import { InputLabel, makeStyles, MenuItem } from "@material-ui/core";
import { Select } from "@material-ui/core";
import {
  FilterType,
  MUIDataTableColumn,
  MUIDataTableColumnOptions,
  MUIDataTableMeta,
} from "mui-datatables";
import React from "react";

const useStyles = makeStyles({
  noFilter: {
    opacity: 0.6,
  },
});

export interface Item {
  value: string;
  text: string;
}

export interface Props {
  /** Label for the select. */
  label: string;
  /** Items to be part of the select options. */
  items: Item[];
  /** Initial value. */
  value: string;
  /** onChange function for the select. */
  onChange: (value?: string) => void;

  /** Text to display on the default option with value "". If not provided, it will be 'No filter'. */
  noFilterStr?: string;

  /** Styles for the select. */
  className?: string;
}

export const OptionFilter: React.FC<Props> = ({
  label,
  items,
  value,
  onChange,
  noFilterStr,
  className,
}) => {
  const classes = useStyles();

  return (
    <>
      <InputLabel shrink data-qa="label">
        {label}
      </InputLabel>
      <Select
        data-qa="select"
        value={value || ""}
        onChange={(e) => {
          onChange(e.target.value as string);
        }}
        className={className}
      >
        <MenuItem
          data-qa="default-item"
          value=""
          key=""
          className={classes.noFilter}
        >
          {noFilterStr || "No filter"}
        </MenuItem>
        {items.map((value) => {
          return (
            <MenuItem
              data-qa={`item-${value.value}`}
              value={value.value}
              key={value.value}
            >
              {value.text}
            </MenuItem>
          );
        })}
      </Select>
    </>
  );
};

const customFilter: FilterType = "custom";

function applyLogic(value: string, filters: string[]): boolean {
  if (filters.length < 1) {
    return false;
  }
  // If the value is null, we treat it like an empty string.
  if (!value) {
    value = "";
  }
  if (!filters[0]) {
    return value !== "";
  }
  return !filters.some((filter) => value.includes(filter));
}

export function getOptionFilterOptions(
  label: string,
  items: Item[],
  noFilterStr?: string,
  className?: string,
  filterList?: string[],
  customBodyRender?: (
    value: any,
    tableMeta: MUIDataTableMeta,
    updateValue: (value: string) => void
  ) => React.ReactNode
): MUIDataTableColumnOptions {
  return {
    customBodyRender: customBodyRender,
    filterType: customFilter,
    filterList: filterList,
    filterOptions: {
      names: [],
      logic: applyLogic,
      display: (
        filterList: string[][],
        onChange: (
          value: string[],
          index: number,
          column: MUIDataTableColumn
        ) => void,
        index: number,
        column: MUIDataTableColumn
      ) => {
        return (
          <OptionFilter
            label={label}
            items={items}
            onChange={(value?: string) => {
              if (!value) {
                filterList[index] = [];
              } else {
                filterList[index][0] = value;
              }
              onChange(filterList[index], index, column);
            }}
            value={
              filterList[index] && filterList[index].length > 0
                ? filterList[index][0]
                : undefined
            }
            noFilterStr={noFilterStr}
            className={className}
          />
        );
      },
    },
    customFilterListOptions: {
      render: (value: string[]) => {
        if (value.length < 1) {
          return "";
        }
        return `${label}: ${value[0]}`;
      },
    },
  };
}
