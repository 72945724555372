import { Grid, Input, InputLabel } from "@material-ui/core";
import React from "react";

export interface Props {
  /** Label for the input. */
  label?: string;
  /** Vale for the input. */
  value?: number;
  /** onChange function for the input. */
  onChange?: (newValue?: number) => void;
  /** Whether the input is required or not. */
  required?: boolean;

  /** Whether the input is fullWidth or not */
  fullWidth?: boolean;
  /** A sytles class for the input */
  className?: string;
}

/** A simple input for numbers. */
export const NumberInput: React.FC<Props> = ({
  label,
  value,
  onChange,
  required,
  fullWidth,
  className,
}) => {
  return (
    <Grid>
      {label && <InputLabel data-qa="label">{label}</InputLabel>}
      <Input
      data-qa="input"
        type="number"
        value={value ? value : ""}
        fullWidth={fullWidth}
        required={required}
        className={className}
        onChange={(e) => {
          if (onChange) {
            const eValue = e.target.value;
            const value = eValue ? Number(eValue) : undefined;
            onChange(value);
          }
        }}
      />
    </Grid>
  );
};
