import React from "react";

import { CircularProgress } from "@mui/material";

import styles from "./css/FullScreen.module.css";

interface Props {
  open: boolean;
}

export const FullScreen: React.FC<Props> = ({ open }) => {
  return (
    open && (
      <div className={styles.progressDiv}>
        <CircularProgress />
      </div>
    )
  );
};
