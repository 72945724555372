import React, { useContext, useMemo, useState } from "react";

import { createTheme, ThemeProvider } from "@mui/material";
import Dialog from "@mui/material/Dialog";

import styles from "./css/AdminDialog.module.css";
import { ClockIcon, CloseIcon, MailIcon } from "../../../assets";
import { OEBSGlobalContext } from "../../../context/OEBSGlobal";
import { RequestService } from "../../../../../api/OracleEBS/request";
import { Config } from "../../../../../utils/config";
import { ExpiryDateTab } from "./components/ExpiryDateTab";
import { AddReviewersTab } from "./components/AddReviewersTab";
import { ChangeRecipientsTab } from "./components/ChangeRecipientsTab";

const theme = createTheme({
  components: {
    MuiPaper: {
      styleOverrides: {
        root: {
          maxWidth: "none !important",
          width: "80% !important",
        },
      },
    },
  },
});

interface Props {
  open?: boolean;
  onCloseClick: () => void;
}

export const AdminDialog: React.FC<Props> = ({
  open = false,
  onCloseClick,
}) => {
  const { app } = useContext(OEBSGlobalContext);
  const requestService = useMemo(
    () => new RequestService(`${Config.apiUrl}${app?.appRoutePath}`),
    [app?.appRoutePath]
  );
  const [activeTab, setActiveTab] = useState<
    "addReviewers" | "expiryDate" | "changeRecipients"
  >("expiryDate");

  const renderTab = () => {
    switch (activeTab) {
      case "addReviewers":
        return <AddReviewersTab requestService={requestService} />;
      case "expiryDate":
        return <ExpiryDateTab requestService={requestService} />;
      case "changeRecipients":
        return <ChangeRecipientsTab requestService={requestService} />;
      default:
        return <></>;
    }
  };

  const onTabClick =
    (tab: "addReviewers" | "expiryDate" | "changeRecipients") => () => {
      setActiveTab(tab);
    };

  return (
    <ThemeProvider theme={theme}>
      <Dialog open={open}>
        <div className={styles.adminSection}>
          <div className={styles.header}>
            <div className={styles.headerContent}>
              <b className={styles.title}>Admin section</b>
              <button className={styles.cross} onClick={onCloseClick}>
                <CloseIcon className={styles.xIcon} />
              </button>
            </div>
          </div>
          <div className={styles.frame}>
            <div className={styles.frameContainer}>
              <div className={styles.tabList}>
                {/* This tab will be hidden until we have client response
                <div
                  className={
                    activeTab === "addReviewers"
                      ? styles.selectedTab
                      : styles.tab
                  }
                  onClick={onTabClick("addReviewers")}
                >
                  <div className={styles.tabContent}>
                    <div className={styles.tabName}>
                      <PersonIcon className={styles.tabIcon} />
                      <div className={styles.tabText}>Add reviewers</div>
                    </div>
                  </div>
                </div> */}
                <div
                  className={
                    activeTab === "expiryDate" ? styles.selectedTab : styles.tab
                  }
                  onClick={onTabClick("expiryDate")}
                >
                  <div className={styles.tabContent}>
                    <div className={styles.tabName}>
                      <ClockIcon className={styles.tabIcon} />
                      <div className={styles.tabText}>Expiry date</div>
                    </div>
                  </div>
                </div>
                <div
                  className={
                    activeTab === "changeRecipients"
                      ? styles.selectedTab
                      : styles.tab
                  }
                  onClick={onTabClick("changeRecipients")}
                >
                  <div className={styles.tabContent}>
                    <div className={styles.tabName}>
                      <MailIcon className={styles.tabIcon} />
                      <div className={styles.tabText}>Change recipients</div>
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.tableBox}>{renderTab()}</div>
            </div>
          </div>
        </div>
      </Dialog>
    </ThemeProvider>
  );
};
