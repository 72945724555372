import React, { useContext, useMemo, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useSnackbar } from "notistack";

import { getStoredSession } from "../../../../api/account";
import { RequestService } from "../../../../api/OracleEBS/request";
import {
  RequestComment,
  RequestEntity,
  RequestRow,
  RequestStatus,
} from "../../../../models/OracleEBS/request";
import { Config } from "../../../../utils/config";
import { inferError } from "../../../../utils/inferError";
import { getCurrentUserID } from "../../../../utils/session";
import { now, uiDateTime } from "../../../../utils/time";
import { AddIcon, DeleteIcon, DownloadIcon } from "../../assets";
import styles from "./css/Request.module.css";
import { ButtonRounded } from "../Buttons/Rounded/ButtonRounded";
import { useConfirmationDialog } from "../../hooks/useConfirmationDialog";
import { getXLSXFile } from "./common/getXLSXFile";
import { readFromXLSX } from "../../../../utils/OracleEBS/XLSX";
import { ImportXLSXDialog } from "./common/ImportXLSXDialog";
import { OEBSGlobalContext } from "../../context/OEBSGlobal";
import { FormTable } from "../RequestTable/common/FormTable";
import { RectangularButton } from "./components/RectangularButton";
import { EmailRecipientDialog } from "./components/EmailRecipientDialog";
import { LoadingProgressContext } from "../../context/LoadingProgress";

interface Props {
  request: RequestEntity;
}

export const EditRequest: React.FC<Props> = ({ request }) => {
  const { id } = useParams();
  const [rows, setRows] = useState<RequestRow[]>(request.rows);
  const [comment, setComment] = useState<string>("");
  const [addComment, setAddComment] = useState<boolean>(
    !request.lastComment.text
  );
  const { app } = useContext(OEBSGlobalContext);
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const requestService = useMemo(
    () => new RequestService(`${Config.apiUrl}${app?.appRoutePath}`),
    [app?.appRoutePath]
  );
  const { getConfirmation } = useConfirmationDialog();
  const [openImportRequest, setOpenImportRequest] = useState<boolean>(false);
  const [requestFile, setRequestFile] = useState<File>();
  const [markWrongCells, setMarkWrongCells] = useState<boolean>(false);
  const [importedRows, setImportedRows] = useState<number>(0);
  const [addRecipients, setAddRecipients] = useState<boolean>(false);
  const [recipientList, setRecipientsList] = useState<string[]>([]);
  const recipientListRef = useRef<string[]>([]);
  const { startLoading, stopLoading } = useContext(LoadingProgressContext);

  const validations = () => {
    const numRowsDeleted = rows.reduce(
      (total, x) => (x.isDeleted ? total + 1 : total),
      0
    );
    if (numRowsDeleted >= rows.length)
      return new Error("All the rows are deleted");
    if (rows.find((row) => Number.isNaN(row.inactiveDate)))
      return new Error("Dates must be valid");
    return undefined;
  };

  const onSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    event.stopPropagation();
    const err = validations();
    if (err) {
      enqueueSnackbar(err.message, {
        autoHideDuration: 1500,
        variant: "error",
      });
      return;
    }
    const confirmed = await getConfirmation({
      title: "Submit Request",
      message: "This edited request will be sent to be reviewed",
      confirmButton: {
        content: "Yes, submit",
        variant: "Terciary",
      },
      additionalButton: (
        <RectangularButton
          onClick={() => {
            setAddRecipients(true);
          }}
        />
      ),
    });
    if (!confirmed) return;

    try {
      startLoading();
      const session = getStoredSession();
      const userID = getCurrentUserID(session);
      const date = now();
      const lastComment: RequestComment = comment
        ? {
            commentID: 0,
            requestID: request.requestID,
            authorID: userID,
            date: date,
            text: comment,
          }
        : undefined;
      const requestUpdated: RequestEntity = {
        requestID: request.requestID,
        type: request.type,
        lastUpdatedBy: userID,
        requesterID: request.requesterID,
        date: request.date,
        rows: rows,
        reviewerID: request.reviewerID,
        lastUpdate: date,
        status: RequestStatus.Submitted,
        lastComment: lastComment,
        notifyTo: recipientListRef.current,
      };
      await requestService.editRequest(Number(id), requestUpdated);
      stopLoading();
      enqueueSnackbar("Succeed! A notification email has been sent", {
        variant: "success",
      });
    } catch (error: any) {
      stopLoading();
      const err = inferError(error);
      enqueueSnackbar(err.errorMessage, { variant: "error" });
      return;
    }
    navigate(`/${app.appRoutePath}`);
  };

  const onDelete = async () => {
    const confirmed = await getConfirmation({
      title: "Delete Request",
      message:
        "This action cannot be rolled back. Are you sure you want to delete this request?",
      confirmButton: {
        content: "Delete request",
        variant: "Red",
      },
      additionalButton: (
        <RectangularButton
          onClick={() => {
            setAddRecipients(true);
          }}
        />
      ),
    });
    if (!confirmed) return;

    try {
      startLoading();
      const session = getStoredSession();
      const userID = getCurrentUserID(session);
      const date = now();
      const lastComment: RequestComment = comment
        ? {
            commentID: 0,
            requestID: request.requestID,
            authorID: userID,
            date: date,
            text: comment,
          }
        : undefined;
      const requestUpdated: RequestEntity = {
        requestID: request.requestID,
        type: request.type,
        lastUpdatedBy: userID,
        requesterID: userID,
        date: request.date,
        reviewerID: request.reviewerID,
        lastUpdate: date,
        status: RequestStatus.Deleted,
        lastComment: lastComment,
        notifyTo: recipientListRef.current,
      };
      await requestService.updateStatus(Number(id), requestUpdated);
      stopLoading();
      enqueueSnackbar("Succeed! A notification email has been sent", {
        variant: "success",
      });
    } catch (error: any) {
      stopLoading();
      const err = inferError(error);
      enqueueSnackbar(err.errorMessage, { variant: "error" });
      return;
    }

    navigate(`/${app.appRoutePath}`);
  };

  const handleFileChange = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const file = event.target.files[0];
    const fileExt = file.name.split(".").pop();
    if (fileExt !== "xlsx") {
      enqueueSnackbar("Wrong file extension. Please provide a .xlsx file", {
        variant: "error",
      });
      return;
    }
    setRequestFile(file);
    if (!file) return;
    const xlsxRows = await readFromXLSX(file, request.requestID, request.type);
    if (xlsxRows.rows.length < request.rows.length) {
      enqueueSnackbar("Imported rows are less than exiting ones", {
        variant: "error",
      });
      return;
    }
    xlsxRows.rows = xlsxRows.rows.filter((row, index) =>
      index >= request.rows.length ? row.status !== "Canceled" : true
    );
    try {
      const validatedRows = await requestService.validateXMLSRows(xlsxRows);
      request.rows.forEach(
        (row, index) => (validatedRows.rows[index].rowID = row.rowID)
      );
      const filteredRows = validatedRows.rows.map((row, index) =>
        row.isDeleted ? { ...request.rows[index], isDeleted: true } : row
      );
      setRows(filteredRows);
      setMarkWrongCells(true);
      setImportedRows(filteredRows.length);
      setOpenImportRequest(false);
      if (validatedRows.invalidMsg !== "") {
        enqueueSnackbar(validatedRows.invalidMsg, {
          autoHideDuration: 60000,
          variant: "error",
        });
      }
    } catch (error: any) {
      const err = inferError(error);
      enqueueSnackbar(err.errorMessage, { variant: "error" });
      return;
    }
  };

  const disableEnterKey = (event: React.KeyboardEvent<HTMLFormElement>) => {
    if (event.key === "Enter") event.preventDefault();
  };

  const CommentSection =
    request && !addComment && request.lastComment.text ? (
      <>
        <div className={styles.commentDiv}>Last comment</div>
        <b className={styles.emailB1}>By: {request.lastComment.authorEmail}</b>
        <b className={styles.dateB}>{uiDateTime(request.lastComment.date)}</b>
        <p className={styles.commentBoxTextarea}>{request.lastComment.text}</p>
        <button
          type="button"
          className={styles.commentButton}
          onClick={() => setAddComment(true)}
        >
          <AddIcon className={styles.addIcon} />
          <div className={styles.addLabelDiv}>Add a comment</div>
        </button>
      </>
    ) : (
      <>
        <div className={styles.commentDiv}>Comment</div>
        <textarea
          value={comment}
          onChange={(event) => setComment(event.currentTarget.value)}
          className={styles.commentBoxTextarea}
          placeholder="Write here"
        />
        <button
          type="button"
          className={styles.commentButton}
          onClick={() => {
            setComment("");
            setAddComment(false);
          }}
        >
          <DeleteIcon className={styles.deleteIcon} />
          <div className={styles.labelDiv}>Delete comment</div>
        </button>
      </>
    );

  const backToList = async () => {
    const confirmed = await getConfirmation({
      title: "Are you sure you want to exit?",
      message: "All progress made will be lost",
      confirmButton: {
        content: "Exit request",
        variant: "Red",
      },
    });
    if (!confirmed) return;

    navigate(`/${app.appRoutePath}`);
  };

  return (
    <form
      className={styles.contentDiv}
      onSubmit={onSubmit}
      onKeyDown={disableEnterKey}
    >
      {addRecipients && (
        <EmailRecipientDialog
          recipientsList={recipientList}
          onClose={() => {
            setAddRecipients(false);
          }}
          onSave={(newRecipients: string[]) => {
            setRecipientsList(newRecipients);
            setAddRecipients(false);
          }}
          recipientListRef={recipientListRef}
        />
      )}
      <ImportXLSXDialog
        open={openImportRequest}
        handleFileChange={handleFileChange}
        onDeleteClick={() => setRequestFile(undefined)}
        onCancelClick={() => setOpenImportRequest(false)}
        onExitClick={() => setOpenImportRequest(false)}
        file={requestFile}
      />
      <FormTable
        rows={rows}
        setRows={setRows}
        edit
        originalRows={request.rows.length}
        markWrongCells={markWrongCells}
        importedRows={importedRows}
        type={request.type}
      />
      <div className={styles.contentDiv1}>
        <div className={styles.tittleDiv1}></div>
        {CommentSection}
      </div>
      <div className={styles.expiryDate}>
        {"[SYSTEM]: This request will be deleted if the expiry date is exceeded: " +
          uiDateTime(request.expirationDate, "DD MMM yyyy")}
      </div>
      <div className={styles.navigateDiv}>
        <ButtonRounded type="button" onClick={backToList} variant="Secondary">
          Back to list
        </ButtonRounded>
        <div className={styles.requestButtons}>
          <button
            className={styles.button}
            type="button"
            onClick={() =>
              getXLSXFile(getConfirmation, {
                type: request.type,
                showState: true,
              })
            }
          >
            <DownloadIcon className={styles.downloadIcon} />
            <b className={styles.buttonB}>Download draft</b>
          </button>
          <div>
            <ButtonRounded
              variant="Secondary"
              type="button"
              onClick={() =>
                getXLSXFile(getConfirmation, {
                  type: request.type,
                  rows: rows,
                  showState: true,
                })
              }
            >
              Export
            </ButtonRounded>
          </div>
          <div>
            <ButtonRounded
              variant="Secondary"
              type="button"
              onClick={() => {
                setOpenImportRequest(true);
              }}
            >
              Import
            </ButtonRounded>
          </div>
          <ButtonRounded variant="Red" type="button" onClick={onDelete}>
            Delete
          </ButtonRounded>
          <ButtonRounded variant="Terciary" type="submit">
            Submit
          </ButtonRounded>
        </div>
      </div>
    </form>
  );
};
