import React, { FunctionComponent, useContext } from "react";

import styles from "./css/Header.module.css";
import { GlobalContext } from "../../../../context/Global";
import {
  FrameIcon,
  LogoIcon,
  LogOutIcon,
  UserIcon,
} from "../../../../assets/header";

export const Header: FunctionComponent = () => {
  const { accountInfo } = useContext(GlobalContext);

  const logOut = () => {
    localStorage.clear();
    window.location.reload();
  };

  return (
    <header className={styles.header}>
      <div className={styles.WTWLogoDiv}>
        <div className={styles.appTittleDiv}>
          <LogoIcon className={styles.logoIcon} />
          <b className={styles.appName}>Application Platform</b>
        </div>
        <div className={styles.logoAlgorathDiv}>
          <div className={styles.logoAlgorathDiv1}>
            <div className={styles.poweredByDiv}>Powered by </div>
            <FrameIcon className={styles.appFrameIcon} />
            <div className={styles.poweredByDiv}>
              {" "}
              v{process.env.REACT_APP_VERSION}
            </div>
          </div>
        </div>
      </div>
      <div className={styles.fullLogDiv}>
        <div className={styles.userDiv}>
          <UserIcon className={styles.userLogoIcon} />
          <div className={styles.userInformationDiv}>
            <p className={styles.description}>Welcome</p>
            <p className={styles.identificationP} id="Body medium">
              {accountInfo.email}
            </p>
          </div>
        </div>
        <button className={styles.logoButton} onClick={logOut}>
          <LogOutIcon className={styles.logOutIcon} />
        </button>
      </div>
    </header>
  );
};
