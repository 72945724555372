import { RequestAuditEntry } from "../../models/OracleEBS/audit";
import {
  RequestEntity,
  RowField,
  TeamMappings,
  ValidationResponse,
} from "../../models/OracleEBS/request";
import { XMLSRequestRows } from "../../utils/OracleEBS/XLSX";
import { ApiBase } from "../base";
import { ENDPOINTS } from "../constants/oracleEBS";
import {
  PageInformationRequest,
  RequestPage,
} from "../../models/OracleEBS/tablePageInformation";
import { User } from "../../models/app";

export class RequestService extends ApiBase {
  getRequestPages = (
    pageInformation?: PageInformationRequest
  ): Promise<RequestPage> => {
    let url = ENDPOINTS.REQUESTS;
    const data = !!pageInformation
      ? JSON.stringify(pageInformation)
      : undefined;
    return this.fetch<RequestPage>(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      data,
    });
  };

  getRequest = (id: number): Promise<RequestEntity> => {
    let url = `${ENDPOINTS.REQUEST}/${id}`;
    return this.fetch<RequestEntity>(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });
  };

  getFieldOptions = (field: string): Promise<RowField[]> => {
    let url = `${ENDPOINTS.OPTIONS}/${field}`;
    return this.fetch<RowField[]>(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });
  };

  getFieldOptionsByParent = (
    field: string,
    parent: number
  ): Promise<RowField[]> => {
    let url = `${ENDPOINTS.OPTIONS}/${field}/${parent}`;
    return this.fetch<RowField[]>(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });
  };

  getMappingOptions = (field: string): Promise<string[]> => {
    let url = `${ENDPOINTS.OPTIONS}/${field}`;
    return this.fetch<string[]>(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });
  };

  getTeamMappings = (team: number): Promise<TeamMappings> => {
    let url = `${ENDPOINTS.MAPPINGS}/${team}`;
    return this.fetch<TeamMappings>(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });
  };

  createRequest = (request: RequestEntity): Promise<void> => {
    return this.fetch(ENDPOINTS.CREATE_REQUEST, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      data: JSON.stringify(request),
    });
  };

  updateStatus = (id: number, request: RequestEntity): Promise<void> => {
    let url = `${ENDPOINTS.UPDATE_STATUS}/${id}`;
    return this.fetch(url, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      data: JSON.stringify(request),
    });
  };

  editRequest = (id: number, request: RequestEntity): Promise<void> => {
    let url = `${ENDPOINTS.EDIT_REQUEST}/${id}`;
    return this.fetch(url, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      data: JSON.stringify(request),
    });
  };

  getRequestAuditLogs = (id: number): Promise<RequestAuditEntry[]> => {
    let url = `${ENDPOINTS.AUDIT_LOGS}/${id}`;
    return this.fetch<RequestAuditEntry[]>(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });
  };

  validateXMLSRows = (rows: XMLSRequestRows): Promise<ValidationResponse> => {
    return this.fetch<ValidationResponse>(ENDPOINTS.VALIDATE_ROWS, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      data: JSON.stringify(rows),
    });
  };

  getUserManual = (): Promise<Blob> => {
    return this.fetch<Blob>(ENDPOINTS.USER_MANUAL, {
      method: "GET",
      responseType: "blob",
    });
  };

  getReviewersList = (searchFilter: string): Promise<User[]> => {
    return this.fetch<User[]>(
      `${ENDPOINTS.REVIEWER_LIST}?email=${searchFilter}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
  };

  getRequestersList = (searchFilter: string): Promise<User[]> => {
    return this.fetch<User[]>(
      `${ENDPOINTS.REQUESTERS_LIST}?email=${searchFilter}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
  };

  modifyUserRole = (userID: number, roleCode: string): Promise<void> => {
    let url = `${ENDPOINTS.MODIFY_ROLE}?id=${userID}&role=${roleCode}`;
    return this.fetch(url, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
    });
  };

  downloadRequestsList = (): Promise<Blob> => {
    return this.fetch<Blob>(ENDPOINTS.REQUEST_LIST_XLSX, {
      method: "GET",
      responseType: "blob",
    });
  };

  downloadRequestsFile = (id: number): Promise<Blob> => {
    return this.fetch<Blob>(`${ENDPOINTS.REQUEST_XLSX}/${id}`, {
      method: "GET",
      responseType: "blob",
    });
  };

  getCompletedRecipients = (searchFilter: string): Promise<string[]> => {
    return this.fetch<string[]>(
      `${ENDPOINTS.COMPLETED_DFLT_RECIPIENTS}?email=${searchFilter}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
  };

  getStatusChangeRecipients = (searchFilter: string): Promise<string[]> => {
    return this.fetch<string[]>(
      `${ENDPOINTS.STATUS_CHANGE_DFLT_RECIPIENTS}?email=${searchFilter}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
  };

  getExpiryDate = (): Promise<number> => {
    return this.fetch<number>(`${ENDPOINTS.EXPIRY_DATE}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });
  };

  updateCompletedRecipients = (recipients: string[]): Promise<void> => {
    return this.fetch(`${ENDPOINTS.COMPLETED_DFLT_RECIPIENTS}`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      data: JSON.stringify({ list: recipients }),
    });
  };

  updateStatusChangeRecipients = (recipients: string[]): Promise<void> => {
    return this.fetch(`${ENDPOINTS.STATUS_CHANGE_DFLT_RECIPIENTS}`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      data: JSON.stringify({ list: recipients }),
    });
  };

  updateExpiryDate = (expiryDate: number): Promise<void> => {
    return this.fetch(`${ENDPOINTS.EXPIRY_DATE}`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      data: JSON.stringify({ daysToAdd: expiryDate }),
    });
  };

  removeFromCompletedRecipients = (recipient: string): Promise<void> => {
    return this.fetch<void>(
      `${ENDPOINTS.COMPLETED_DFLT_RECIPIENTS_REMOVE}?email=${recipient}`,
      {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
  };

  removeFromStatusChangeRecipients = (recipient: string): Promise<void> => {
    return this.fetch<void>(
      `${ENDPOINTS.STATUS_CHANGE_DFLT_RECIPIENTS_REMOVE}?email=${recipient}`,
      {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
  };
}
