import { Session } from "../models/account";
import jwt_decode from "jwt-decode";

interface Token {
  id: number;
  email: string;
}

export const getCurrentUserEmail = (session: Session): string =>
  (jwt_decode(session.token) as Token).email;

export const getCurrentUserID = (session: Session): number =>
  (jwt_decode(session.token) as Token).id;
