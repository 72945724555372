import {
  Filter,
  FilterTypeValues,
  FilterType,
} from "../../models/OracleEBS/tablePageInformation";

export const getFilter = <T extends FilterTypeValues>(
  filters: Filter[],
  type: T,
  field: string
): FilterType<T> => {
  return filters.find(
    (element) => element.type === type && element.field === field
  ) as FilterType<T>;
};
