import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";

import { ThemeProvider, createTheme } from "@mui/material/styles";

import styles from "./css/OracleEBS.module.css";
import { Column, PageInformation, Table } from "../Table/Table";
import { Config } from "../../../../utils/config";
import { RequestService } from "../../../../api/OracleEBS/request";
import {
  RequestEntity,
  RequestStatus,
  RequestType,
} from "../../../../models/OracleEBS/request";
import { BroomIcon, CloseIcon, OptionsIcon, SearchIcon } from "../../assets";
import { uiDateTime } from "../../../../utils/time";
import { useSnackbar } from "notistack";
import { inferError } from "../../../../utils/inferError";
import {
  RequestListHeaders,
  REQUEST_FIELD_NAMES,
  ROLE_CODES,
} from "../../../../utils/OracleEBS/constants";
import { useNavigate } from "react-router-dom";
import { ServerSideInformation } from "../Table/Table/Table";
import {
  NumberFilter,
  PageInformationRequest,
  StatusCount,
  StringFilter,
  Filter,
} from "../../../../models/OracleEBS/tablePageInformation";
import { OEBSGlobalContext } from "../../context/OEBSGlobal";
import { FiltersSelector } from "./components/FiltersSelector";
import { FilterChip } from "./components/FilterChip";
import { getFilter } from "../../../../utils/OracleEBS/filters";
import { ActionButton } from "../Buttons/Action/ActionButton";
import { DownloadListButton } from "./components/DownloadListButton";
import saveAs from "file-saver";
import { now } from "moment";

const theme = createTheme({
  components: {
    MuiPaper: {
      styleOverrides: {
        root: {
          width: "100%",
          borderRadius: "10px",
          boxShadow: "0 5px 6px rgb(103 52 168 / 30%)",
          marginBottom: "28px",
        },
      },
    },
    //@ts-ignore
    MUIDataTable: {
      styleOverrides: {
        responsiveBase: {
          maxHeight: "50vh",
          borderRadius: "8px 8px 0 0",
        },
      },
    },
    MUIDataTableToolbar: {
      styleOverrides: {
        root: { display: "none" },
      },
    },
    MUIDataTableFilterList: {
      styleOverrides: {
        root: {
          display: "none",
        },
      },
    },
    MUIDataTableHeadRow: {
      styleOverrides: {
        root: { height: "52px" },
      },
    },
    MUIDataTableHeadCell: {
      styleOverrides: {
        root: {
          backgroundColor: "#dfdfdf",
          paddingTop: "0 !important",
          paddingBottom: "0 !important",
        },
        data: {
          fontWeight: "bold",
        },
        toolButton: {
          textTransform: "none !important",
          marginLeft: "-8px !important",
          minWidth: "0 !important",
          marginRight: "8px !important",
          paddingLeft: "8px !important",
          paddingRight: "8px !important",
        },
      },
    },
    MUIDataTableFooter: {
      styleOverrides: {
        root: {
          backgroundColor: "#dfdfdf",
          borderRadius: "0 0 8px 8px",
          height: "100%",
        },
      },
    },
    MUIDataTablePagination: {
      styleOverrides: {
        tableCellContainer: {
          padding: "0px 24px 0px 24px !important",
        },
      },
    },
  },
});

// TODO: https://algorath.atlassian.net/jira/software/projects/WMA/boards/30/roadmap?selectedIssue=WMA-52
// Try to divide the code into smaller components
export const OracleEBS = () => {
  const { app, role } = useContext(OEBSGlobalContext);
  const [requestList, setRequestList] = useState<RequestEntity[]>([]);
  const [numRows, setNumRows] = useState<number>();
  const [openAdvancedFilters, setOpenAdvancedFilters] =
    useState<boolean>(false);
  const [statusCount, setStatusCount] = useState<StatusCount>();
  const { requestPageInformation, setRequestPageInformation } =
    useContext(OEBSGlobalContext);
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const requestService = useMemo(
    () => new RequestService(`${Config.apiUrl}${app?.appRoutePath}`),
    [app?.appRoutePath]
  );
  const statusFilter = useMemo(
    () =>
      (getFilter(
        requestPageInformation.filters,
        "options",
        REQUEST_FIELD_NAMES.STATUS
      )?.value as RequestStatus[]) || [],
    [requestPageInformation]
  );
  // This  variable will contain the actual table info in order to help us to detect when a change occurs.
  // Without this the component would enter into a loop because always detect a change (we are not setting the actual value then)
  const [tableInfo, setTableInfo] = useState<PageInformation>({
    page: 0,
    rowsPerPage: 10,
    search: "",
    filters: [],
    sort: undefined,
  });

  const fetchRequests = useCallback(
    async (pageInfo: PageInformationRequest) => {
      try {
        const page = await requestService.getRequestPages(pageInfo);
        setRequestList(page.list);
        setNumRows(page.count);
        setStatusCount(page.statusCount);
      } catch (error: any) {
        const err = inferError(error);
        enqueueSnackbar(err.errorMessage, { variant: "error" });
      }
    },
    [enqueueSnackbar, setRequestList, requestService]
  );

  useEffect(() => {
    // This is needed in order to avoid making a request each time you type or select a filter or order.
    // This cause that the first time we enter we have to wait 500 ms too.
    const timer = setTimeout(() => {
      fetchRequests(requestPageInformation);
    }, 500);

    return () => clearTimeout(timer);
  }, [requestPageInformation, fetchRequests]);

  const itemToData = (item: RequestEntity) => {
    return [
      Number(item.requestID),
      item.type,
      role?.roleCode === ROLE_CODES.REQUESTER
        ? item.reviewerEmail
        : item.requesterEmail,
      item.lastUpdate
        ? uiDateTime(item.lastUpdate, "DD MMM yyyy HH:mm")
        : undefined,
      uiDateTime(item.date, "DD MMM yyyy HH:mm"),
      item.status,
    ];
  };

  const columns: Column[] = [
    {
      type: "number",
      id: REQUEST_FIELD_NAMES.REQUEST_ID,
      label: RequestListHeaders[REQUEST_FIELD_NAMES.REQUEST_ID],
      display: "true",
      customBodyRender: (value) => {
        return <div className={styles.requestID}>{value}</div>;
      },
    },
    {
      type: "options",
      id: REQUEST_FIELD_NAMES.REQUEST_TYPE,
      label: RequestListHeaders[REQUEST_FIELD_NAMES.REQUEST_TYPE],
      customBodyRender: (value) => {
        return (
          <div>
            Team {`${value === RequestType.Edition ? "Mapping " : ""}${value}`}
          </div>
        );
      },
      items: [
        {
          value: RequestType.Creation,
          text: "Team creation",
        },
        {
          value: RequestType.Edition,
          text: "Team edition",
        },
        {
          value: RequestType.Inactivation,
          text: "Team inactivation",
        },
      ],
    },
    {
      type: "string",
      id:
        role.roleCode === ROLE_CODES.REQUESTER
          ? REQUEST_FIELD_NAMES.REVIEWER_EMAIL
          : REQUEST_FIELD_NAMES.REQUESTER_EMAIL,
      label:
        RequestListHeaders[
          role.roleCode === ROLE_CODES.REQUESTER
            ? REQUEST_FIELD_NAMES.REVIEWER_EMAIL
            : REQUEST_FIELD_NAMES.REQUESTER_EMAIL
        ],
      display: "true",
    },
    {
      type: "dateRange",
      id: REQUEST_FIELD_NAMES.LAST_UPDATE,
      label: RequestListHeaders[REQUEST_FIELD_NAMES.LAST_UPDATE],
      display: "true",
    },
    {
      type: "dateRange",
      id: REQUEST_FIELD_NAMES.DATE,
      label: RequestListHeaders[REQUEST_FIELD_NAMES.DATE],
      display: "true",
    },
    {
      type: "options",
      id: REQUEST_FIELD_NAMES.STATUS,
      label: RequestListHeaders[REQUEST_FIELD_NAMES.STATUS],
      customBodyRender: (value) => {
        let className: string;
        switch (value) {
          case RequestStatus.Resolved:
            className = styles.statusResolved;
            break;
          case RequestStatus.Rejected:
            className = styles.statusRejected;
            break;
          case RequestStatus.Submitted:
            className = styles.statusSubmitted;
            break;
          case RequestStatus.Deleted:
            className = styles.statusDeleted;
            break;
          case RequestStatus.Completed:
            className = styles.statusCompleted;
            break;
        }
        return <div className={className}>{value}</div>;
      },
      items: [
        {
          value: RequestStatus.Resolved,
          text: "Resolved",
        },
        {
          value: RequestStatus.Rejected,
          text: "Rejected",
        },
        {
          value: RequestStatus.Submitted,
          text: "Submitted",
        },
        { value: RequestStatus.Deleted, text: RequestStatus.Deleted },
      ],
    },
  ];

  const onRequestSelection = (request: RequestEntity) => {
    navigate(`${request.requestID}`);
  };

  const onSearchChange = ({
    currentTarget: { value },
  }: React.ChangeEvent<HTMLInputElement>) => {
    setRequestPageInformation((prev) => {
      if (!value) return { ...prev, search: [] };

      const ref: NumberFilter = {
        field: REQUEST_FIELD_NAMES.REQUEST_ID,
        type: "number",
        value: Number(value) || null,
      };
      const email: StringFilter = {
        field:
          role.roleCode === ROLE_CODES.REQUESTER
            ? REQUEST_FIELD_NAMES.REVIEWER_EMAIL
            : REQUEST_FIELD_NAMES.REQUESTER_EMAIL,
        type: "string",
        value: value,
      };
      return { ...prev, search: [ref, email] };
    });
  };

  const onStatusFilter = (status: RequestStatus) => () => {
    setRequestPageInformation((prev) => {
      const aux = [...prev.filters];
      const index = aux.findIndex(
        (element) =>
          element.type === "options" &&
          element.field === REQUEST_FIELD_NAMES.STATUS
      );
      if (index === -1) {
        aux.push({
          field: REQUEST_FIELD_NAMES.STATUS,
          type: "options",
          value: [status],
        });
      } else {
        const statusIndex = statusFilter.indexOf(status);
        let auxList: RequestStatus[];
        if (statusIndex !== -1) {
          auxList = [
            ...statusFilter.slice(0, statusIndex),
            ...statusFilter.slice(statusIndex + 1),
          ];
        } else {
          auxList = [...statusFilter, status];
        }

        if (auxList.length < 1)
          return {
            ...prev,
            filters: [...aux.slice(0, index), ...aux.slice(index + 1)],
          };
        else
          aux[index] = {
            field: REQUEST_FIELD_NAMES.STATUS,
            type: "options",
            value: auxList,
          };
      }
      return { ...prev, filters: aux };
    });
  };

  const clearFilter = (field: string) => {
    setRequestPageInformation((prev) => {
      const aux = [...prev.filters];
      const index = aux.findIndex((element) => element.field === field);
      if (index !== -1) {
        return {
          ...prev,
          filters: [...aux.slice(0, index), ...aux.slice(index + 1)],
        };
      }
    });
  };

  const serverSideInfo: ServerSideInformation = {
    pageInformation: tableInfo,
    onPageChange: useCallback(
      (pageInfo: PageInformation) => {
        setTableInfo(pageInfo);
        setRequestPageInformation((prev) => {
          return {
            ...prev,
            sort: {
              field: pageInfo.sort?.name,
              order: pageInfo.sort.direction,
            },
            page: pageInfo.page,
            rowsPerPage: pageInfo.rowsPerPage,
          };
        });
      },
      [setRequestPageInformation]
    ),
    numRows: numRows,
  };

  const getValueString = (filter: Filter): string => {
    return filter.type === "string"
      ? filter.value
      : filter.type === "date"
      ? `from: ${
          filter.value.from ? uiDateTime(filter.value.from, "DD MMM yyyy") : "-"
        } / to: ${
          filter.value.to ? uiDateTime(filter.value.to, "DD MMM yyyy") : "-"
        }`
      : filter.type === "options"
      ? filter.value.join(", ")
      : filter.value.toString();
  };

  const downloadRequestsList = async () => {
    try {
      await requestService.downloadRequestsList();
      const list = await requestService.downloadRequestsList();
      saveAs(list, `Requests_List_${now()}.xlsx`);
    } catch (error: any) {
      const err = inferError(error);
      enqueueSnackbar(err.errorMessage, { variant: "error" });
    }
  };

  return (
    <div className={styles.containerDiv}>
      {openAdvancedFilters && (
        <FiltersSelector
          open={openAdvancedFilters}
          onCloseClick={() => setOpenAdvancedFilters(false)}
        />
      )}
      <div className={styles.contentDiv}>
        <div className={styles.navigateDiv}>
          <div className={styles.upsideBarDiv}>
            <b className={styles.requestsListB}>Requests list</b>
            {role?.roleCode === ROLE_CODES.REQUESTER && (
              <div className={styles.actionButtons}>
                <ActionButton
                  action="create"
                  variant="Primary"
                  onClick={() => navigate("create-team")}
                >
                  Create Team
                </ActionButton>
                <ActionButton
                  action="edit"
                  variant="Terciary"
                  onClick={() => navigate("edit-team")}
                >
                  Edit Team Mapping
                </ActionButton>
                <ActionButton
                  action="inactivate"
                  variant="Red"
                  onClick={() => navigate("inactivate-team")}
                >
                  Inactivate Team
                </ActionButton>
              </div>
            )}
          </div>
          <div className={styles.upsideBarDiv}>
            <div className={styles.navigationDiv}>
              <div className={styles.searchBarDiv}>
                <div className={styles.frameDiv}>
                  <SearchIcon className={styles.groupIcon} />
                  <input
                    className={styles.searchText}
                    value={
                      getFilter(
                        requestPageInformation.search,
                        "string",
                        role.roleCode === ROLE_CODES.REQUESTER
                          ? REQUEST_FIELD_NAMES.REVIEWER_EMAIL
                          : REQUEST_FIELD_NAMES.REQUESTER_EMAIL
                      )?.value || ""
                    }
                    onChange={onSearchChange}
                    placeholder="Search"
                  />
                  <button
                    className={styles.xButton}
                    onClick={() => {
                      setRequestPageInformation((prev) => {
                        return { ...prev, search: [] };
                      });
                    }}
                  >
                    <CloseIcon className={styles.xIcon} />
                  </button>
                </div>
              </div>
              <FiltersButton
                count={requestPageInformation.filters.length}
                onFilterClick={() => setOpenAdvancedFilters(true)}
                onClearClick={() =>
                  setRequestPageInformation((prev) => {
                    return { ...prev, filters: [] };
                  })
                }
              />
            </div>
            <div className={styles.userDiv}>
              <BubbleButton
                status={RequestStatus.Submitted}
                statusFilter={statusFilter}
                onStatusFilter={onStatusFilter}
                count={statusCount?.submitted}
              />
              <BubbleButton
                status={RequestStatus.Rejected}
                statusFilter={statusFilter}
                onStatusFilter={onStatusFilter}
                count={statusCount?.rejected}
              />
              <BubbleButton
                status={RequestStatus.Resolved}
                statusFilter={statusFilter}
                onStatusFilter={onStatusFilter}
                count={statusCount?.resolved}
              />
              <BubbleButton
                status={RequestStatus.Deleted}
                statusFilter={statusFilter}
                onStatusFilter={onStatusFilter}
                count={statusCount?.deleted}
              />
              <BubbleButton
                status={RequestStatus.Completed}
                statusFilter={statusFilter}
                onStatusFilter={onStatusFilter}
                count={statusCount?.completed}
              />
            </div>
          </div>
        </div>
        {requestPageInformation.filters.some(
          (filter) => filter.field !== REQUEST_FIELD_NAMES.STATUS
        ) && (
          <div className={styles.chipsContainer}>
            <div className={styles.chipsRow}>
              <div className={styles.chipsFrame}>
                {requestPageInformation.filters.map((filter) => {
                  if (filter.field !== REQUEST_FIELD_NAMES.STATUS) {
                    const value = getValueString(filter);

                    const onClearClick = () => clearFilter(filter.field);

                    return (
                      <FilterChip
                        key={filter.field}
                        field={filter.field}
                        value={value}
                        onClearClick={onClearClick}
                      />
                    );
                  } else return null;
                })}
              </div>
            </div>
          </div>
        )}
        <ThemeProvider theme={theme}>
          <Table
            list={requestList}
            columns={columns}
            itemToData={itemToData}
            onSelect={(request) => onRequestSelection(request)}
            serverSideInfo={serverSideInfo}
            rowsPerPage={requestPageInformation.rowsPerPage}
          />
        </ThemeProvider>
      </div>
      <DownloadListButton onClick={downloadRequestsList} />
    </div>
  );
};

interface BubbleButtonProps {
  status: RequestStatus;
  statusFilter: RequestStatus[];
  onStatusFilter: (status: RequestStatus) => () => void;
  count: number;
}

const BubbleButton: React.FC<BubbleButtonProps> = ({
  status,
  statusFilter,
  onStatusFilter,
  count,
}) => {
  const isIncluded = statusFilter.includes(status);
  return (
    <button
      className={`${styles.bubbleSelectorButton} ${
        isIncluded && styles.bubbleSelectorButtonActive
      }`}
      onClick={onStatusFilter(status)}
    >
      <div className={styles.bubbleSelectorContentDiv}>
        <b className={styles.bubbleSelectorContentText}>{count || 0}</b>
        <b className={styles.bubbleSelectorContentText}>{status}</b>
      </div>
    </button>
  );
};

interface FilterButtonProps {
  count: number;
  onFilterClick: () => void;
  onClearClick: () => void;
}

const FiltersButton: React.FC<FilterButtonProps> = ({
  count,
  onFilterClick,
  onClearClick,
}) => {
  return (
    <div className={styles.filterContainerDiv}>
      <button className={styles.filterButton} onClick={onFilterClick}>
        <div className={styles.contentDiv1}>
          <div className={styles.filterIconDiv}>
            <OptionsIcon className={styles.filterIcon} />
          </div>
          <b className={styles.filtersB}>{`Filters `}</b>
          <b className={styles.filtersB}>{`(${count})`}</b>
        </div>
      </button>
      <button className={styles.clearAllButton} onClick={onClearClick}>
        <BroomIcon className={styles.broomIcon} />
        <div className={styles.clearAllDiv}>
          <b className={styles.clearAllB}>Clear all</b>
        </div>
      </button>
    </div>
  );
};
