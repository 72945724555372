import React from "react";
import { DownloadIcon } from "../../../assets";

import styles from "./css/DownloadListButton.module.css";

interface Props {
  onClick: () => void;
}

export const DownloadListButton: React.FC<Props> = ({ onClick }) => {
  return (
    <div className={styles.buttonContainer}>
      <button className={styles.exportButton} onClick={onClick}>
        <DownloadIcon className={styles.iconDownload} />
        <b className={styles.text}>Download list</b>
      </button>
    </div>
  );
};
