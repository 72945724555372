import React from "react";
import { PersonIcon } from "../../../assets";

import styles from "./css/RectangularButton.module.css";

interface Props {
  onClick: () => void;
}
export const RectangularButton: React.FC<Props> = ({ onClick }) => {
  return (
    <button className={styles.button} type="button" onClick={onClick}>
      <div className={styles.content}>
        <PersonIcon className={styles.icon} />
        <b className={styles.text}>More recipients</b>
      </div>
    </button>
  );
};
