import { RequestEntity } from "./request";

interface FilterBase {
  field: string;
}

export interface StringFilter extends FilterBase {
  type: "string";
  value: string;
}

export interface NumberFilter extends FilterBase {
  type: "number";
  value: number;
}

interface OptionsFilter extends FilterBase {
  type: "options";
  value: string[];
}

export interface DateFilterValue {
  from: number;
  to: number;
}
export interface DateFilter extends FilterBase {
  type: "date";
  value: DateFilterValue;
}

export type Filter = StringFilter | NumberFilter | OptionsFilter | DateFilter;

export type FilterTypeValues = Filter["type"];

export type FilterType<T extends FilterTypeValues> = Extract<
  Filter,
  { type: T }
>;

interface Sort {
  field: string;
  order: "asc" | "desc";
}
export interface PageInformationRequest {
  search?: Filter[];
  filters: Filter[];
  page: number;
  rowsPerPage: number;
  sort?: Sort;
}

export const defaultPageInformationRequest: PageInformationRequest = {
  search: [],
  filters: [],
  page: 0,
  rowsPerPage: 10,
};

export interface StatusCount {
  submitted: number;
  rejected: number;
  resolved: number;
  deleted: number;
  completed: number;
}

export interface RequestPage {
  list: RequestEntity[];
  count: number;
  statusCount: StatusCount;
}
