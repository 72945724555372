import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useSnackbar } from "notistack";
import UndoIcon from "@mui/icons-material/Undo";

import { RequestService } from "../../../../../api/OracleEBS/request";
import {
  RequestRow,
  RequestType,
  RowField,
} from "../../../../../models/OracleEBS/request";
import { Config } from "../../../../../utils/config";
import { inferError } from "../../../../../utils/inferError";
import { DeleteIcon } from "../../../assets";
import styles from "../css/RequestTable.module.css";
import { Footer } from "../common/Footer";
import { Header } from "../common/Header";
import { TeamCreationRow } from "../TeamCreation/Row";
import { RowDisplay } from "../common/RowDisplay";
import { ROW_FIELD_PARAM } from "../../../../../utils/OracleEBS/constants";
import { OEBSGlobalContext } from "../../../context/OEBSGlobal";
import { TeamEditionRow } from "../TeamEdition/Row";
import { TeamInactivationRow } from "../TeamInactivation/Row";

interface Props {
  rows: RequestRow[];
  setRows?: React.Dispatch<React.SetStateAction<RequestRow[]>>;
  edit?: boolean;
  originalRows?: number;
  importedRows?: number;
  markWrongCells: boolean;
  type: RequestType;
}

export const FormTable: React.FC<Props> = ({
  rows,
  setRows,
  edit,
  originalRows = 0,
  importedRows = 0,
  markWrongCells,
  type,
}) => {
  const indexScrollBarRef = useRef<HTMLDivElement>(null);
  const rowsScrollBarRef = useRef<HTMLDivElement>(null);
  const { app } = useContext(OEBSGlobalContext);
  const { enqueueSnackbar } = useSnackbar();
  const [rowsBackup] = useState<RequestRow[]>(rows);

  const [legalEntityOptions, setLegalEntityOptions] = useState<RowField[]>([]);
  const [mappingTeamOptions, setMappingTeamOptions] = useState<string[]>([]);
  const [mappingOfficeOptions, setMappingOfficeOptions] = useState<string[]>(
    []
  );

  const requestService = useMemo(
    () => new RequestService(`${Config.apiUrl}${app?.appRoutePath}`),
    [app?.appRoutePath]
  );

  const addRow = () => {
    const newRow = {
      rowNumber: rows.length + 1,
      requestID: edit ? rows[0].requestID : 0,
      isDeleted: false,
    } as RequestRow;
    setRows((prev) => [...prev, newRow]);
  };

  const deleteRow = (index: number) => () => {
    edit && index < originalRows
      ? setRows((prev) =>
          [...prev].map((row) =>
            row.rowNumber === index + 1
              ? { ...rowsBackup[index], isDeleted: true }
              : row
          )
        )
      : setRows((prev) =>
          [...prev.slice(0, index), ...prev.slice(index + 1)].map((row, i) => {
            return { ...row, rowNumber: i + 1 };
          })
        );
  };

  const undo = (index: number) => () => {
    setRows((prev) =>
      [...prev].map((row) =>
        row.rowNumber === index + 1 ? { ...row, isDeleted: false } : row
      )
    );
  };

  useEffect(() => {
    (async () => {
      try {
        const legalEntityOps = await requestService.getFieldOptions(
          ROW_FIELD_PARAM.LEGAL_ENTITY
        );
        setLegalEntityOptions(legalEntityOps);
        if (type !== RequestType.Inactivation) {
          const mappingTeamOps = await requestService.getMappingOptions(
            ROW_FIELD_PARAM.MAPPING_TEAM
          );
          setMappingTeamOptions(mappingTeamOps);
          const mappingOfficeOps = await requestService.getMappingOptions(
            ROW_FIELD_PARAM.MAPPING_OFFICE
          );
          setMappingOfficeOptions(mappingOfficeOps);
        }
      } catch (error: any) {
        const err = inferError(error);
        enqueueSnackbar(err.errorMessage, { variant: "error" });
      }
    })();
  }, [
    app.appRoutePath,
    enqueueSnackbar,
    requestService,
    setMappingOfficeOptions,
    setMappingTeamOptions,
    type,
  ]);

  const handleChange = useCallback(
    (rowNumber: number, field: string, value: string | number | RowField) => {
      setRows((prev) =>
        [...prev].map((row) =>
          row.rowNumber === rowNumber ? { ...row, [field]: value } : row
        )
      );
    },
    [setRows]
  );

  const deleteSelection = (rowNumber: number, fields: string[]) => {
    let rowWithDeletions = {};
    fields.forEach((field) => {
      rowWithDeletions = { ...rowWithDeletions, [field]: undefined };
    });
    setRows((prev) =>
      [...prev].map((row) =>
        row.rowNumber === rowNumber ? { ...row, ...rowWithDeletions } : row
      )
    );
  };

  return (
    <div className={styles.newRequestTable}>
      <div className={styles.indexDivider}>
        <div className={styles.indexTableHeadDiv}>
          <div className={styles.indexContainerDiv}>
            <header className={styles.indexHeader} id="Header">
              <div className={styles.headerCellFixedDiv} />
              <div className={styles.headerCellFixedDiv1}>
                <div className={styles.textDiv}>Nº</div>
              </div>
            </header>
            <div
              className={styles.indexRowsBoxDiv}
              ref={indexScrollBarRef}
              onScroll={(event: React.UIEvent<HTMLElement>) => {
                rowsScrollBarRef.current.scrollTop =
                  event.currentTarget.scrollTop;
              }}
            >
              <div className={styles.indexRowsDiv}>
                {rows.map((row, index) => (
                  <div
                    className={`${styles.indexRowDiv} ${
                      row.isDeleted && styles.rowDivDeleted
                    }`}
                    key={row.rowNumber}
                  >
                    {row.isDeleted ? (
                      <button
                        type="button"
                        className={styles.bodyCellFixedButton}
                        onClick={undo(index)}
                      >
                        <UndoIcon className={styles.undoIcon} />
                      </button>
                    ) : (
                      <button
                        type="button"
                        className={styles.bodyCellFixedButton}
                        onClick={deleteRow(index)}
                      >
                        <DeleteIcon className={styles.deleteIcon} />
                      </button>
                    )}
                    <div className={styles.bodyCellFixedDiv}>
                      <p className={styles.textP}>{row.rowNumber}</p>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
        <div className={styles.tableHeadDiv}>
          <div className={styles.containerDiv}>
            <Header type={type} />
            <div
              className={styles.rowsBoxDiv}
              ref={rowsScrollBarRef}
              onScroll={(event: React.UIEvent<HTMLElement>) => {
                indexScrollBarRef.current.scrollTop =
                  event.currentTarget.scrollTop;
              }}
            >
              <div className={styles.rowsDiv}>
                {rows.map((row) =>
                  row.isDeleted ? (
                    <RowDisplay type={type} row={row} key={row.rowNumber} />
                  ) : type === RequestType.Creation ? (
                    <TeamCreationRow
                      row={row}
                      key={row.rowNumber}
                      handleChange={handleChange}
                      deleteSelection={deleteSelection}
                      legalEntityOptions={legalEntityOptions}
                      mappingTeamOptions={mappingTeamOptions}
                      mappingOfficeOptions={mappingOfficeOptions}
                      markWrongCells={
                        markWrongCells && row.rowNumber <= importedRows
                      }
                    />
                  ) : type === RequestType.Edition ? (
                    <TeamEditionRow
                      row={row}
                      key={row.rowNumber}
                      handleChange={handleChange}
                      deleteSelection={deleteSelection}
                      legalEntityOptions={legalEntityOptions}
                      mappingTeamOptions={mappingTeamOptions}
                      mappingOfficeOptions={mappingOfficeOptions}
                      markWrongCells={
                        markWrongCells && row.rowNumber <= importedRows
                      }
                    />
                  ) : (
                    <TeamInactivationRow
                      row={row}
                      key={row.rowNumber}
                      handleChange={handleChange}
                      deleteSelection={deleteSelection}
                      legalEntityOptions={legalEntityOptions}
                      markWrongCells={
                        markWrongCells && row.rowNumber <= importedRows
                      }
                    />
                  )
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className={styles.footerContainerDiv}>
        <Footer addRow={addRow} />
      </div>
    </div>
  );
};
