import React, { FunctionComponent, useContext } from "react";

import styles from "./css/Header.module.css";
import { GlobalContext } from "../../../../context/Global";
import { AppIcon } from "../../../common/AppIcon";
import { FrameIcon, HomeIcon, UserIcon } from "../../../../assets/header";
import { useNavigate } from "react-router-dom";
import { OEBSGlobalContext } from "../../context/OEBSGlobal";

export const Header: FunctionComponent = () => {
  const { accountInfo } = useContext(GlobalContext);
  const { app, role } = useContext(OEBSGlobalContext);
  const navigate = useNavigate();

  return (
    <header className={styles.header}>
      <div className={styles.appLogoDiv}>
        <div className={styles.appNameTittleDiv}>
          <AppIcon className={styles.appIcon} />
          <b className={styles.appName}>{app?.appName}</b>
        </div>
        <div className={styles.logoAlgorathAppDiv}>
          <div className={styles.logoAlgorathDiv}>
            <div className={styles.poweredByDiv}>Powered by </div>
            <FrameIcon className={styles.frameIcon} />
            <div className={styles.poweredByDiv}>
              {" "}
              v{process.env.REACT_APP_VERSION}
            </div>
          </div>
        </div>
      </div>
      <div className={styles.fullLogDiv}>
        <div className={styles.userDiv}>
          <UserIcon className={styles.userLogoIcon} />
          <div className={styles.userInformationDiv}>
            {!role && <p className={styles.description}>Welcome</p>}
            <p className={styles.identificationP} id="Body medium">
              {accountInfo.email}
            </p>
            {role && <p className={styles.description}>{role.roleDisplay}</p>}
          </div>
        </div>
        <button className={styles.logoButton} onClick={() => navigate("/")}>
          <HomeIcon className={styles.homeIcon} />
        </button>
      </div>
    </header>
  );
};
