import React, { useCallback, useEffect, useState } from "react";

import { useSnackbar } from "notistack";

import { RequestService } from "../../../../../../api/OracleEBS/request";
import { inferError } from "../../../../../../utils/inferError";
import styles from "./css/ExpiryDateTab.module.css";

interface Props {
  requestService: RequestService;
}

export const ExpiryDateTab: React.FC<Props> = ({ requestService }) => {
  const [daysToAdd, setDaysToAdd] = useState<string>("");
  const [actualDaysToAdd, setActualDaysToAdd] = useState<number>();
  const { enqueueSnackbar } = useSnackbar();
  const blockInvalidChar = (event: React.KeyboardEvent<HTMLInputElement>) =>
    ["e", "E", "+", "-", "."].includes(event.key) && event.preventDefault();
  const numberRegExp = /^[1-9][0-9]*$/i;

  const fetchExpiryDate = useCallback(async () => {
    try {
      const actualDaysToAdd = await requestService.getExpiryDate();
      setActualDaysToAdd(actualDaysToAdd);
    } catch (error: any) {
      const err = inferError(error);
      enqueueSnackbar(err.errorMessage, {
        variant: "error",
        preventDuplicate: true,
      });
    }
  }, [enqueueSnackbar, requestService]);

  useEffect(() => {
    fetchExpiryDate();
  }, [enqueueSnackbar, fetchExpiryDate, requestService]);

  const onInputChange = ({
    currentTarget: { value },
  }: React.ChangeEvent<HTMLInputElement>) => {
    setDaysToAdd(value);
  };

  const onSaveClick = async () => {
    try {
      await requestService.updateExpiryDate(Number(daysToAdd));
      setDaysToAdd("");
      fetchExpiryDate();
    } catch (error: any) {
      const err = inferError(error);
      enqueueSnackbar(err.errorMessage, {
        variant: "error",
        preventDuplicate: true,
      });
    }
  };

  return (
    <div className={styles.content}>
      <div className={styles.expiryDate}>
        <div className={styles.component}>
          <div className={styles.dateSaved}>
            Set new expiration date for rejected requests (in days):
          </div>
          <div className={styles.cell}>
            <input
              className={styles.inputNumber}
              type="number"
              min={1}
              onKeyDown={blockInvalidChar}
              value={daysToAdd}
              onChange={onInputChange}
            />
          </div>
        </div>
        <div className={styles.info}>
          <i className={styles.dateSaved}>Last expiry date saved:</i>
          <i className={styles.dareSaved}>{actualDaysToAdd} days</i>
        </div>
      </div>
      <button
        className={styles.buttonAdd}
        disabled={!daysToAdd || !numberRegExp.test(daysToAdd)}
        onClick={onSaveClick}
      >
        <div className={styles.saveText}>Save expiry date</div>
      </button>
    </div>
  );
};
