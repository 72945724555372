import React from "react";
import { useSnackbar } from "notistack";

import styles from "./css/SnackbarCloseButton.module.css";

interface Props {
  snackbarId: string | number;
}
export const SnackbarCloseButton: React.FC<Props> = ({ snackbarId }) => {
  const { closeSnackbar } = useSnackbar();

  return (
    <button className={styles.button} onClick={() => closeSnackbar(snackbarId)}>
      <div className={styles.text}>Dismiss</div>
    </button>
  );
};
