import React from "react";

import {
  Action,
  RequestAuditEntry,
} from "../../../../../models/OracleEBS/audit";
import { RequestStatus } from "../../../../../models/OracleEBS/request";
import { formatLogDateTime } from "../../../../../utils/time";
import { ArrowRightIcon } from "../../../assets";
import styles from "./css/Entry.module.css";
import { EntryComment } from "./Comment";
import { NewRow } from "./NewRow";
import { RowChange } from "./RowChange";

interface Props {
  entry: RequestAuditEntry;
}

export const Entry: React.FC<Props> = ({ entry }) => {
  const request = entry.request.rowData;
  const prev = entry.request.previousFields;
  const actual = entry.request.changedFields;
  return (
    <div className={styles.entryDiv}>
      <div className={styles.dividerContainer}>
        <div className={styles.divider} />
      </div>
      <div className={styles.contentDiv}>
        <div className={styles.infoContainerDiv}>
          <div className={styles.infoDiv}>
            <b className={styles.infoTextB}>
              {request.lastUpdate
                ? formatLogDateTime(request.lastUpdate)
                : formatLogDateTime(request.date)}
            </b>
          </div>
          <div className={styles.infoDiv1}>
            <b className={styles.headerTextB}>
              Last update by: {request.lastUpdatedByEmail}
            </b>
          </div>
        </div>
        <div className={styles.contentDiv}>
          <div className={styles.rowsDiv}>
            <div className={styles.tableHeaderDiv}>
              <div className={styles.headerCellDiv}>
                <b className={styles.headerTextB}>Request Status</b>
              </div>

              <div className={styles.contentCellDiv}>
                <div className={styles.statusDiv}>
                  {entry.request.action === Action.Insert ? (
                    <b
                      className={
                        request.status === RequestStatus.Rejected
                          ? styles.statusRejected
                          : request.status === RequestStatus.Resolved
                          ? styles.statusResolved
                          : request.status === RequestStatus.Deleted
                          ? styles.statusDeleted
                          : request.status === RequestStatus.Submitted
                          ? styles.statusSubmitted
                          : styles.statusCompleted
                      }
                    >
                      {request.status}
                    </b>
                  ) : (
                    <>
                      <b
                        className={
                          prev.status === RequestStatus.Rejected
                            ? styles.statusRejected
                            : prev.status === RequestStatus.Resolved
                            ? styles.statusResolved
                            : prev.status === RequestStatus.Deleted
                            ? styles.statusDeleted
                            : prev.status === RequestStatus.Submitted
                            ? styles.statusSubmitted
                            : styles.statusCompleted
                        }
                      >
                        {prev.status}
                      </b>
                      <div className={styles.arrowIconDiv}>
                        <ArrowRightIcon className={styles.arrowIcon} />
                      </div>
                      <b
                        className={
                          actual.status === RequestStatus.Rejected
                            ? styles.statusRejected
                            : actual.status === RequestStatus.Resolved
                            ? styles.statusResolved
                            : actual.status === RequestStatus.Deleted
                            ? styles.statusDeleted
                            : actual.status === RequestStatus.Submitted
                            ? styles.statusSubmitted
                            : styles.statusCompleted
                        }
                      >
                        {actual.status}
                      </b>{" "}
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
          {entry.rows.map((row) =>
            row.action === Action.Insert ? (
              <NewRow
                type={request.type}
                row={row.rowData}
                key={row.rowData.rowID}
              />
            ) : (
              <RowChange
                row={row.rowData}
                prev={row.previousFields}
                actual={row.changedFields}
                key={row.rowData.rowID}
              />
            )
          )}
          {entry.comment && <EntryComment comment={entry.comment} />}
        </div>
      </div>
    </div>
  );
};
