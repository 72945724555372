import { makeStyles } from "@material-ui/core";
import {
  FilterType,
  MUIDataTableColumn,
  MUIDataTableColumnOptions,
  MUIDataTableMeta,
} from "mui-datatables";
import React, { useCallback } from "react";
import { NumberInputDelay } from "../NumberInputDelay";

export interface Props {
  /** Label for the string filter input */
  label: string;
  /** filterList structure. Must be provided from MUI-Datatable. */
  filterList: string[][];
  /** onChange function. Must be similar to the one provided by MUI-Datatable. */
  onChange: (
    value: string[],
    index: number,
    column: MUIDataTableColumn
  ) => void;
  /** Index of the element. Must be provided from MUI-Datatable. */
  index: number;
  /** Column of the element. Must be provided from MUI-Datatable. */
  column: MUIDataTableColumn;
  /** The amount of milliseconds that are garanteed to be waited before calling 'onChange'. */
  delay?: number;
  /** className for the input. */
  className?: string;
}

const useStyles = makeStyles({
  text: {
    display: "flex",
  },
  grid: {
    flexDirection: "row",
    display: "flex",
  },
});

export const NumberFilter: React.FC<Props> = ({
  label,
  filterList,
  onChange,
  index,
  column,
  delay,
  className,
}) => {
  const classes = useStyles();

  const filterListDep = JSON.stringify(filterList);
  const onChangeCallback = useCallback(
    (value?: number) => {
      const filterList = JSON.parse(filterListDep);
      if (value === undefined) {
        filterList[index] = [];
      } else {
        filterList[index][0] = `${value}`;
      }
      onChange(filterList[index], index, column);
    },
    [onChange, index, column, filterListDep]
  );

  const value =
    filterList[index].length === 0 || filterList[index][0] === undefined
      ? undefined
      : Number(filterList[index][0]);

  return (
    <NumberInputDelay
      data-qa="number-filter"
      label={label}
      fullWidth
      onChange={onChangeCallback}
      value={value}
      delay={delay}
      className={className || classes.text}
    />
  );
};

function applyLogic(value: string, filters: string[]): boolean {
  if (filters.length < 1) {
    return false;
  }
  // If the value is null, we try it like an empty string.
  if (!value) {
    value = "";
  }
  if (!filters[0]) {
    return value !== "";
  }
  return !value.toString().includes(filters[0]);
}

const customFilter: FilterType = "custom";

export function getNumberFilterOptions(
  label: string,
  customBodyRender?: (
    value: any,
    tableMeta: MUIDataTableMeta,
    updateValue: (value: string) => void
  ) => React.ReactNode,
  filterList?: string[]
): MUIDataTableColumnOptions {
  return {
    customBodyRender: customBodyRender,
    filterType: customFilter,
    filterList: filterList,
    filterOptions: {
      names: [],
      logic: applyLogic,
      display: (
        filterList: string[][],
        onChange: (
          value: string[],
          index: number,
          column: MUIDataTableColumn
        ) => void,
        index: number,
        column: MUIDataTableColumn
      ) => {
        return (
          <NumberFilter
            label={label}
            onChange={onChange}
            column={column}
            index={index}
            filterList={filterList}
          />
        );
      },
    },
    customFilterListOptions: {
      render: (value: string) => {
        if (!value) {
          return "";
        }
        return label + ": " + value;
      },
    },
  };
}
