import React, { useState } from "react";
import { useSnackbar } from "notistack";

import { Login as LoginModel } from "../../models/account";
import { AccountService } from "../../api/account";
import { Config } from "../../utils/config";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import { Box, FormControl } from "@mui/material";
import { LoginStatus } from "../../context/Global";
import styles from "./css/Login.module.css";
import { inferError } from "../../utils/inferError";

const accountService = new AccountService(Config.apiUrl);

interface Props {
  onLogIn: (status: LoginStatus) => void;
}

export const Login: React.FC<Props> = ({ onLogIn }) => {
  const { enqueueSnackbar } = useSnackbar();
  const [info, setInfo] = useState<LoginModel>({
    email: "",
  });

  const onSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    e.stopPropagation();

    try {
      await accountService.login(info);
      onLogIn({ status: "valid", email: info.email });
    } catch (error: any) {
      const err = inferError(error);
      enqueueSnackbar(err.errorMessage, { variant: "error" });
    }
  };

  return (
    <Box className={styles.content}>
      <form onSubmit={onSubmit}>
        <FormControl fullWidth>
          <Box>
            <TextField
              fullWidth
              id="email"
              label="email"
              variant="filled"
              value={info.email}
              onChange={(e) => {
                setInfo({ ...info, email: e.target.value });
              }}
            />
          </Box>
        </FormControl>
        <FormControl fullWidth>
          <Box>
            <Button fullWidth type="submit" variant="contained" color="primary">
              Sign in
            </Button>
          </Box>
        </FormControl>
      </form>
    </Box>
  );
};
