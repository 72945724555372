import React from "react";
import { AddIcon, CancelIcon, EditIcon } from "../../../assets";

import styles from "./css/ActionButton.module.css";

type Variants = "Primary" | "Terciary" | "Red";

interface Props {
  type?: "button" | "submit" | "reset";
  action?: "create" | "edit" | "inactivate";
  children?: React.ReactNode;
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
  variant: Variants;
}

export const ActionButton: React.FC<Props> = ({
  type,
  action,
  children,
  onClick,
  variant,
}) => {
  let buttonClassName = styles.buttonShrinkPrimary;
  switch (variant) {
    case "Terciary":
      buttonClassName = styles.buttonShrinkTerciary;
      break;
    case "Red":
      buttonClassName = styles.buttonShrinkRed;
      break;
  }

  return (
    <button className={buttonClassName} type={type} onClick={onClick}>
      <div className={styles.contentDiv}>
        {action === "edit" ? (
          <EditIcon className={styles.icon} />
        ) : action === "inactivate" ? (
          <CancelIcon className={styles.icon} />
        ) : (
          <AddIcon className={styles.icon} />
        )}

        <b className={styles.inactiveTeamB}>{children}</b>
      </div>
    </button>
  );
};
