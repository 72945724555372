import React, { useContext, useMemo, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useSnackbar } from "notistack";

import { getStoredSession } from "../../../../api/account";
import { RequestService } from "../../../../api/OracleEBS/request";
import {
  RequestComment,
  RequestEntity,
  RequestStatus,
} from "../../../../models/OracleEBS/request";
import { Config } from "../../../../utils/config";
import { inferError } from "../../../../utils/inferError";
import { getCurrentUserID } from "../../../../utils/session";
import { now, uiDateTime } from "../../../../utils/time";
import { AddIcon, DeleteIcon } from "../../assets";
import styles from "./css/Request.module.css";
import { ROLE_CODES } from "../../../../utils/OracleEBS/constants";
import { ButtonRounded } from "../Buttons/Rounded/ButtonRounded";
import { useConfirmationDialog } from "../../hooks/useConfirmationDialog";
import { DialogOptions } from "../../context/ConfirmationDialog";
import { getXLSXFile } from "./common/getXLSXFile";
import { OEBSGlobalContext } from "../../context/OEBSGlobal";
import { RequestDisplayTable } from "../RequestTable/common/RequestDisplayTable";
import { RectangularButton } from "./components/RectangularButton";
import { EmailRecipientDialog } from "./components/EmailRecipientDialog";
import { LoadingProgressContext } from "../../context/LoadingProgress";
import saveAs from "file-saver";

interface Props {
  request: RequestEntity;
}

export const DisplayRequest: React.FC<Props> = ({ request }) => {
  const { id } = useParams();
  const [comment, setComment] = useState<string>("");
  const [addComment, setAddComment] = useState<boolean>(
    !request.lastComment.text
  );
  const { app, role } = useContext(OEBSGlobalContext);
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const requestService = useMemo(
    () => new RequestService(`${Config.apiUrl}${app?.appRoutePath}`),
    [app?.appRoutePath]
  );
  const { getConfirmation } = useConfirmationDialog();
  const [addRecipients, setAddRecipients] = useState<boolean>(false);
  const [recipientList, setRecipientsList] = useState<string[]>([]);
  const recipientListRef = useRef<string[]>([]);
  const { startLoading, stopLoading } = useContext(LoadingProgressContext);
  const snTickerIDRef = useRef<HTMLInputElement>();

  const resolveRequest = async (
    resolution: "Approve" | "Reject" | "Complete"
  ) => {
    const dialogOptions: DialogOptions =
      resolution === "Approve"
        ? {
            title: "Approve Request",
            message: "Are you sure you want to approve this request?",
            additionalButton: (
              <RectangularButton
                onClick={() => {
                  setAddRecipients(true);
                }}
              />
            ),
            confirmButton: {
              content: "Approve request",
              variant: "Green",
            },
          }
        : resolution === "Reject"
        ? {
            title: "Reject Request",
            message: "Are you sure you want to reject this request?",
            additionalButton: (
              <RectangularButton
                onClick={() => {
                  setAddRecipients(true);
                }}
              />
            ),
            confirmButton: {
              content: "Reject request",
              variant: "Red",
            },
          }
        : {
            title: "Introduce Ticket ID",
            confirmButton: {
              content: "Complete request",
              variant: "Blue",
            },
            additionalButton: (
              <RectangularButton
                onClick={() => {
                  setAddRecipients(true);
                }}
              />
            ),
            additionalComponent: AddTicketID,
          };
    const confirmed = await getConfirmation(dialogOptions);
    if (!confirmed) return;

    try {
      startLoading();
      const session = getStoredSession();
      const userID = getCurrentUserID(session);
      const date = now();
      const lastComment: RequestComment = comment
        ? {
            commentID: 0,
            requestID: request.requestID,
            authorID: userID,
            date: date,
            text: comment,
          }
        : undefined;
      const requestUpdated: RequestEntity = {
        requestID: request.requestID,
        type: request.type,
        lastUpdatedBy: userID,
        requesterID: request.requesterID,
        date: request.date,
        reviewerID: userID,
        lastUpdate: date,
        status:
          resolution === "Approve"
            ? RequestStatus.Resolved
            : resolution === "Reject"
            ? RequestStatus.Rejected
            : RequestStatus.Completed,
        lastComment: lastComment,
        notifyTo: recipientListRef.current,
      };
      if (resolution === "Complete") {
        requestUpdated.snTicketID = snTickerIDRef.current.value;
      }
      await requestService.updateStatus(Number(id), requestUpdated);
      stopLoading();
      enqueueSnackbar("Succeed! A notification email has been sent", {
        variant: "success",
      });
      if (resolution === "Approve") {
        const file = await requestService.downloadRequestsFile(Number(id));
        saveAs(file, `OTRequest-${id}.xlsx`);
      }
    } catch (error: any) {
      stopLoading();
      const err = inferError(error);
      enqueueSnackbar(err.errorMessage, { variant: "error" });
      return;
    }
    if (resolution === "Approve") {
      navigate(0);
    } else {
      navigate(`/${app.appRoutePath}`);
    }
  };

  const AddTicketID = (
    <div className={styles.addTicketDiv}>
      <div className={styles.addTicketLabel}>Ticket ID: </div>
      <input
        ref={snTickerIDRef}
        className={styles.addTicketInput}
        maxLength={30}
      />
    </div>
  );

  const CommentSection =
    request &&
    (role.roleCode === ROLE_CODES.REQUESTER ? (
      !addComment && request.lastComment.text ? (
        <>
          <div className={styles.commentDiv}>Last comment</div>
          <b className={styles.emailB1}>
            By: {request.lastComment.authorEmail}
          </b>
          <b className={styles.dateB}>{uiDateTime(request.lastComment.date)}</b>
          <p className={styles.commentBoxTextarea}>
            {request.lastComment.text}
          </p>
          {request.status === RequestStatus.Rejected && (
            <button
              type="button"
              className={styles.commentButton}
              onClick={() => setAddComment(true)}
            >
              <AddIcon className={styles.addIcon} />
              <div className={styles.addLabelDiv}>Add a comment</div>
            </button>
          )}
        </>
      ) : (
        request.status === RequestStatus.Rejected && (
          <>
            <div className={styles.commentDiv}>Comment</div>
            <textarea
              value={comment}
              onChange={(event) => setComment(event.currentTarget.value)}
              className={styles.commentBoxTextarea}
              placeholder="Write here"
            />
            <button
              type="button"
              className={styles.commentButton}
              onClick={() => {
                setComment("");
                setAddComment(false);
              }}
            >
              <DeleteIcon className={styles.deleteIcon} />
              <div className={styles.labelDiv}>Delete comment</div>
            </button>
          </>
        )
      )
    ) : !addComment && request.lastComment.text ? (
      <>
        <div className={styles.commentDiv}>Last comment</div>
        <b className={styles.emailB1}>By: {request.lastComment.authorEmail}</b>
        <b className={styles.dateB}>{uiDateTime(request.lastComment.date)}</b>
        <p className={styles.commentBoxTextarea}>{request.lastComment.text}</p>
        {(request.status === RequestStatus.Submitted ||
          request.status === RequestStatus.Resolved) && (
          <button
            type="button"
            className={styles.commentButton}
            onClick={() => setAddComment(true)}
          >
            <AddIcon className={styles.addIcon} />
            <div className={styles.addLabelDiv}>Add a comment</div>
          </button>
        )}
      </>
    ) : (
      (request.status === RequestStatus.Submitted ||
        request.status === RequestStatus.Resolved) && (
        <>
          <div className={styles.commentDiv}>Comment</div>
          <textarea
            value={comment}
            onChange={(event) => setComment(event.currentTarget.value)}
            className={styles.commentBoxTextarea}
            placeholder="Write here"
          />
          <button
            type="button"
            className={styles.commentButton}
            onClick={() => {
              setComment("");
              setAddComment(false);
            }}
          >
            <DeleteIcon className={styles.deleteIcon} />
            <div className={styles.labelDiv}>Delete comment</div>
          </button>
        </>
      )
    ));

  const ButtonsSection =
    request &&
    (role.roleCode === ROLE_CODES.REVIEWER ||
      role.roleCode === ROLE_CODES.ADMIN) &&
    ((request.status === RequestStatus.Submitted && (
      <>
        <ButtonRounded variant="Red" onClick={() => resolveRequest("Reject")}>
          Reject
        </ButtonRounded>
        <ButtonRounded
          variant="Green"
          onClick={() => resolveRequest("Approve")}
        >
          Approve
        </ButtonRounded>
      </>
    )) ||
      (request.status === RequestStatus.Resolved && (
        <ButtonRounded
          variant="Blue"
          onClick={() => resolveRequest("Complete")}
        >
          Complete request
        </ButtonRounded>
      )));

  return (
    <>
      {addRecipients && (
        <EmailRecipientDialog
          recipientsList={recipientList}
          onClose={() => {
            setAddRecipients(false);
          }}
          onSave={(newRecipients: string[]) => {
            setRecipientsList(newRecipients);
            setAddRecipients(false);
          }}
          recipientListRef={recipientListRef}
        />
      )}
      <RequestDisplayTable rows={request.rows} type={request.type} />
      <div className={styles.contentDiv1}>
        <div className={styles.tittleDiv1}></div>
        {CommentSection}
      </div>
      <div className={styles.navigateDiv}>
        <ButtonRounded
          type="button"
          onClick={() => navigate(`/${app.appRoutePath}`)}
          variant="Secondary"
        >
          Back to list
        </ButtonRounded>
        <div className={styles.requestButtons}>
          <div>
            <ButtonRounded
              variant="Secondary"
              type="button"
              onClick={() =>
                getXLSXFile(getConfirmation, {
                  type: request.type,
                  rows: request.rows.filter((row) => !row.isDeleted),
                  isAccepted: request.status === RequestStatus.Resolved,
                  requester: request.requesterEmail,
                  reviewer: request.reviewerEmail,
                  date: request.date,
                })
              }
            >
              Export
            </ButtonRounded>
          </div>
          {ButtonsSection}
        </div>
      </div>
    </>
  );
};
