import React from "react";

import { RequestRow } from "../../../../../models/OracleEBS/request";
import { ROW_FIELD_HEADER_TITLE } from "../../../../../utils/OracleEBS/constants";
import { formatLogDate } from "../../../../../utils/time";
import styles from "./css/Row.module.css";

interface Props {
  row: RequestRow;
  prev: RequestRow;
  actual: RequestRow;
}

export const RowChange: React.FC<Props> = ({ row, prev, actual }) => {
  return (
    <div className={styles.rowsDiv1}>
      <div className={styles.tableHeaderDiv}>
        <div className={styles.headerCellDiv}>
          <b className={styles.headerTextB}>Row {row.rowNumber}</b>
        </div>
        <div className={styles.headerCellDiv1}>
          <b className={styles.headerTextB}>Previous value</b>
        </div>
        <div className={styles.headerCellDiv1}>
          <b className={styles.headerTextB}>New value</b>
        </div>
      </div>
      {prev.isDeleted !== actual.isDeleted && (
        <RowContent
          title={ROW_FIELD_HEADER_TITLE.STATUS}
          prev={prev.isDeleted ? "Canceled" : "Fulfilled"}
          actual={actual.isDeleted ? "Canceled" : "Fulfilled"}
          prevCellClassName={`${
            prev.isDeleted
              ? styles.contentCellDivCanceled
              : styles.contentCellDivActive
          }`}
          actualCellClassName={`${
            actual.isDeleted
              ? styles.contentCellDivCanceled
              : styles.contentCellDivActive
          }`}
        />
      )}
      {prev.legalEntity && (
        <RowContent
          title={ROW_FIELD_HEADER_TITLE.LEGAL_ENTITY}
          prev={prev.legalEntity.text}
          actual={actual.legalEntity.text}
          prevCellClassName={styles.contentCellDiv}
          actualCellClassName={styles.contentCellDiv}
        />
      )}
      {prev.businessUnit && (
        <RowContent
          title={ROW_FIELD_HEADER_TITLE.BUSINESS_UNIT}
          prev={prev.businessUnit.text}
          actual={actual.businessUnit.text}
          prevCellClassName={styles.contentCellDiv}
          actualCellClassName={styles.contentCellDiv}
        />
      )}
      {prev.function && (
        <RowContent
          title={ROW_FIELD_HEADER_TITLE.FUNCTION}
          prev={prev.function.text}
          actual={actual.function.text}
          prevCellClassName={styles.contentCellDiv}
          actualCellClassName={styles.contentCellDiv}
        />
      )}
      {prev.department && (
        <RowContent
          title={ROW_FIELD_HEADER_TITLE.DEPARTMENT}
          prev={prev.department.text}
          actual={actual.department.text}
          prevCellClassName={styles.contentCellDiv}
          actualCellClassName={styles.contentCellDiv}
        />
      )}
      {prev.team && (
        <RowContent
          title={ROW_FIELD_HEADER_TITLE.TEAM}
          prev={prev.team.text}
          actual={actual.team.text}
          prevCellClassName={styles.contentCellDiv}
          actualCellClassName={styles.contentCellDiv}
        />
      )}
      {(prev.inactiveDate || actual.inactiveDate) && (
        <RowContent
          title={ROW_FIELD_HEADER_TITLE.INACTIVE_DATE}
          prev={prev.inactiveDate ? formatLogDate(prev.inactiveDate) : "-"}
          actual={
            actual.inactiveDate ? formatLogDate(actual.inactiveDate) : "-"
          }
          prevCellClassName={styles.contentCellDiv}
          actualCellClassName={styles.contentCellDiv}
        />
      )}
      {prev.mappingTeam && (
        <RowContent
          title={ROW_FIELD_HEADER_TITLE.MAPPING_TEAM}
          prev={prev.mappingTeam}
          actual={actual.mappingTeam}
          prevCellClassName={styles.contentCellDiv}
          actualCellClassName={styles.contentCellDiv}
        />
      )}
      {prev.mappingOffice && (
        <RowContent
          title={ROW_FIELD_HEADER_TITLE.MAPPING_OFFICE}
          prev={prev.mappingOffice}
          actual={actual.mappingOffice}
          prevCellClassName={styles.contentCellDiv}
          actualCellClassName={styles.contentCellDiv}
        />
      )}
    </div>
  );
};

interface ContentProps {
  title: string;
  prev: string;
  actual: string;
  prevCellClassName: string;
  actualCellClassName: string;
}

const RowContent: React.FC<ContentProps> = ({
  title,
  prev,
  actual,
  prevCellClassName,
  actualCellClassName,
}) => {
  return (
    <div className={styles.tableHeaderDiv}>
      <div className={styles.contentHeaderCellDiv}>
        <b className={styles.headerTextB}>{title}</b>
      </div>
      <div className={prevCellClassName}>
        <div className={styles.textDiv}>{prev}</div>
      </div>
      <div className={actualCellClassName}>
        <div className={styles.textDiv}>{actual}</div>
      </div>
    </div>
  );
};
