import React, { useCallback, useEffect, useState } from "react";

import { useSnackbar } from "notistack";

import { RequestService } from "../../../../../../api/OracleEBS/request";
import { User } from "../../../../../../models/app";
import { inferError } from "../../../../../../utils/inferError";
import { ROLE_CODES } from "../../../../../../utils/OracleEBS/constants";
import styles from "./css/AddReviewersTab.module.css";
import {
  AddIcon,
  CloseIcon,
  DeleteIcon,
  SearchIcon,
  UserBlueIcon,
} from "../../../../assets";
import { AddReviewerDialog } from "./AddReviewerDialog";

interface Props {
  requestService: RequestService;
}

export const AddReviewersTab: React.FC<Props> = ({ requestService }) => {
  const [openAddPopUp, setOpenAddPopUp] = useState(false);
  const [reviewersList, setReviewersList] = useState<User[]>([]);
  const [searchFilter, setSearchFilter] = useState<string>("");
  const { enqueueSnackbar } = useSnackbar();

  const fetchReviewersList = useCallback(
    async (searchFilter: string) => {
      try {
        if (!openAddPopUp) {
          const res = await requestService.getReviewersList(searchFilter);
          setReviewersList(res);
        }
      } catch (error: any) {
        const err = inferError(error);
        enqueueSnackbar(err.errorMessage, { variant: "error" });
      }
    },
    [openAddPopUp, requestService, enqueueSnackbar]
  );

  useEffect(() => {
    // This is needed in order to avoid making a request each time you type or select a filter or order.
    // This cause that the first time we enter we have to wait 500 ms too.
    const timer = setTimeout(() => {
      fetchReviewersList(searchFilter);
    }, 500);
    return () => clearTimeout(timer);
  }, [searchFilter, fetchReviewersList]);

  const onSearchChange = ({
    currentTarget: { value },
  }: React.ChangeEvent<HTMLInputElement>) => {
    setSearchFilter(value);
  };

  const onDeleteClick = async (userID: number) => {
    await requestService.modifyUserRole(userID, ROLE_CODES.REQUESTER);
    await fetchReviewersList("");
  };

  return (
    <div className={styles.table}>
      <div className={styles.tableBox}>
        <div className={styles.navigate}>
          <div className={styles.searchBarContainer}>
            <div className={styles.searchBar}>
              <div className={styles.content}>
                <SearchIcon className={styles.searchIcon} />
                <div className={styles.textContainer}>
                  <input
                    className={styles.text2}
                    placeholder="Search reviewer..."
                    onChange={onSearchChange}
                    value={searchFilter}
                  />
                </div>
              </div>
              <button className={styles.x} onClick={() => setSearchFilter("")}>
                <CloseIcon className={styles.cancelIcon} />
              </button>
            </div>
            <div className={styles.line} />
          </div>
          <button
            className={styles.buttonAdd}
            onClick={() => setOpenAddPopUp(true)}
          >
            <AddIcon className={styles.addIcon} />
            <b className={styles.text3}>Add reviewer</b>
          </button>
        </div>
        <div className={styles.container}>
          <div className={styles.tableName}>
            <b className={styles.tableNameText}>Reviewer List</b>
          </div>
          <div className={styles.roles}>
            <div className={styles.tableHeader}>
              <div className={styles.categoryEmail}>
                <div className={styles.tableHeaderText}>Email</div>
              </div>
            </div>
            <div className={styles.rolesBox}>
              {reviewersList.map((user) => (
                <div key={user.id} className={styles.roleDefault}>
                  <div className={styles.identification}>
                    <UserBlueIcon className={styles.userIcon} />
                    <div className={styles.tableText}>{user.email}</div>
                  </div>
                  <button className={styles.deleteButton}>
                    <DeleteIcon
                      className={styles.deleteIcon}
                      onClick={() => onDeleteClick(user.id)}
                    />
                  </button>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
      {openAddPopUp && (
        <AddReviewerDialog
          open={openAddPopUp}
          onCloseClick={() => setOpenAddPopUp(false)}
        />
      )}
    </div>
  );
};
