import React from "react";

export type LoginStatus = {
  status?: "invalid" | "valid";
  email?: string;
};

interface ContextProps {
  accountInfo: LoginStatus;
  setAccountInfo: (info: LoginStatus) => void;
}

export const GlobalContext = React.createContext<ContextProps>(
  {} as ContextProps
);
