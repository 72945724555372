import { AppAndRole, AppEntity } from "../models/app";
import { Role } from "../models/role";
import { ApiBase } from "./base";
import { ENDPOINTS } from "./constants";

export class AppService extends ApiBase {
  getApps = (): Promise<AppEntity[]> => {
    return this.fetch<AppEntity[]>(ENDPOINTS.APPS, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });
  };

  getAppRole = (appRoute: string): Promise<Role> => {
    return new Promise<Role>(async (resolve, reject) => {
      try {
        const role: Role = await this.fetch(`${appRoute}/${ENDPOINTS.ROLE}`);
        resolve(role);
      } catch (error) {
        reject(error);
      }
    });
  };

  getAppsAndRoles = (): Promise<AppAndRole[]> => {
    return this.fetch<AppAndRole[]>(ENDPOINTS.APPS_AND_ROLES, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });
  };
}
