import { MUIDataTableColumn, MUIDataTableColumnOptions } from "mui-datatables";

export function setDisplay(
  opt: MUIDataTableColumnOptions,
  display?: "true" | "false" | "excluded"
): MUIDataTableColumnOptions {
  opt["display"] = display;
  return opt;
}

export function setSortThirdClickReset(
  opt: MUIDataTableColumnOptions,
  value: boolean = true
): MUIDataTableColumnOptions {
  opt["sortThirdClickReset"] = value;
  return opt;
}

export function setColumnsSortThirdClickReset(columns: MUIDataTableColumn[], value: boolean = true) {
  columns.forEach((c) => {
    if (!c.options) {
      c.options = {};
    }
    setSortThirdClickReset(c.options, value);
  });
}


