import React from "react";

import { AppEntity } from "../../../../models/app";
import { AppIcon } from "../../../common/AppIcon";
import styles from "./css/AppButton.module.css";

interface Props {
  app: AppEntity;
  onClick: () => void;
}
export const AppButton: React.FC<Props> = (props) => {
  return (
    <button
      onClick={props.onClick}
      className={styles.appButton}
      style={{ backgroundColor: props.app.appColor || "" }}
    >
      <div className={styles.contentDiv}>
        <div className={styles.logoDiv}>
          <AppIcon iconName={props.app.appIconName} className={styles.icon} />
        </div>
        <div className={styles.textContentDiv}>
          <div className={styles.appTittleDiv}>{props.app.appName}</div>
          <div className={styles.descriptionDiv}>
            {props.app.appDescription}
          </div>
        </div>
      </div>
    </button>
  );
};
