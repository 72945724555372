import React from "react";

import { RequestAuditEntry } from "../../../../../models/OracleEBS/audit";
import { CloseIcon } from "../../../assets";
import styles from "./css/Audit.module.css";
import { Entry } from "./Entry";

interface Props {
  onClose: () => void;
  entries: RequestAuditEntry[];
}

const Audit: React.FC<Props> = ({ onClose, entries }) => {
  return (
    <div className={styles.auditDiv}>
      <div className={styles.headerDiv}>
        <div className={styles.closeDiv}>
          <div className={styles.frameDiv}>
            <b className={styles.headerTextB}>Audit</b>
            <button className={styles.closeButton} onClick={onClose}>
              <CloseIcon className={styles.closeIcon} />
            </button>
          </div>
        </div>
      </div>
      <div className={styles.entryContainerDiv}>
        {entries.map((entry) => (
          <Entry key={entry.request.eventID} entry={entry} />
        ))}
      </div>
    </div>
  );
};

export default Audit;
