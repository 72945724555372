import React from "react";

import Dialog from "@mui/material/Dialog";

import styles from "./css/ConfirmationDialog.module.css";
import { CloseIcon } from "../../assets";

interface Props {
  open: boolean;
  AdditionalButton?: React.ReactNode;
  AcceptButton: React.ReactNode;
  CancelButton: React.ReactNode;
  AdditionalComponent?: React.ReactNode;
  title: string;
  message?: string;
  onClickExit?: () => void;
}

export const ConfirmationDialog: React.FC<Props> = ({
  open,
  title,
  message,
  AdditionalButton,
  AdditionalComponent,
  AcceptButton,
  CancelButton,
  onClickExit,
}) => {
  return (
    <Dialog open={open}>
      <form className={styles.confirmationDialogForm}>
        <div className={styles.frameDiv}>
          {onClickExit && (
            <button
              className={styles.frameButton}
              type="button"
              onClick={onClickExit}
            >
              <CloseIcon className={styles.closeIcon} />
            </button>
          )}
          <div className={styles.contentDiv}>
            <div className={styles.textDiv}>
              <b className={styles.textB}>{title}</b>
              {message && (
                <div className={styles.descriptionDiv}>{message}</div>
              )}
            </div>
            {AdditionalComponent}
            <div className={styles.buttonsDiv}>
              {AdditionalButton}
              {AcceptButton}
              {CancelButton}
            </div>
          </div>
        </div>
      </form>
    </Dialog>
  );
};
