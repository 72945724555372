import React from "react";
import { Route, Routes } from "react-router-dom";

import { Dashboard } from "../Dashboard/Dashboard";
import { Router as OracleEBS } from "../Oracle EBS/layouts/Router";
import { APP_NAME as OEBS_APP_NAME } from "../../utils/OracleEBS/constants";
import { AppAndRole, AppEntity } from "../../models/app";
import { Role } from "../../models/role";

interface Props {
  appsAndRoles: AppAndRole[];
}

export const Router: React.FC<Props> = ({ appsAndRoles }) => {
  const getAppElement = (app: AppEntity, role: Role): JSX.Element => {
    switch (app.appName) {
      case OEBS_APP_NAME:
        return <OracleEBS app={app} role={role} />;

      default:
        return <></>;
    }
  };

  return (
    <Routes>
      <Route
        path="/"
        element={<Dashboard appList={appsAndRoles.map((elem) => elem.app)} />}
      />
      {appsAndRoles.map((elem) => (
        <Route
          key={elem.app.appRoutePath}
          path={
            elem.app.appRoutePath.startsWith("/")
              ? elem.app.appRoutePath
              : `/${elem.app.appRoutePath}/*`
          }
          element={getAppElement(elem.app, elem.role)}
        />
      ))}
    </Routes>
  );
};
