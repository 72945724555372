import { Dialog } from "@mui/material";
import React, { useEffect, useState } from "react";
import { AddIcon, CloseIcon } from "../../../assets";

import styles from "./css/EmailRecipientDialog.module.css";

interface Props {
  onClose: () => void;
  onSave: (newRecipients: string[]) => void;
  recipientsList: string[];
  recipientListRef: React.MutableRefObject<string[]>;
}

export const EmailRecipientDialog: React.FC<Props> = ({
  onClose,
  onSave,
  recipientsList,
  recipientListRef,
}) => {
  const [newRecipient, setNewRecipient] = useState<string>("");
  const [isEmailValid, setIsEmailValid] = useState<boolean>(false);
  const [newRecipientList, setNewRecipientsList] =
    useState<string[]>(recipientsList);

  const onInputChange = ({
    currentTarget: { value },
  }: React.ChangeEvent<HTMLInputElement>) => {
    setNewRecipient(value);
  };

  const deleteRecipient = (index: number) => () => {
    setNewRecipientsList((prev) => [
      ...prev.slice(0, index),
      ...prev.slice(index + 1),
    ]);
  };

  const addRecipient = () => {
    setNewRecipientsList((prev) => [...prev, newRecipient]);
    recipientListRef.current = [...newRecipientList, newRecipient];
    setNewRecipient("");
  };

  const onSaveClick = () => {
    onSave(newRecipientList);
  };

  useEffect(() => {
    const testEmail = /^[ ]*([^@\s]+)@((?:[-a-z0-9]+\.)+[a-z]{1,})[ ]*$/i;
    setIsEmailValid(testEmail.test(newRecipient));
  }, [newRecipient, setIsEmailValid]);

  return (
    <Dialog open>
      <div className={styles.container}>
        <div className={styles.frame}>
          <div className={styles.headerParent}>
            <div className={styles.header}>
              <div className={styles.content}>
                <div className={styles.popupTittle}>
                  <div className={styles.tittle}>Share request</div>
                </div>
                <button className={styles.x} onClick={onClose}>
                  <CloseIcon className={styles.vectorIcon} />
                </button>
              </div>
              <div className={styles.divider} />
            </div>
            <div className={styles.chipsContainer}>
              <div className={styles.chipsRow}>
                <div className={styles.chipsFrame}>
                  {newRecipientList.map((recipient, index) => (
                    <EmailChip
                      key={index}
                      address={recipient}
                      onDeleteClick={deleteRecipient(index)}
                    />
                  ))}
                </div>
              </div>
            </div>
            <div className={styles.group}>
              <input
                className={styles.emailInput}
                value={newRecipient}
                onChange={onInputChange}
              />
              <button
                className={styles.addButton}
                onClick={addRecipient}
                disabled={!isEmailValid}
              >
                <div className={styles.addButtonContent}>
                  <AddIcon className={styles.addIcon} />
                  <b className={styles.add}>Add</b>
                </div>
              </button>
            </div>
          </div>
          <div className={styles.buttonsContainer}>
            <button className={styles.saveButton} onClick={onSaveClick}>
              <b className={styles.saveButtonText}>Save changes</b>
            </button>
            <button className={styles.cancelButton} onClick={onClose}>
              <b className={styles.cancelButtonText}>Cancel</b>
            </button>
          </div>
        </div>
      </div>
    </Dialog>
  );
};

interface ChipProps {
  address: string;
  onDeleteClick: () => void;
}

const EmailChip: React.FC<ChipProps> = ({ address, onDeleteClick }) => {
  return (
    <div className={styles.email}>
      <button className={styles.xSmall} onClick={onDeleteClick}>
        <CloseIcon className={styles.xSmallIcon} />
      </button>
      <div className={styles.emailAddress}>{address}</div>
    </div>
  );
};
