import React from "react";

import { ButtonRounded } from "../../Buttons/Rounded/ButtonRounded";
import { ConfirmationDialog } from "../../Dialog/ConfirmationDialog";
import { FileSelector } from "../../FileSelector/FileSelector";

interface Props {
  open: boolean;
  handleFileChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  file: File;
  onDeleteClick: () => void;
  onCancelClick: () => void;
  onExitClick: () => void;
}

export const ImportXLSXDialog: React.FC<Props> = ({
  open,
  handleFileChange,
  file,
  onDeleteClick,
  onCancelClick,
  onExitClick,
}) => {
  return (
    <ConfirmationDialog
      open={open}
      title="Import"
      message="Select a file to import"
      AcceptButton={
        <FileSelector
          handleFileChange={handleFileChange}
          accept=".xlsx"
          file={file}
          onDeleteClick={onDeleteClick}
        />
      }
      CancelButton={
        <ButtonRounded
          variant={"Transparent"}
          type="button"
          onClick={onCancelClick}
        >
          Cancel
        </ButtonRounded>
      }
      onClickExit={onExitClick}
    />
  );
};
