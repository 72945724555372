import React from "react";

import { TextField } from "@mui/material";
import styles from "../css/Row.module.css";

interface FieldProps {
  isDeleted: boolean;
  value: string;
}

export const FieldDisplay: React.FC<FieldProps> = ({ isDeleted, value }) => {
  return (
    <div className={isDeleted ? styles.cellDeleted : styles.cell}>
      <TextField
        className={styles.bodyCellAutocomplete}
        inputProps={{
          readOnly: true,
          className: isDeleted && styles.rowTextDeleted,
        }}
        value={value}
        color="secondary"
        label=""
        size="medium"
        variant="outlined"
      />
    </div>
  );
};
