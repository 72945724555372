import Axios, { AxiosRequestConfig } from "axios";
import { Login, Session } from "../models/account";
import { ApiBase } from "./base";
import { ENDPOINTS } from "./constants";

const SESSION_KEY = "AUTH_SESSION";

// Set interceptor to send the session token for all requests.
Axios.interceptors.request.use((config: AxiosRequestConfig) => {
  const session = getStoredSession();
  if (session) {
    config.headers = { Authorization: "Bearer " + session.token };
  }
  return config;
});

export class AccountService extends ApiBase {
  login = async (info: Login): Promise<void> => {
    const session = await this.fetch<Session>(ENDPOINTS.LOGIN, {
      method: "POST",
      headers: {
        "content-type": "application/json",
      },
      data: JSON.stringify(info),
    });

    localStorage.setItem(SESSION_KEY, JSON.stringify(session));
  };

  checkSession = (): Promise<Session> => {
    return new Promise<Session>(async (resolve, reject) => {
      try {
        await this.fetch(ENDPOINTS.AUTHENTICATED);
        const session = getStoredSession();
        if (session) {
          resolve(session);
          return;
        }
        reject();
      } catch (error) {
        reject(error);
      }
    });
  };
}

export const getStoredSession = () => {
  const sessionData = localStorage.getItem(SESSION_KEY);
  if (sessionData) {
    return JSON.parse(sessionData) as Session;
  }
};
