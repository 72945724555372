import React, { useState } from "react";
import moment from "moment";
import { Grid, IconButton, makeStyles } from "@material-ui/core/";
import {
  KeyboardDatePicker,
  KeyboardDateTimePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import { MaterialUiPickersDate } from "@material-ui/pickers/typings/date";
import DateFnsUtils from "@date-io/date-fns";
import { Props } from ".";

type DatePickerBaseProps = {
  /** The label to be displayed on the component. */
  label?: string;
  /** The timestamp of the value the component will display. If undefined, they input will be empty. */
  value?: number;
  /** Function that will be called when the component picks a valid date. */
  onChange?: (value?: number) => void;
  /** Wether or not this input is required. */
  required?: boolean;
  /** The message the component will display when the user enters an invalid date. If undefined, it will display `Invalid date.` */
  invalidDateMsg?: string;
  /** Icon for the clear button, that will empty the input when pressed. If undefined, the button will not be displayed. */
  clearIcon?: JSX.Element;

  /** Wether or not the component should display an error message. */
  error?: boolean;
  /** The error message the component should display. */
  helperText?: string;
  /** The function that is called when an invalid date is entered. */
  onDateError?: (value?: MaterialUiPickersDate) => void;

  /** Wether or not the component style is fullWith. */
  fullWidth?: boolean;
  /** Class for the component style. */
  className?: string;
};

export type DatePickerProps = DatePickerBaseProps & {
  /** Wether or not the component should set to zero the hour, minutes, seconds and milliseconds of the given input.
   *  Keep in mind that this change is made using UTC time zone. The initial value given will not be formatted. */
  clearHourMinSec?: boolean;
};

export type DateTimePickerProps = DatePickerBaseProps;

export const useStyles = makeStyles({
  grid: {
    flexDirection: "row",
    display: "flex",
  },
  textDate: {
    display: "flex",
  },
  iconButton: {
    display: "flex",
    marginTop: 20,
  },
});

export const INVALID_DATE_MSG = "Invalid date.";

type DatePickerBaseType = "date" | "datetime";

/**
 * Simple picker for dates that will unworry you about time zones.
 */
export const DatePickerBase = (type: DatePickerBaseType) => {
  const Base: React.FC<Props> = ({
    label,
    value,
    onChange,
    required,
    invalidDateMsg,
    clearIcon,
    clearHourMinSec,
    error,
    helperText,
    onDateError,
    fullWidth,
    className,
  }: DatePickerProps) => {
    const classes = useStyles();
    const [dateError, setDateError] = useState<string>();

    const handleChange = (value: MaterialUiPickersDate) => {
      if (!value) {
        onError();
        onChange(undefined);
        return;
      }
      const valueTime = value.getTime();
      if (isNaN(valueTime)) {
        onError(value, invalidDateMsg || INVALID_DATE_MSG);
        return;
      }

      if (onChange) {
        const momentDate = moment.utc(valueTime);
        onError();
        if (clearHourMinSec) {
          momentDate.utcOffset(0, true);
          momentDate.set("hour", 0);
          momentDate.set("minute", 0);
          momentDate.set("second", 0);
          momentDate.set("millisecond", 0);
        }
        onChange(momentDate.unix());
      }
    };

    const onError = (value?: MaterialUiPickersDate, msg?: string) => {
      setDateError(msg);
      if (onDateError && value) {
        onDateError(value);
      }
    };

    const renderPicker = (type: DatePickerBaseType) => {
      switch (type) {
        case "date":
          return (
            <KeyboardDatePicker
              label={label || ""}
              disableToolbar
              variant="inline"
              format={"dd/MM/yyyy"}
              margin="normal"
              value={value ? moment.unix(value) : null}
              fullWidth={fullWidth}
              required={required}
              error={error || !!dateError}
              helperText={helperText || dateError}
              className={className || classes.textDate}
              onChange={handleChange}
              KeyboardButtonProps={{
                "aria-label": "change date",
              }}
            />
          );
        case "datetime":
          return (
            <KeyboardDateTimePicker
              label={label || ""}
              disableToolbar
              variant="inline"
              format={"dd/MM/yyyy HH:mm"}
              ampm={false}
              margin="normal"
              value={value ? moment.unix(value) : null}
              fullWidth={fullWidth}
              required={required}
              error={error || !!dateError}
              helperText={helperText || dateError}
              className={className || classes.textDate}
              onChange={handleChange}
              KeyboardButtonProps={{
                "aria-label": "change date",
              }}
            />
          );
      }
    };

    return (
      <div data-qa="date-picker">
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <Grid className={classes.grid}>
            {renderPicker(type)}

            {clearIcon && (
              <IconButton
                data-qa="clear-button"
                color="secondary"
                className={classes.iconButton}
                disabled={value === undefined}
                onClick={() => {
                  if (onChange) {
                    onChange(undefined);
                  }
                }}
              >
                {clearIcon}
              </IconButton>
            )}
          </Grid>
        </MuiPickersUtilsProvider>
      </div>
    );
  };

  return Base;
};
