export enum RequestStatus {
  Resolved = "Resolved",
  Rejected = "Rejected",
  Submitted = "Submitted",
  Deleted = "Deleted",
  Completed = "Completed",
}

export enum RequestType {
  Creation = "Creation",
  Edition = "Edition",
  Inactivation = "Inactivation",
}

export interface RequestEntity {
  requestID: number;
  type: RequestType;
  lastUpdatedBy: number;
  lastUpdatedByEmail?: string;
  requesterID: number;
  requesterEmail?: string;
  reviewerID?: number;
  reviewerEmail?: string;
  date: number;
  lastUpdate?: number;
  status: RequestStatus;
  rows?: RequestRow[];
  lastComment?: RequestComment;
  expirationDate?: number;
  notifyTo?: string[];
  snTicketID?: string;
}

export interface RowField {
  code?: number;
  text?: string;
}

export interface RequestRow {
  rowID: number;
  requestID: number;
  rowNumber: number;
  legalEntity: RowField;
  businessUnit: RowField;
  function: RowField;
  department: RowField;
  team: RowField;
  inactiveDate?: number;
  mappingTeam: string;
  mappingOffice: string;
  isDeleted?: boolean;
}

export interface TeamMappings {
  mappingTeam: string;
  mappingOffice: string;
}

export interface RequestComment {
  commentID: number;
  requestID: number;
  authorID: number;
  authorEmail?: string;
  text: string;
  date: number;
}

export interface ValidationResponse {
  rows: RequestRow[];
  invalidMsg: string;
}
