import React from "react";

import FileUploadIcon from "@mui/icons-material/FileUpload";

import { CloseIcon } from "../../assets";
import styles from "./css/FileSelector.module.css";
import { ButtonRounded } from "../Buttons/Rounded/ButtonRounded";
import { Tooltip } from "@mui/material";

interface Props {
  handleFileChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  accept?: string;
  file?: File;
  onDeleteClick: () => void;
}

export const FileSelector: React.FC<Props> = ({
  handleFileChange,
  accept,
  file,
  onDeleteClick,
}) => {
  const fileInputRef = React.createRef<HTMLInputElement>();
  return (
    <div className={styles.fileSelectorDiv}>
      <ButtonRounded
        variant="Secondary"
        onClick={() => {
          fileInputRef.current.click();
          fileInputRef.current.value = "";
        }}
      >
        <FileUploadIcon />
        Choose a file
        <input
          ref={fileInputRef}
          type="file"
          hidden
          accept={accept}
          onChange={handleFileChange}
        />
      </ButtonRounded>

      {file && (
        <div className={styles.fileNameDiv}>
          <button
            className={styles.xButton}
            type="button"
            onClick={onDeleteClick}
          >
            <CloseIcon className={styles.vectorIcon} />
          </button>
          <Tooltip title={file.name} placement="top" arrow>
            <div className={styles.fileNameP}>{file.name}</div>
          </Tooltip>
        </div>
      )}
    </div>
  );
};
