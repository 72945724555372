import { ErrorResponse } from "../models/error";

export const NO_RESPONSE = "NO_RESPONSE";
export const NO_RESPONSE_MESSAGE =
  "The request was made but no response was received";
export const UNKNOWN_ERROR = "unknown_error";

export const inferError = (error: any): ErrorResponse => {
  if (error.response) {
    return error.response.data
      ? error.response.data
      : { errorCode: UNKNOWN_ERROR, errorMessage: error.message };
  } else if (error.request) {
    // The request was made but no response was received
    return {
      errorCode: NO_RESPONSE,
      errorMessage: NO_RESPONSE_MESSAGE,
    };
  }
  // Something happened in setting up the request that triggered an Error
  return {
    errorCode: UNKNOWN_ERROR,
    errorMessage: "An unexpected error occurred",
  };
};
