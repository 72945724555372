import React from "react";

import { Autocomplete, TextField } from "@mui/material";

import styles from "../css/Row.module.css";
import { RowField } from "../../../../../models/OracleEBS/request";

interface RowFieldAutocompleteProps {
  onChange: (
    event: React.ChangeEvent<HTMLInputElement>,
    value: string | null
  ) => void;
  value: RowField;
  options: RowField[];
  placeholder: string;
  invalidCell: boolean;
  isFreeSolo?: boolean;
  disabled?: boolean;
}

export const RowFieldAutocomplete: React.FC<RowFieldAutocompleteProps> = ({
  value,
  options,
  onChange,
  placeholder,
  invalidCell,
  isFreeSolo,
  disabled,
}) => {
  return (
    <div className={styles.cell}>
      <div className={`${invalidCell && styles.invalidCell}`}>
        <Autocomplete
          freeSolo={isFreeSolo}
          autoHighlight
          disabled={disabled}
          value={value || null}
          onChange={onChange}
          className={styles.bodyCellAutocomplete}
          options={options}
          getOptionLabel={(option: RowField) => option.text || ""}
          isOptionEqualToValue={(opt1, opt2) => opt1.code === opt2.code}
          renderInput={(params: any) => (
            <TextField
              {...params}
              required
              onChange={isFreeSolo && onChange}
              color="secondary"
              label=""
              variant="outlined"
              placeholder={placeholder}
              helperText=""
            />
          )}
          size="medium"
        />
      </div>
    </div>
  );
};
