import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";

import { ThemeProvider, createTheme } from "@mui/material/styles";

import styles from "../css/Row.module.css";
import { RequestRow, RowField } from "../../../../../models/OracleEBS/request";
import {
  ROW_FIELD_NAMES,
  ROW_FIELD_PARAM,
} from "../../../../../utils/OracleEBS/constants";
import { RequestService } from "../../../../../api/OracleEBS/request";
import { Config } from "../../../../../utils/config";
import { inferError } from "../../../../../utils/inferError";
import { useSnackbar } from "notistack";
import { StringAutocomplete } from "../common/StringAutocomplete";
import { OEBSGlobalContext } from "../../../context/OEBSGlobal";
import { RowFieldAutocomplete } from "../common/RowFieldAutocomplete";

const theme = createTheme({
  components: {
    MuiOutlinedInput: {
      styleOverrides: {
        notchedOutline: {
          border: "none",
        },
      },
    },
    MuiAutocomplete: {
      styleOverrides: {
        root: { border: "none" },
        listbox: {
          overflow: "unset",
        },
        paper: {
          backgroundColor: "#eaebf2",
          maxHeight: "200px",
        },
      },
    },
  },
});

interface Props {
  row: RequestRow;
  handleChange?: (
    rowNumber: number,
    field: string,
    value: string | number | string | null
  ) => void;
  deleteSelection?: (rowNumber: number, fields: string[]) => void;
  legalEntityOptions?: RowField[];
  mappingTeamOptions?: string[];
  mappingOfficeOptions?: string[];
  markWrongCells: boolean;
}

export const TeamEditionRow: React.FC<Props> = ({
  row,
  handleChange,
  deleteSelection,
  legalEntityOptions,
  mappingTeamOptions,
  mappingOfficeOptions,
  markWrongCells,
}) => {
  const { app } = useContext(OEBSGlobalContext);
  const { enqueueSnackbar } = useSnackbar();
  const [businessUnitOptions, setBusinessUnitOptions] = useState<RowField[]>(
    []
  );
  const [functionOptions, setFunctionOptions] = useState<RowField[]>([]);
  const [departmentOptions, setDepartmentOptions] = useState<RowField[]>([]);
  const [teamOptions, setTeamOptions] = useState<RowField[]>([]);
  const requestService = useMemo(
    () => new RequestService(`${Config.apiUrl}${app?.appRoutePath}`),
    [app?.appRoutePath]
  );
  const [markCell, setMarkCell] = useState<{
    legalEntity: boolean;
    businessUnit: boolean;
    function: boolean;
    department: boolean;
    team: boolean;
    mappingTeam: boolean;
    mappingOffice: boolean;
  }>({
    legalEntity: markWrongCells && !row.legalEntity,
    businessUnit: markWrongCells && !row.businessUnit && !!row.legalEntity,
    function: markWrongCells && !row.function && !!row.businessUnit,
    department: markWrongCells && !row.department && !!row.function,
    team: markWrongCells && !row.team && !!row.department,
    mappingTeam: markWrongCells && !row.mappingTeam,
    mappingOffice: markWrongCells && !row.mappingOffice,
  });

  const getFieldOptions = useCallback(
    async (field: string, parent: number) => {
      try {
        const options = await requestService.getFieldOptionsByParent(
          field,
          parent
        );
        return options;
      } catch (error: any) {
        const err = inferError(error);
        enqueueSnackbar(err.errorMessage, { variant: "error" });
      }
    },
    [enqueueSnackbar, requestService]
  );

  const getTeamMappings = useCallback(
    async (team: number) => {
      try {
        const mappings = await requestService.getTeamMappings(team);
        return mappings;
      } catch (error: any) {
        const err = inferError(error);
        enqueueSnackbar(err.errorMessage, { variant: "error" });
      }
    },
    [enqueueSnackbar, requestService]
  );

  useEffect(() => {
    (async () => {
      setMarkCell({
        legalEntity: markWrongCells && !row.legalEntity,
        businessUnit: markWrongCells && !row.businessUnit && !!row.legalEntity,
        function: markWrongCells && !row.function && !!row.businessUnit,
        department: markWrongCells && !row.department && !!row.function,
        team: markWrongCells && !row.team && !!row.department,
        mappingTeam: markWrongCells && !row.mappingTeam,
        mappingOffice: markWrongCells && !row.mappingOffice,
      });
      if (row.legalEntity) {
        const businessUnitOps = await getFieldOptions(
          ROW_FIELD_PARAM.BUSINESS_UNIT,
          row.legalEntity.code
        );
        setBusinessUnitOptions(businessUnitOps);
      }
      if (row.businessUnit) {
        const functionOps = await getFieldOptions(
          ROW_FIELD_PARAM.FUNCTION,
          row.businessUnit.code
        );
        setFunctionOptions(functionOps);
      }
      if (row.function) {
        const departmentOps = await getFieldOptions(
          ROW_FIELD_PARAM.DEPARTMENT,
          row.function.code
        );
        setDepartmentOptions(departmentOps);
      }
      if (row.department) {
        const teamOps = await getFieldOptions(
          ROW_FIELD_PARAM.TEAM,
          row.department.code
        );
        setTeamOptions(teamOps);
      }
    })();
  }, [
    getFieldOptions,
    handleChange,
    markWrongCells,
    row.businessUnit,
    row.department,
    row.function,
    row.legalEntity,
    row.mappingOffice,
    row.mappingTeam,
    row.rowNumber,
    row.team,
  ]);

  useEffect(() => {
    (async () => {
      if (row.team && !row.mappingTeam && !row.mappingOffice) {
        const mappings = await getTeamMappings(row.team.code);
        if (mappings === null) return;
        handleChange(
          row.rowNumber,
          ROW_FIELD_NAMES.MAPPING_TEAM,
          mappings.mappingTeam
        );
        handleChange(
          row.rowNumber,
          ROW_FIELD_NAMES.MAPPING_OFFICE,
          mappings.mappingOffice
        );
      }
    })();
  }, [
    getTeamMappings,
    handleChange,
    row.mappingOffice,
    row.mappingTeam,
    row.rowNumber,
    row.team,
  ]);

  const onChange =
    (field: string) =>
    async (
      event: React.ChangeEvent<HTMLInputElement>,
      value: string | string | null
    ) => {
      handleChange(row.rowNumber, field, value || event.target.value);
      let fieldsToClear: string[] = [];
      switch (field) {
        case ROW_FIELD_NAMES.LEGAL_ENTITY:
          fieldsToClear = [
            ROW_FIELD_NAMES.BUSINESS_UNIT,
            ROW_FIELD_NAMES.FUNCTION,
            ROW_FIELD_NAMES.DEPARTMENT,
            ROW_FIELD_NAMES.TEAM,
            ROW_FIELD_NAMES.MAPPING_TEAM,
            ROW_FIELD_NAMES.MAPPING_OFFICE,
          ];
          break;
        case ROW_FIELD_NAMES.BUSINESS_UNIT:
          fieldsToClear = [
            ROW_FIELD_NAMES.FUNCTION,
            ROW_FIELD_NAMES.DEPARTMENT,
            ROW_FIELD_NAMES.TEAM,
            ROW_FIELD_NAMES.MAPPING_TEAM,
            ROW_FIELD_NAMES.MAPPING_OFFICE,
          ];
          break;
        case ROW_FIELD_NAMES.FUNCTION:
          fieldsToClear = [
            ROW_FIELD_NAMES.DEPARTMENT,
            ROW_FIELD_NAMES.TEAM,
            ROW_FIELD_NAMES.MAPPING_TEAM,
            ROW_FIELD_NAMES.MAPPING_OFFICE,
          ];
          break;
        case ROW_FIELD_NAMES.DEPARTMENT:
          fieldsToClear = [
            ROW_FIELD_NAMES.TEAM,
            ROW_FIELD_NAMES.MAPPING_TEAM,
            ROW_FIELD_NAMES.MAPPING_OFFICE,
          ];
          break;
        case ROW_FIELD_NAMES.TEAM:
          fieldsToClear = [
            ROW_FIELD_NAMES.MAPPING_TEAM,
            ROW_FIELD_NAMES.MAPPING_OFFICE,
          ];
          break;
      }
      if (fieldsToClear.length > 0)
        deleteSelection(row.rowNumber, fieldsToClear);
    };

  return (
    <ThemeProvider theme={theme}>
      <div className={styles.rowDiv}>
        <RowFieldAutocomplete
          value={row.legalEntity}
          onChange={onChange(ROW_FIELD_NAMES.LEGAL_ENTITY)}
          options={legalEntityOptions}
          disabled={false}
          placeholder="Eclipse Legal Entity"
          invalidCell={markCell.legalEntity}
        />
        <RowFieldAutocomplete
          value={row.businessUnit}
          onChange={onChange(ROW_FIELD_NAMES.BUSINESS_UNIT)}
          options={businessUnitOptions}
          disabled={!row.legalEntity}
          placeholder="Eclipse Business Unit"
          invalidCell={markCell.businessUnit}
        />
        <RowFieldAutocomplete
          value={row.function}
          onChange={onChange(ROW_FIELD_NAMES.FUNCTION)}
          options={functionOptions}
          disabled={!row.businessUnit}
          placeholder="Eclipse Function"
          invalidCell={markCell.function}
        />
        <RowFieldAutocomplete
          value={row.department}
          onChange={onChange(ROW_FIELD_NAMES.DEPARTMENT)}
          options={departmentOptions}
          disabled={!row.function}
          placeholder="Eclipse Department"
          invalidCell={markCell.department}
        />
        <RowFieldAutocomplete
          value={row.team}
          onChange={onChange(ROW_FIELD_NAMES.TEAM)}
          options={teamOptions}
          disabled={!row.department}
          placeholder="Eclipse Team"
          invalidCell={markCell.team}
        />
        <StringAutocomplete
          value={row.mappingTeam}
          options={mappingTeamOptions}
          disabled={!row.team}
          onChange={onChange(ROW_FIELD_NAMES.MAPPING_TEAM)}
          placeholder="Oracle/EBS Mapping Team"
          invalidCell={markCell.mappingTeam}
        />
        <StringAutocomplete
          value={row.mappingOffice}
          options={mappingOfficeOptions}
          disabled={!row.team}
          onChange={onChange(ROW_FIELD_NAMES.MAPPING_OFFICE)}
          placeholder="Oracle/EBS Mapping Office"
          invalidCell={markCell.mappingOffice}
        />
      </div>
    </ThemeProvider>
  );
};
