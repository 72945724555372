import React from "react";
import {
  ConfirmationDialogContext,
  DialogOptions,
} from "../context/ConfirmationDialog";

export const useConfirmationDialog = () => {
  const { openDialog } = React.useContext(ConfirmationDialogContext);

  const getConfirmation = React.useCallback(
    ({ ...options }: DialogOptions) =>
      new Promise<boolean>((res) => {
        openDialog({ ...options, actionCallBack: res });
      }),
    [openDialog]
  );

  return { getConfirmation };
};
