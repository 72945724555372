import React from "react";

import { ThemeProvider, createTheme } from "@mui/material/styles";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { LocalizationProvider } from "@mui/x-date-pickers";

import styles from "../css/Row.module.css";
import {
  RequestRow,
  RequestType,
} from "../../../../../models/OracleEBS/request";
import { uiDate } from "../../../../../utils/time";
import { FieldDisplay } from "./FieldDisplay";

const theme = createTheme({
  components: {
    MuiOutlinedInput: {
      styleOverrides: {
        notchedOutline: {
          border: "none",
        },
      },
    },
    MuiAutocomplete: {
      styleOverrides: {
        root: { border: "none" },
        paper: {
          backgroundColor: "#eaebf2",
          maxHeight: "200px",
        },
      },
    },
  },
});

interface Props {
  type: RequestType;
  row: RequestRow;
}

export const RowDisplay: React.FC<Props> = ({ type, row }) => {
  return (
    <LocalizationProvider dateAdapter={AdapterMoment}>
      <ThemeProvider theme={theme}>
        <div
          className={`${styles.rowDiv} ${
            row.isDeleted && styles.rowDivDeleted
          }`}
        >
          <FieldDisplay
            isDeleted={row.isDeleted}
            value={row.legalEntity.text}
          />
          <FieldDisplay
            isDeleted={row.isDeleted}
            value={row.businessUnit.text}
          />
          <FieldDisplay isDeleted={row.isDeleted} value={row.function.text} />
          <FieldDisplay isDeleted={row.isDeleted} value={row.department.text} />
          <FieldDisplay isDeleted={row.isDeleted} value={row.team.text} />
          {type === RequestType.Inactivation ? (
            <FieldDisplay
              isDeleted={row.isDeleted}
              value={row.inactiveDate ? uiDate(row.inactiveDate) : "-"}
            />
          ) : (
            <>
              <FieldDisplay isDeleted={row.isDeleted} value={row.mappingTeam} />
              <FieldDisplay
                isDeleted={row.isDeleted}
                value={row.mappingOffice}
              />
            </>
          )}
        </div>
      </ThemeProvider>
    </LocalizationProvider>
  );
};
