import { makeStyles } from "@material-ui/core";
import {
  FilterType,
  MUIDataTableColumn,
  MUIDataTableColumnOptions,
  MUIDataTableMeta,
} from "mui-datatables";
import React, { useCallback } from "react";
import { StringInputDelay } from "../StringInputDelay";

export interface Props {
  /** Label for the string filter input */
  label: string;
  /** filterList structure. Must be provided from MUI-Datatable. */
  filterList: string[][];
  /** onChange function. Must be similar to the one provided by MUI-Datatable. */
  onChange: (
    value: string[],
    index: number,
    column: MUIDataTableColumn
  ) => void;
  /** Index of the element. Must be provided from MUI-Datatable. */
  index: number;
  /** Column of the element. Must be provided from MUI-Datatable. */
  column: MUIDataTableColumn;
  /** The amount of milliseconds that are garanteed to be waited before calling 'onChange'. */
  delay?: number;
  /** Icon for the clean button. If not provided, there will be no button. */
  clearIcon?: JSX.Element;
  /** className for the input. */
  className?: string;
}

const useStyles = makeStyles({
  grid: {
    flexDirection: "row",
    display: "flex",
  },
  text: {
    display: "flex",
  },
});

export const StringFilter: React.FC<Props> = ({
  label,
  filterList,
  onChange,
  index,
  column,
  delay,
  clearIcon,
  className,
}) => {
  const classes = useStyles();

  const filterListDep = JSON.stringify(filterList);
  const onChangeCallback = useCallback(
    (value?: string) => {
      const filterList = JSON.parse(filterListDep) as string[][];
      // We need to be able to send `undefined` value due to necessity to make a distintion between 3 values:
      // - Filtering by something
      // - Filtering by null value
      // - Not filtering
      // which is impossible to get with the string type that the component offers us.
      if (value === undefined || value === "") {
        filterList[index] = [];
      } else {
        filterList[index][0] = value;
      }
      onChange(filterList[index], index, column);
    },
    [onChange, index, column, filterListDep]
  );

  return (
    <StringInputDelay
      data-qa="string-filter"
      label={label}
      fullWidth
      onChange={onChangeCallback}
      value={filterList[index][0]}
      className={className || classes.text}
      clearIcon={clearIcon}
      delay={delay}
    />
  );
};

export function applyLogic(value: string, filters: string[]): boolean {
  if (filters.length < 1) {
    return false;
  }
  // If the value is null, we treat it like an empty string.
  if (!value) {
    value = "";
  }
  if (!filters[0]) {
    return value !== "";
  }
  return !value.toLowerCase().includes(filters[0].toLowerCase());
}

const customFilter: FilterType = "custom";

export function getStringFilterOptions(
  label: string,
  clearIcon?: JSX.Element,
  filterList?: string[],
  customBodyRender?: (
    value: any,
    tableMeta: MUIDataTableMeta,
    updateValue: (value: string) => void
  ) => React.ReactNode
): MUIDataTableColumnOptions {
  return {
    customBodyRender: customBodyRender,
    filterType: customFilter,
    filterList: filterList,
    filterOptions: {
      names: [],
      logic: applyLogic,
      display: (
        filterList: string[][],
        onChange: (
          value: string[],
          index: number,
          column: MUIDataTableColumn
        ) => void,
        index: number,
        column: MUIDataTableColumn
      ) => {
        return (
          <StringFilter
            label={label}
            // No new function is created here to avoid the infinite loop caused on `TextInputDelay`'s useEffect
            onChange={onChange}
            clearIcon={clearIcon}
            column={column}
            index={index}
            filterList={filterList}
          />
        );
      },
    },
    customFilterListOptions: {
      render: (value: string) => {
        if (value === undefined || value === null) {
          return "";
        }
        if (!clearIcon && value === "") {
          return `No ${label}`;
        }
        return `${label}: ${value}`;
      },
    },
  };
}
