import clone from "clone";
import React, { useState } from "react";

import { AppEntity } from "../../../models/app";
import {
  defaultPageInformationRequest,
  PageInformationRequest,
} from "../../../models/OracleEBS/tablePageInformation";
import { Role } from "../../../models/role";

interface ContextProps {
  requestPageInformation: PageInformationRequest;
  setRequestPageInformation: React.Dispatch<
    React.SetStateAction<PageInformationRequest>
  >;
  app: AppEntity;
  role: Role;
}

export const OEBSGlobalContext = React.createContext<ContextProps>(
  {} as ContextProps
);
interface ProviderProps {
  children: React.ReactNode;
  app: AppEntity;
  role: Role;
}

export const OEBSGlobalProvider: React.FC<ProviderProps> = ({
  children,
  app,
  role,
}) => {
  const [requestPageInformation, setRequestPageInformation] =
    useState<PageInformationRequest>(clone(defaultPageInformationRequest));

  return (
    <OEBSGlobalContext.Provider
      value={{
        requestPageInformation,
        setRequestPageInformation,
        app,
        role,
      }}
    >
      {children}
    </OEBSGlobalContext.Provider>
  );
};
