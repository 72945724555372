import React, { useContext, useMemo, useRef, useState } from "react";

import { useNavigate } from "react-router-dom";
import { useSnackbar } from "notistack";

import { getStoredSession } from "../../../../api/account";
import { RequestService } from "../../../../api/OracleEBS/request";
import {
  RequestComment,
  RequestEntity,
  RequestRow,
  RequestStatus,
  RequestType,
} from "../../../../models/OracleEBS/request";
import { Config } from "../../../../utils/config";
import { inferError } from "../../../../utils/inferError";
import { getCurrentUserID } from "../../../../utils/session";
import { now } from "../../../../utils/time";
import {
  AddIcon,
  CancelIcon,
  DeleteIcon,
  DownloadIcon,
  EditIcon,
} from "../../assets";
import styles from "./css/Request.module.css";
import { ButtonRounded } from "../Buttons/Rounded/ButtonRounded";
import { useConfirmationDialog } from "../../hooks/useConfirmationDialog";
import { getXLSXFile } from "./common/getXLSXFile";
import { readFromXLSX } from "../../../../utils/OracleEBS/XLSX";
import { ImportXLSXDialog } from "./common/ImportXLSXDialog";
import { OEBSGlobalContext } from "../../context/OEBSGlobal";
import { FormTable } from "../RequestTable/common/FormTable";
import { RectangularButton } from "./components/RectangularButton";
import { EmailRecipientDialog } from "./components/EmailRecipientDialog";
import { LoadingProgressContext } from "../../context/LoadingProgress";

interface Props {
  type: RequestType;
}

export const CreateRequest: React.FC<Props> = ({ type }) => {
  const [rows, setRows] = useState<RequestRow[]>([
    { rowNumber: 1 } as RequestRow,
  ]);
  const [comment, setComment] = useState<string>("");
  const { app } = useContext(OEBSGlobalContext);
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const requestService = useMemo(
    () => new RequestService(`${Config.apiUrl}${app?.appRoutePath}`),
    [app?.appRoutePath]
  );
  const { getConfirmation } = useConfirmationDialog();
  const [openImportRequest, setOpenImportRequest] = useState<boolean>(false);
  const [requestFile, setRequestFile] = useState<File>();
  const [markWrongCells, setMarkWrongCells] = useState<boolean>(false);
  const [importedRows, setImportedRows] = useState<number>(0);
  const [addRecipients, setAddRecipients] = useState<boolean>(false);
  const [recipientList, setRecipientsList] = useState<string[]>([]);
  const recipientListRef = useRef<string[]>([]);
  const { startLoading, stopLoading } = useContext(LoadingProgressContext);

  const validations = () => {
    if (rows.length < 1) return new Error("You can't send an empty request");
    if (rows.find((row) => Number.isNaN(row.inactiveDate)))
      return new Error("Dates must be valid");
    return undefined;
  };

  const onSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    event.stopPropagation();
    const err = validations();
    if (err) {
      enqueueSnackbar(err.message, {
        autoHideDuration: 1500,
        variant: "error",
      });
      return;
    }
    const confirmed = await getConfirmation({
      title: "Submit Request",
      message: "This request will be sent to be reviewed",
      confirmButton: {
        content: "Yes, submit",
        variant: "Terciary",
      },
      additionalButton: (
        <RectangularButton
          onClick={() => {
            setAddRecipients(true);
          }}
        />
      ),
    });
    if (!confirmed) return;

    try {
      startLoading();
      const session = getStoredSession();
      const userID = getCurrentUserID(session);
      const date = now();
      const lastComment: RequestComment = comment
        ? {
            commentID: 0,
            requestID: 0,
            authorID: userID,
            date: date,
            text: comment,
          }
        : undefined;
      const request: RequestEntity = {
        requestID: 0,
        type: type,
        lastUpdatedBy: userID,
        requesterID: userID,
        date: date,
        status: RequestStatus.Submitted,
        rows: rows,
        lastComment: lastComment,
        notifyTo: recipientListRef.current,
      };
      await requestService.createRequest(request);
      stopLoading();
      enqueueSnackbar("Succeed! A notification email has been sent", {
        variant: "success",
      });
    } catch (error: any) {
      stopLoading();
      const err = inferError(error);
      enqueueSnackbar(err.errorMessage, { variant: "error" });
      return;
    }

    navigate(`/${app.appRoutePath}`);
  };

  const backToList = async () => {
    const confirmed = await getConfirmation({
      title: "Are you sure you want to exit?",
      message: "All progress made will be lost",
      confirmButton: {
        content: "Exit request",
        variant: "Red",
      },
    });
    if (!confirmed) return;

    navigate(`/${app.appRoutePath}`);
  };

  const handleFileChange = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const file = event.target.files[0];
    const fileExt = file.name.split(".").pop();
    if (fileExt !== "xlsx") {
      enqueueSnackbar("Wrong file extension. Please provide a .xlsx file", {
        variant: "error",
      });
      return;
    }
    setRequestFile(file);
    try {
      const xlsxRows = await readFromXLSX(file, 0, type);
      xlsxRows.rows = xlsxRows.rows.filter((row) => row.status !== "Canceled");
      const validatedRows = await requestService.validateXMLSRows(xlsxRows);
      setRows(validatedRows.rows);
      setMarkWrongCells(true);
      setImportedRows(validatedRows.rows.length);
      setOpenImportRequest(false);
      if (validatedRows.invalidMsg !== "") {
        enqueueSnackbar(validatedRows.invalidMsg, {
          autoHideDuration: 60000,
          variant: "error",
        });
      }
    } catch (error: any) {
      const err = inferError(error);
      enqueueSnackbar(err.errorMessage, { variant: "error" });
      return;
    }
  };

  const disableEnterKey = (event: React.KeyboardEvent<HTMLFormElement>) => {
    if (event.key === "Enter") event.preventDefault();
  };

  let title = <></>;
  switch (type) {
    case RequestType.Creation:
      title = (
        <>
          <div className={styles.createTeamIconDiv}>
            <AddIcon />
          </div>
          <div className={styles.createTeamTitle}>Create team</div>
        </>
      );
      break;
    case RequestType.Edition:
      title = (
        <>
          <div className={styles.editTeamIconDiv}>
            <EditIcon />
          </div>
          <div className={styles.editTeamTitle}>Edit team mapping</div>
        </>
      );
      break;
    case RequestType.Inactivation:
      title = (
        <>
          <div className={styles.inactivateTeamIconDiv}>
            <CancelIcon />
          </div>
          <div className={styles.inactivateTeamTitle}>Inactivate team</div>
        </>
      );
      break;
  }

  return (
    <div className={styles.containerDiv}>
      {addRecipients && (
        <EmailRecipientDialog
          recipientsList={recipientList}
          onClose={() => {
            setAddRecipients(false);
          }}
          onSave={(newRecipients: string[]) => {
            setRecipientsList(newRecipients);
            setAddRecipients(false);
          }}
          recipientListRef={recipientListRef}
        />
      )}
      <ImportXLSXDialog
        open={openImportRequest}
        handleFileChange={handleFileChange}
        onDeleteClick={() => setRequestFile(undefined)}
        onCancelClick={() => setOpenImportRequest(false)}
        onExitClick={() => setOpenImportRequest(false)}
        file={requestFile}
      />
      <div className={styles.newRequestDiv}>
        <form
          className={styles.contentDiv}
          onSubmit={onSubmit}
          onKeyDown={disableEnterKey}
        >
          <div className={styles.tittleDiv}>
            <div className={styles.newRequestDiv1}>{title}</div>
          </div>
          <FormTable
            rows={rows}
            setRows={setRows}
            markWrongCells={markWrongCells}
            importedRows={importedRows}
            type={type}
          />
          <div className={styles.contentDiv1}>
            <div className={styles.tittleDiv1}>
              <div className={styles.commentDiv}>Comment</div>
            </div>
            <textarea
              value={comment}
              onChange={(event) => setComment(event.currentTarget.value)}
              className={styles.commentBoxTextarea}
              placeholder="Write here"
            />
            <button
              type="button"
              className={styles.deleteButton}
              onClick={() => setComment("")}
            >
              <DeleteIcon className={styles.deleteIcon} />
              <div className={styles.labelDiv}>Delete comment</div>
            </button>
          </div>
          <div className={styles.navigateDiv}>
            <ButtonRounded
              type="button"
              onClick={backToList}
              variant="Secondary"
            >
              Back to list
            </ButtonRounded>
            <div className={styles.requestButtons}>
              <ButtonRounded
                variant="Primary"
                type="button"
                onClick={() => getXLSXFile(getConfirmation, { type: type })}
              >
                <DownloadIcon className={styles.downloadIcon} />
                <div>Download draft</div>
              </ButtonRounded>{" "}
              <div>
                <ButtonRounded
                  variant="Secondary"
                  type="button"
                  onClick={() =>
                    getXLSXFile(getConfirmation, { type: type, rows: rows })
                  }
                >
                  Export
                </ButtonRounded>
              </div>
              <div>
                <ButtonRounded
                  variant="Secondary"
                  type="button"
                  onClick={() => {
                    setOpenImportRequest(true);
                  }}
                >
                  Import
                </ButtonRounded>
              </div>
              <ButtonRounded variant="Terciary" type="submit">
                Submit
              </ButtonRounded>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};
