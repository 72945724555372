import clone from "clone";
import { MUIDataTableState } from "mui-datatables";
import {
  defaultPageInformation,
  Filter,
  PageInformation,
} from "../models/PageInformation";

export function getPageInfo(tableState: MUIDataTableState): PageInformation {
  const filters: Filter[] = tableState.filterList.map(
    (f: string[], index: number) => ({
      field: tableState.columns[index].label || "",
      id: tableState.columns[index].name,
      values: f,
    })
  );

  return {
    sort: tableState.sortOrder,
    page: tableState.page,
    rowsPerPage: tableState.rowsPerPage,
    search: tableState.searchText,
    filters: filters,
  };
}

export function formatPageInformation(
  pageInformation: PageInformation
): PageInformation {
  const res = clone(pageInformation);
  res.filters.forEach((filter, index) => {
    if (filter.values.length < 1) {
      res.filters.splice(index, 1);
    }
  });
  return res;
}

export function clearPageInformation(
  list: ((PageInformation: PageInformation) => void)[]
) {
  list.forEach((setter) => {
    setter(clone(defaultPageInformation));
  });
}

// This function fixes array error that sometimes occurs on the tableState.
export function fixTableStateError(tableState: MUIDataTableState) {
  tableState.filterList.forEach((filter, index) => {
    if (!(filter instanceof Array)) {
      tableState.filterList[index] = [];
    }
  });
}
