import React, { useCallback, useEffect, useState } from "react";

import { useSnackbar } from "notistack";

import { RequestService } from "../../../../../../api/OracleEBS/request";
import { inferError } from "../../../../../../utils/inferError";
import {
  AddIcon,
  CloseIcon,
  DeleteIcon,
  SearchIcon,
  UserBlueIcon,
} from "../../../../assets";
import styles from "./css/ChangeRecipientsTab.module.css";
import { Tooltip } from "@mui/material";

interface Props {
  requestService: RequestService;
}

export const ChangeRecipientsTab: React.FC<Props> = ({ requestService }) => {
  return (
    <div className={styles.recipients}>
      <RecipientsManager
        requestService={requestService}
        mode="STATUS_CHANGE"
        title="Status Change Recipients"
      />
      <div className="divider" />
      <RecipientsManager
        requestService={requestService}
        mode="COMPLETED"
        title="Completed Status Recipients"
      />
    </div>
  );
};

interface RecipientsManagerProps {
  requestService: RequestService;
  mode: "STATUS_CHANGE" | "COMPLETED";
  title: string;
}
const RecipientsManager: React.FC<RecipientsManagerProps> = ({
  requestService,
  mode,
  title,
}) => {
  const [newRecipient, setNewRecipient] = useState<string>("");
  const [isEmailValid, setIsEmailValid] = useState<boolean>(false);
  const [recipientList, setRecipientList] = useState<string[]>([]);
  const [searchFilter, setSearchFilter] = useState<string>("");
  const { enqueueSnackbar } = useSnackbar();

  const onInputChange = ({
    currentTarget: { value },
  }: React.ChangeEvent<HTMLInputElement>) => {
    setNewRecipient(value);
  };

  useEffect(() => {
    const testEmail = /^[ ]*([^@\s]+)@((?:[-a-z0-9]+\.)+[a-z]{1,})[ ]*$/i;
    setIsEmailValid(testEmail.test(newRecipient));
  }, [newRecipient, setIsEmailValid]);

  const onSearchChange = ({
    currentTarget: { value },
  }: React.ChangeEvent<HTMLInputElement>) => {
    setSearchFilter(value);
  };

  const addRecipient = async () => {
    try {
      const updateFunc =
        mode === "COMPLETED"
          ? requestService.updateCompletedRecipients
          : requestService.updateStatusChangeRecipients;
      await updateFunc([...recipientList, newRecipient]);
      setNewRecipient("");
      fetchRecipientsList(searchFilter);
    } catch (error: any) {
      const err = inferError(error);
      enqueueSnackbar(err.errorMessage, {
        variant: "error",
        preventDuplicate: true,
      });
    }
  };

  const onDeleteClick = async (recipient: string) => {
    try {
      const updateFunc =
        mode === "COMPLETED"
          ? requestService.removeFromCompletedRecipients
          : requestService.removeFromStatusChangeRecipients;
      await updateFunc(recipient);
      fetchRecipientsList(searchFilter);
    } catch (error: any) {
      const err = inferError(error);
      enqueueSnackbar(err.errorMessage, {
        variant: "error",
        preventDuplicate: true,
      });
    }
  };

  const fetchRecipientsList = useCallback(
    async (searchFilter: string) => {
      try {
        const fetchFunc =
          mode === "COMPLETED"
            ? requestService.getCompletedRecipients
            : requestService.getStatusChangeRecipients;
        const list = await fetchFunc(searchFilter);
        setRecipientList(list || []);
      } catch (error: any) {
        const err = inferError(error);
        enqueueSnackbar(err.errorMessage, { variant: "error" });
      }
    },
    [
      mode,
      requestService.getCompletedRecipients,
      requestService.getStatusChangeRecipients,
      enqueueSnackbar,
    ]
  );

  useEffect(() => {
    // This is needed in order to avoid making a request each time you type or select a filter or order.
    // This cause that the first time we enter we have to wait 500 ms too.
    const timer = setTimeout(() => {
      fetchRecipientsList(searchFilter);
    }, 500);
    return () => clearTimeout(timer);
  }, [searchFilter, fetchRecipientsList]);

  return (
    <div className={styles.table}>
      <div className={styles.tableBox}>
        <div className={styles.recipientsTitle}>
          <b className={styles.recipientsTitleText}>{title}</b>
        </div>
        <div className={styles.navigate}>
          <div className={styles.searchBarContainer}>
            <div className={styles.searchBar}>
              <div className={styles.content}>
                <SearchIcon className={styles.searchIcon} />
                <div className={styles.textContainer}>
                  <input
                    className={styles.text2}
                    placeholder="Search reviewer..."
                    onChange={onSearchChange}
                    value={searchFilter}
                  />
                </div>
              </div>
              <button className={styles.x} onClick={() => setSearchFilter("")}>
                <CloseIcon className={styles.cancelIcon} />
              </button>
            </div>
            <div className={styles.line} />
          </div>
        </div>
        <Tooltip
          title={
            recipientList.indexOf(newRecipient) !== -1
              ? "Email already added"
              : ""
          }
          placement="bottom"
          arrow
          disableHoverListener
          disableTouchListener
          disableFocusListener
        >
          <div className={styles.group}>
            <input
              className={styles.emailInput}
              value={newRecipient}
              onChange={onInputChange}
            />
            <button
              className={styles.addButton}
              onClick={addRecipient}
              disabled={
                !isEmailValid || recipientList.indexOf(newRecipient) !== -1
              }
            >
              <div className={styles.addButtonContent}>
                <AddIcon className={styles.addIcon} />
                <b className={styles.add}>Add</b>
              </div>
            </button>
          </div>
        </Tooltip>
        <div className={styles.container}>
          <div className={styles.tableName}>
            <b className={styles.tableNameText}>Reviewer List</b>
          </div>
          <div className={styles.roles}>
            <div className={styles.tableHeader}>
              <div className={styles.categoryEmail}>
                <div className={styles.tableHeaderText}>Email</div>
              </div>
            </div>
            <div className={styles.rolesBox}>
              {recipientList.map((recipient, index) => (
                <div key={index} className={styles.roleDefault}>
                  <div className={styles.identification}>
                    <UserBlueIcon className={styles.userIcon} />
                    <div className={styles.tableText}>{recipient}</div>
                  </div>
                  <button className={styles.deleteButton}>
                    <DeleteIcon
                      className={styles.deleteIcon}
                      onClick={() => onDeleteClick(recipient)}
                    />
                  </button>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
