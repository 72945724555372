import React from "react";
import styles from "./css/Comment.module.css";

interface Props {
  comment: string;
}
export const EntryComment: React.FC<Props> = ({ comment }) => {
  return (
    <div className={styles.rowsDiv}>
      <header className={styles.rowHeader} id="Header">
        <div className={styles.headerCellDiv}>
          <b className={styles.headerTextB}>Comment</b>
        </div>
        <div className={styles.contentCellDiv}>
          <div className={styles.textDiv}>{comment}</div>
        </div>
      </header>
    </div>
  );
};
