import React, { useContext, useEffect, useMemo, useState } from "react";

import {
  Autocomplete,
  createTheme,
  Dialog,
  TextField,
  ThemeProvider,
} from "@mui/material";

import { CloseIcon } from "../../../../assets";
import styles from "./css/AddReviewerDialog.module.css";
import { RequestService } from "../../../../../../api/OracleEBS/request";
import { Config } from "../../../../../../utils/config";
import { User } from "../../../../../../models/app";
import { useSnackbar } from "notistack";
import { OEBSGlobalContext } from "../../../../context/OEBSGlobal";
import { inferError } from "../../../../../../utils/inferError";
import { ROLE_CODES } from "../../../../../../utils/OracleEBS/constants";

const theme = createTheme({
  components: {
    MuiPaper: {
      styleOverrides: {
        root: {
          width: "50% !important",
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        notchedOutline: {
          border: "none",
        },
      },
    },
    MuiAutocomplete: {
      styleOverrides: {
        root: { border: "none" },
        listbox: {
          overflow: "unset",
        },
        paper: {
          backgroundColor: "#eaebf2",
        },
      },
    },
  },
});

interface Props {
  open?: boolean;
  onCloseClick: () => void;
}

export const AddReviewerDialog: React.FC<Props> = ({
  open = false,
  onCloseClick,
}) => {
  const { app } = useContext(OEBSGlobalContext);
  const requestService = useMemo(
    () => new RequestService(`${Config.apiUrl}${app?.appRoutePath}`),
    [app?.appRoutePath]
  );
  const [userToModify, setUserToModify] = useState<User>();
  const [requestersList, setRequestersList] = useState<User[]>([]);
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    (async () => {
      try {
        const res = await requestService.getRequestersList("");
        setRequestersList(res);
      } catch (error: any) {
        const err = inferError(error);
        enqueueSnackbar(err.errorMessage, { variant: "error" });
      }
    })();
  }, [enqueueSnackbar, setRequestersList, requestService]);

  const onChange = (
    event: React.SyntheticEvent<Element, Event>,
    value: User
  ) => {
    setUserToModify(value);
  };

  const onAddClick = async () => {
    await requestService.modifyUserRole(userToModify.id, ROLE_CODES.REVIEWER);
    onCloseClick();
  };

  return (
    <ThemeProvider theme={theme}>
      <Dialog open={open}>
        <div className={styles.popUp}>
          <div className={styles.header}>
            <div className={styles.tittle}>
              <b className={styles.newReviewer}>New reviewer</b>
              <button className={styles.cross} onClick={onCloseClick}>
                <CloseIcon className={styles.xIcon} />
              </button>
            </div>
            <div className={styles.divider} />
          </div>
          <div className={styles.content}>
            <div className={styles.inputContainer}>
              <div className={styles.description}>User email:</div>
              <div className={styles.input}>
                <div className={styles.email}>
                  <Autocomplete
                    autoHighlight
                    value={userToModify || null}
                    onChange={onChange}
                    className={styles.inputText}
                    options={requestersList}
                    getOptionLabel={(option: User) => option.email || ""}
                    isOptionEqualToValue={(opt1, opt2) => opt1.id === opt2.id}
                    renderInput={(params: any) => (
                      <TextField
                        {...params}
                        required
                        color="secondary"
                        label=""
                        variant="outlined"
                        placeholder="Requester email"
                        helperText=""
                      />
                    )}
                    size="medium"
                  />
                </div>
              </div>
            </div>
            <div className={styles.buttons}>
              <button className={styles.buttonCancel} onClick={onCloseClick}>
                <b className={styles.buttonCancelText}>Cancel</b>
              </button>
              <button className={styles.buttonAdd} onClick={onAddClick}>
                <b className={styles.buttonAddText}>Add reviewer</b>
              </button>
            </div>
          </div>
        </div>
      </Dialog>
    </ThemeProvider>
  );
};
