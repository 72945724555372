import React, { useRef } from "react";

import {
  RequestRow,
  RequestType,
} from "../../../../../models/OracleEBS/request";
import styles from "../css/RequestTable.module.css";
import { Footer } from "./Footer";
import { Header } from "./Header";
import { RowDisplay } from "./RowDisplay";

interface Props {
  type: RequestType;
  rows: RequestRow[];
}

export const RequestDisplayTable: React.FC<Props> = ({ type, rows }) => {
  const indexScrollBarRef = useRef<HTMLDivElement>(null);
  const rowsScrollBarRef = useRef<HTMLDivElement>(null);

  return (
    <div className={styles.newRequestTable}>
      <div className={styles.indexDivider}>
        <div className={styles.indexTableHeadDiv}>
          <div className={styles.indexContainerDiv}>
            <header className={styles.indexHeader} id="Header">
              <div className={styles.headerCellFixedDiv1}>
                <div className={styles.indexTextDiv}>Nº</div>
              </div>
            </header>
            <div
              className={styles.indexRowsBoxDiv}
              ref={indexScrollBarRef}
              onScroll={(event: React.UIEvent<HTMLElement>) => {
                rowsScrollBarRef.current.scrollTop =
                  event.currentTarget.scrollTop;
              }}
            >
              <div className={styles.indexRowsDiv}>
                {rows.map((row) => (
                  <div
                    className={`${styles.indexRowDiv} ${
                      row.isDeleted && styles.rowDivDeleted
                    }`}
                    key={row.rowNumber}
                  >
                    <div className={styles.indexCellFixedDiv}>
                      <p className={styles.textP}>{row.rowNumber}</p>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
        <div className={styles.tableHeadDiv}>
          <div className={styles.containerDiv}>
            <Header type={type} />
            <div
              className={styles.rowsBoxDiv}
              ref={rowsScrollBarRef}
              onScroll={(event: React.UIEvent<HTMLElement>) => {
                indexScrollBarRef.current.scrollTop =
                  event.currentTarget.scrollTop;
              }}
            >
              <div className={styles.rowsDiv}>
                {rows.map((row) => (
                  <RowDisplay type={type} row={row} key={row.rowNumber} />
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className={styles.footerContainerDiv}>
        <Footer />
      </div>
    </div>
  );
};
