import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";

import { TextField } from "@mui/material";
import { ThemeProvider, createTheme } from "@mui/material/styles";

import styles from "../css/Row.module.css";
import { RequestRow, RowField } from "../../../../../models/OracleEBS/request";
import {
  ROW_FIELD_NAMES,
  ROW_FIELD_PARAM,
} from "../../../../../utils/OracleEBS/constants";
import { RequestService } from "../../../../../api/OracleEBS/request";
import { Config } from "../../../../../utils/config";
import { inferError } from "../../../../../utils/inferError";
import { useSnackbar } from "notistack";
import { StringAutocomplete } from "../common/StringAutocomplete";
import { OEBSGlobalContext } from "../../../context/OEBSGlobal";
import { RowFieldAutocomplete } from "../common/RowFieldAutocomplete";

const theme = createTheme({
  components: {
    MuiOutlinedInput: {
      styleOverrides: {
        notchedOutline: {
          border: "none",
        },
      },
    },
    MuiAutocomplete: {
      styleOverrides: {
        root: { border: "none" },
        listbox: {
          overflow: "unset",
        },
        paper: {
          backgroundColor: "#eaebf2",
          maxHeight: "200px",
        },
      },
    },
  },
});

interface Props {
  row: RequestRow;
  handleChange?: (
    rowNumber: number,
    field: string,
    value: string | number | RowField | null
  ) => void;
  deleteSelection?: (rowNumber: number, fields: string[]) => void;
  legalEntityOptions?: RowField[];
  mappingTeamOptions?: string[];
  mappingOfficeOptions?: string[];
  markWrongCells: boolean;
}

export const TeamCreationRow: React.FC<Props> = ({
  row,
  handleChange,
  deleteSelection,
  legalEntityOptions,
  mappingTeamOptions,
  mappingOfficeOptions,
  markWrongCells,
}) => {
  const { app } = useContext(OEBSGlobalContext);
  const { enqueueSnackbar } = useSnackbar();
  const [businessUnitOptions, setBusinessUnitOptions] = useState<RowField[]>(
    []
  );
  const [functionOptions, setFunctionOptions] = useState<RowField[]>([]);
  const [departmentOptions, setDepartmentOptions] = useState<RowField[]>([]);
  const requestService = useMemo(
    () => new RequestService(`${Config.apiUrl}${app?.appRoutePath}`),
    [app?.appRoutePath]
  );
  const [markCell, setMarkCell] = useState<{
    legalEntity: boolean;
    businessUnit: boolean;
    function: boolean;
    department: boolean;
    team: boolean;
    mappingTeam: boolean;
    mappingOffice: boolean;
  }>({
    legalEntity: markWrongCells && !row.legalEntity,
    businessUnit: markWrongCells && !row.businessUnit && !!row.legalEntity,
    function: markWrongCells && !row.function && !!row.businessUnit,
    department: markWrongCells && !row.department && !!row.function,
    team: markWrongCells && !row.team && !!row.department,
    mappingTeam: markWrongCells && !row.mappingTeam,
    mappingOffice: markWrongCells && !row.mappingOffice,
  });

  const getFieldOptions = useCallback(
    async (field: string, parent: number) => {
      try {
        const options = await requestService.getFieldOptionsByParent(
          field,
          parent
        );
        return options;
      } catch (error: any) {
        const err = inferError(error);
        enqueueSnackbar(err.errorMessage, { variant: "error" });
      }
    },
    [enqueueSnackbar, requestService]
  );

  useEffect(() => {
    (async () => {
      setMarkCell({
        legalEntity: markWrongCells && !row.legalEntity,
        businessUnit: markWrongCells && !row.businessUnit && !!row.legalEntity,
        function: markWrongCells && !row.function && !!row.businessUnit,
        department: markWrongCells && !row.department && !!row.function,
        team: markWrongCells && !row.team && !!row.department,
        mappingTeam: markWrongCells && !row.mappingTeam,
        mappingOffice: markWrongCells && !row.mappingOffice,
      });
      if (row.legalEntity) {
        const businessUnitOps = await getFieldOptions(
          ROW_FIELD_PARAM.BUSINESS_UNIT,
          row.legalEntity.code
        );
        setBusinessUnitOptions(businessUnitOps);
      }
      if (row.businessUnit) {
        const functionOps = await getFieldOptions(
          ROW_FIELD_PARAM.FUNCTION,
          row.businessUnit.code
        );
        setFunctionOptions(functionOps);
      }
      if (row.function) {
        const departmentOps = await getFieldOptions(
          ROW_FIELD_PARAM.DEPARTMENT,
          row.function.code
        );
        setDepartmentOptions(departmentOps);
      }
    })();
  }, [
    getFieldOptions,
    markWrongCells,
    row.businessUnit,
    row.department,
    row.function,
    row.legalEntity,
    row.mappingOffice,
    row.mappingTeam,
    row.team,
  ]);

  const onChange =
    (field: string) =>
    async (
      event: React.ChangeEvent<HTMLInputElement>,
      value: string | null
    ) => {
      if (field === ROW_FIELD_NAMES.TEAM)
        handleChange(row.rowNumber, field, {
          code: null,
          text: event.target.value,
        } as RowField);
      else handleChange(row.rowNumber, field, value || event.target.value);
      let fieldsToClear: string[] = [];
      switch (field) {
        case ROW_FIELD_NAMES.LEGAL_ENTITY:
          fieldsToClear = [
            ROW_FIELD_NAMES.BUSINESS_UNIT,
            ROW_FIELD_NAMES.FUNCTION,
            ROW_FIELD_NAMES.DEPARTMENT,
            ROW_FIELD_NAMES.TEAM,
          ];
          break;
        case ROW_FIELD_NAMES.BUSINESS_UNIT:
          fieldsToClear = [
            ROW_FIELD_NAMES.FUNCTION,
            ROW_FIELD_NAMES.DEPARTMENT,
            ROW_FIELD_NAMES.TEAM,
          ];
          break;
        case ROW_FIELD_NAMES.FUNCTION:
          fieldsToClear = [ROW_FIELD_NAMES.DEPARTMENT, ROW_FIELD_NAMES.TEAM];
          break;
        case ROW_FIELD_NAMES.DEPARTMENT:
          fieldsToClear = [ROW_FIELD_NAMES.TEAM];
          break;
      }
      if (fieldsToClear.length > 0)
        deleteSelection(row.rowNumber, fieldsToClear);
    };

  return (
    <ThemeProvider theme={theme}>
      <div className={styles.rowDiv}>
        <RowFieldAutocomplete
          value={row.legalEntity}
          onChange={onChange(ROW_FIELD_NAMES.LEGAL_ENTITY)}
          options={legalEntityOptions}
          placeholder="Eclipse Legal Entity"
          invalidCell={markCell.legalEntity}
        />
        <RowFieldAutocomplete
          value={row.businessUnit}
          onChange={onChange(ROW_FIELD_NAMES.BUSINESS_UNIT)}
          options={businessUnitOptions}
          disabled={!row.legalEntity}
          placeholder="Eclipse Business Unit"
          invalidCell={markCell.businessUnit}
        />
        <RowFieldAutocomplete
          value={row.function}
          onChange={onChange(ROW_FIELD_NAMES.FUNCTION)}
          options={functionOptions}
          disabled={!row.businessUnit}
          placeholder="Eclipse Function"
          invalidCell={markCell.function}
        />
        <RowFieldAutocomplete
          value={row.department}
          onChange={onChange(ROW_FIELD_NAMES.DEPARTMENT)}
          options={departmentOptions}
          disabled={!row.function}
          placeholder="Eclipse Department"
          invalidCell={markCell.department}
        />
        <div className={styles.cell}>
          <div className={`${markCell.team && styles.invalidCell}`}>
            <TextField
              className={styles.bodyCellAutocomplete}
              required
              disabled={!row.department}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                onChange(ROW_FIELD_NAMES.TEAM)(event, null)
              }
              value={row.team?.text || ""}
              color="secondary"
              label=""
              variant="outlined"
              placeholder="Eclipse Team"
              helperText=""
            />
          </div>
        </div>
        <StringAutocomplete
          value={row.mappingTeam}
          options={mappingTeamOptions}
          onChange={onChange(ROW_FIELD_NAMES.MAPPING_TEAM)}
          placeholder="Oracle/EBS Mapping Team"
          invalidCell={markCell.mappingTeam}
        />
        <StringAutocomplete
          value={row.mappingOffice}
          options={mappingOfficeOptions}
          onChange={onChange(ROW_FIELD_NAMES.MAPPING_OFFICE)}
          placeholder="Oracle/EBS Mapping Office"
          invalidCell={markCell.mappingOffice}
        />
      </div>
    </ThemeProvider>
  );
};
