import { Grid, IconButton, makeStyles } from "@material-ui/core";
import {
  FilterType,
  MUIDataTableColumn,
  MUIDataTableColumnOptions,
  MUIDataTableMeta,
} from "mui-datatables";
import React from "react";
import { parseUIDate, toLastSecond, uiDate } from "../../../../../utils/time";
import { DatePicker } from "../DatePicker";

export interface Props {
  /** Label for the input. */
  label: string;
  /** Start date. */
  from?: number | null;
  /** End date. */
  to?: number | null;
  /** Function to be called after a change is made. */
  onChange: (from?: number | null, to?: number | null) => void;

  /** The word to be displayed on the label for the 'from' date. If not provided it will be 'FROM'. */
  fromWord?: string;
  /** The word to be displayed on the label for the 'to' date. If not provided it will be 'TO'. */
  toWord?: string;

  /** Icon for the null button. If not provided, there will be no null button. Null button calls 'onChange' will 'null' value on both dates. */
  nullIcon?: JSX.Element;
  /** Icon for the clear button. If not provided, there will be no clear button. Clear button calls 'onChange' will 'undefined' value on both dates. */
  clearIcon?: JSX.Element;

  /** Whether or not the component is fullWidth. */
  fullWidth?: boolean;
  /** Style class for the date inputs. */
  className?: string;
}

const useStyles = makeStyles({
  dateInput: {
    display: "flex",
  },
  grid: {
    flexDirection: "row",
    display: "flex",
  },
  iconButton: {
    display: "flex",
    marginTop: 20,
  },
  icon: {
    display: "flex",
  },
});

export const DateRangeFilter: React.FC<Props> = ({
  label,
  onChange,
  from,
  to,
  fromWord,
  toWord,
  clearIcon,
  nullIcon,
  fullWidth,
  className,
}) => {
  const classes = useStyles();

  return (
    <Grid data-qa="date-range-picker" className={classes.grid}>
      <DatePicker
        data-qa="picker-1"
        label={`${label} - ${fromWord || "FROM"}`}
        fullWidth={fullWidth}
        onChange={(value) => {
          onChange(value, to);
        }}
        value={from}
        className={className || classes.dateInput}
      />
      <DatePicker
        data-qa="picker-2"
        label={`${label} - ${toWord || "TO"}`}
        fullWidth
        onChange={(value) => {
          onChange(from, value);
        }}
        value={to}
        className={className || classes.dateInput}
      />
      {nullIcon && (
        <IconButton
          data-qa="null-button"
          color="secondary"
          className={classes.iconButton}
          onClick={() => {
            if (onChange) {
              onChange(null, null);
            }
          }}
        >
          {nullIcon}
        </IconButton>
      )}
      {clearIcon && (
        <IconButton
          data-qa="clear-button"
          color="secondary"
          className={classes.iconButton}
          disabled={from === undefined && to === undefined}
          onClick={() => {
            if (onChange) {
              onChange(undefined, undefined);
            }
          }}
        >
          {clearIcon}
        </IconButton>
      )}
    </Grid>
  );
};

const applyLogic = (
  date: string,
  filters: (number | undefined | null)[]
): boolean => {
  if (filters.length <= 1) {
    return false;
  }

  const from: number | undefined | null = filters[0];
  const to: number | undefined | null = filters[1];
  const dateToCompare: number = parseUIDate(date, "DD-MM-YYYY");

  // If null filter is applied
  if (from === null || to === null) {
    return date !== "";
  }

  // If no filters or values are applied
  if (from === undefined && to === undefined) {
    return false;
  }

  // If from is not provided
  if (from === undefined && to) {
    return dateToCompare > to;
  }
  if (to === undefined && from) {
    return from > dateToCompare;
  }

  if (from && to) {
    return date === "" || from > dateToCompare || dateToCompare > to;
  }
  return false;
};

const customFilter: FilterType = "custom";

export const getDateRangeFilterOptions = (
  label: string,
  clearIcon?: JSX.Element,
  nullIcon?: JSX.Element,
  sinceWord?: string,
  untilWord?: string,
  filterList?: string[],
  customBodyRender?: (
    value: any,
    tableMeta: MUIDataTableMeta,
    updateValue: (value: string) => void
  ) => React.ReactNode
): MUIDataTableColumnOptions => {
  const render = (value: number[]) => {
    if (!value[0] || !value[1]) {
      return "";
    }

    if (isNaN(value[0]) && isNaN(value[1])) {
      return "No " + label;
    }

    const since = isNaN(value[0])
      ? ""
      : `${sinceWord || "since"} ${uiDate(value[0])}`;
    const until = isNaN(value[1])
      ? ""
      : `${untilWord || "until"} ${uiDate(value[1])}`;

    return `${label} ${since} ${until}`;
  };

  return {
    customBodyRender: customBodyRender,
    filterType: customFilter,
    filterList: filterList,
    filterOptions: {
      names: [],
      logic: applyLogic,
      display: (
        filterList: string[][],
        onChange: (
          value: string[],
          index: number,
          column: MUIDataTableColumn
        ) => void,
        index: number,
        column: MUIDataTableColumn
      ) => {
        const onChangeFn = (
          from?: number | undefined | null,
          to?: number | undefined | null
        ) => {
          const fromValue =
            from !== null && from !== undefined && Number.isNaN(from)
              ? undefined
              : from;
          const toValue =
            to !== null && to !== undefined && Number.isNaN(to)
              ? undefined
              : to;
          if (fromValue === undefined && toValue === undefined) {
            filterList[index] = [];
          } else {
            filterList[index][0] = `${fromValue}`;
            // This field is added 23h 59min so the filter works since `from` until `to`.
            filterList[index][1] = `${
              toValue ? toLastSecond(toValue) : toValue
            }`;
          }
          onChange(filterList[index], index, column);
        };

        return (
          <DateRangeFilter
            label={label}
            from={Number(filterList[index][0])}
            to={Number(filterList[index][1])}
            onChange={onChangeFn}
            clearIcon={clearIcon}
            nullIcon={nullIcon}
          />
        );
      },
    },
    customFilterListOptions: {
      render: render,
    },
  };
};
