import React from "react";

import { RequestListHeaders } from "../../../../../utils/OracleEBS/constants";
import { CloseIcon } from "../../../assets";
import styles from "./css/FilterChip.module.css";

interface Props {
  field: string;
  value: string;
  onClearClick: () => void;
}

export const FilterChip: React.FC<Props> = ({ field, value, onClearClick }) => {
  return (
    <div className={styles.chipDiv}>
      <div className={styles.contentDiv}>
        <div className={styles.chiptittleDiv}>{RequestListHeaders[field]}:</div>
        <div className={styles.chiptittleDiv}>{value}</div>
      </div>
      <button className={styles.iconclosechipButton} onClick={onClearClick}>
        <CloseIcon className={styles.vectorIcon} />
      </button>
    </div>
  );
};
