import React from "react";

import { useNavigate } from "react-router-dom";

import { AppEntity } from "../../models/app";
import Grid from "@mui/material/Grid";
import { AppButton } from "./components/AppButton/AppButton";
import { Header } from "./components/Header/Header";

interface Props {
  appList: AppEntity[];
}

export const Dashboard: React.FC<Props> = ({ appList }) => {
  const navigate = useNavigate();

  const onClick = async (app: AppEntity) => {
    navigate(
      app.appRoutePath.startsWith("/")
        ? app.appRoutePath
        : `/${app.appRoutePath}`
    );
  };

  return (
    <>
      <Header />
      <div style={{ backgroundColor: "black" }}></div>
      <Grid container spacing={2} p={"70px"}>
        {appList.map((app) => {
          return (
            <Grid item xs={12} md={6} lg={3} key={app.appID}>
              <AppButton app={app} onClick={() => onClick(app)}></AppButton>
            </Grid>
          );
        })}
      </Grid>
    </>
  );
};
