import React from "react";

import styles from "./css/ButtonRounded.module.css";

export type ButtonVariants =
  | "Primary"
  | "Secondary"
  | "Terciary"
  | "Green"
  | "Red"
  | "Blue"
  | "Transparent";
interface Props {
  type?: "button" | "submit" | "reset";
  children?: React.ReactNode;
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
  variant?: ButtonVariants;
}

export const ButtonRounded: React.FC<Props> = ({
  type = "button",
  children,
  onClick,
  variant = "Primary",
}) => {
  let buttonClassName = styles.buttonPrimary;
  let textClassName = styles.textPrimary;
  switch (variant) {
    case "Secondary":
      buttonClassName = styles.buttonSecondary;
      textClassName = styles.textSecondary;
      break;
    case "Terciary":
      buttonClassName = styles.buttonTerciary;
      textClassName = styles.textTerciary;
      break;
    case "Green":
      buttonClassName = styles.buttonGreen;
      textClassName = styles.textGreen;
      break;
    case "Red":
      buttonClassName = styles.buttonRed;
      textClassName = styles.textRed;
      break;
    case "Blue":
      buttonClassName = styles.buttonBlue;
      textClassName = styles.textBlue;
      break;
    case "Transparent":
      buttonClassName = styles.buttonTransparent;
      textClassName = styles.textTransparent;
  }

  return (
    <button type={type} className={buttonClassName} onClick={onClick}>
      <div className={textClassName}>{children}</div>
    </button>
  );
};
