import React, { useEffect, useState } from "react";
import { NumberInput, Props as NumberInputProps } from "../NumberInput";
import { DEFAULT_DELAY } from "../StringInputDelay";

interface Props {
  /** The amount of milliseconds that are garanteed to be waited before calling 'onChange'. */
  delay?: number;
  /** Input onChange function. */
  onChange: (value?: number) => void;
}

type NumberInputPropsWithoutOnChange = Omit<NumberInputProps, "onChange">;
export type NumberInputDelayProps = Props & NumberInputPropsWithoutOnChange;

/** A simple number picker with delay before calling 'onChange' */
export const NumberInputDelay: React.FC<NumberInputDelayProps> = (props) => {
  const { delay, onChange, value } = props;
  const [actualValue, setActualValue] = useState<number>(value);

  useEffect(() => {
    const timer = setTimeout(
      () => {
        onChange(actualValue);
      },
      delay || DEFAULT_DELAY
    );
    return () => clearTimeout(timer);
  }, [actualValue, delay, onChange]);

  const inputProps: NumberInputProps = {
    ...props,
    value: actualValue,
    onChange: setActualValue,
  };
  return <NumberInput data-qa="number-input-delay" {...inputProps} />;
};
