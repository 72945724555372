import { RequestEntity, RequestRow } from "./request";

export enum Action {
  Insert = "I",
  Update = "U",
  Delete = "D",
  Truncate = "T",
}

export interface AuditLog {
  eventID: number;
  schemaName: string;
  tableName: string;
  requestID: number;
  recordID: number;
  actionTimestamp: number;
  transactionID: number;
  action: Action;
}
export interface RequestAuditLog extends AuditLog {
  rowData: RequestEntity;
  previousFields?: RequestEntity;
  changedFields?: RequestEntity;
}
export interface RowAuditLog extends AuditLog {
  rowData: RequestRow;
  previousFields?: RequestRow;
  changedFields?: RequestRow;
}

export interface RequestAuditEntry {
  request: RequestAuditLog;
  rows: RowAuditLog[];
  comment?: string;
}
