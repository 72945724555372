import React, { useContext, useEffect, useState } from "react";

import { AccountService } from "../../api/account";
import { AppService } from "../../api/app";
import { GlobalContext, LoginStatus } from "../../context/Global";
import { AppAndRole } from "../../models/app";
import { Config } from "../../utils/config";
import { getCurrentUserEmail } from "../../utils/session";
import { Login } from "../Account/Login";
import { Router } from "./Router";

const accountService = new AccountService(Config.apiUrl);
const appService = new AppService(Config.apiUrl);

export const Session: React.FC = () => {
  const { accountInfo, setAccountInfo } = useContext(GlobalContext);
  const [appsAndRoles, setAppsAndRoles] = useState<AppAndRole[]>([]);
  const accountInfoDep = JSON.stringify(accountInfo);

  useEffect(() => {
    (async () => {
      const accountInfo = JSON.parse(accountInfoDep);
      try {
        const session = await accountService.checkSession();
        const email = getCurrentUserEmail(session);
        const appsAndRoles = await appService.getAppsAndRoles();
        setAppsAndRoles(appsAndRoles);
        setAccountInfo({ email: email, status: "valid" });
      } catch (error) {
        setAccountInfo({ ...accountInfo, status: "invalid" });
      }
    })();
  }, [setAccountInfo, accountInfoDep]);

  if (!accountInfo.status) {
    return <span></span>;
  }
  switch (accountInfo.status) {
    case "valid":
      return <Router appsAndRoles={appsAndRoles} />;
    case "invalid":
    default:
      return (
        <Login
          onLogIn={(res: LoginStatus) => {
            setAccountInfo(res);
          }}
        />
      );
  }
};
