import React from "react";
import {
  ExportOptions,
  exportToXMLS,
} from "../../../../../utils/OracleEBS/XLSX";
import { DownloadIcon } from "../../../assets";
import { DialogOptions } from "../../../context/ConfirmationDialog";

export const getXLSXFile = async (
  getConfirmation: ({ ...options }: DialogOptions) => Promise<boolean>,
  options: ExportOptions
) => {
  let title = "Download draft";
  let message = "A .XLSX will be generated as template";
  let buttonLabel = "Download draft";
  if (options.rows) {
    title = "Export request";
    message = "Current request will be exported to .XLSX";
    buttonLabel = "Export request";
  }
  const confirmed = await getConfirmation({
    title: title,
    message: message,
    confirmButton: {
      variant: "Primary",
      content: (
        <>
          <DownloadIcon />
          <div>{buttonLabel}</div>
        </>
      ),
    },
  });
  if (confirmed) exportToXMLS(options);
};
