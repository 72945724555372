import React from "react";

import {
  RequestRow,
  RequestType,
} from "../../../../../models/OracleEBS/request";
import { ROW_FIELD_HEADER_TITLE } from "../../../../../utils/OracleEBS/constants";
import { formatLogDate } from "../../../../../utils/time";
import styles from "./css/Row.module.css";

interface Props {
  type: RequestType;
  row: RequestRow;
}

export const NewRow: React.FC<Props> = ({ type, row }) => {
  return (
    <div className={styles.rowsDiv1}>
      <div className={styles.tableHeaderDiv}>
        <div className={styles.headerCellDiv}>
          <b className={styles.headerTextB}>Row {row.rowNumber}</b>
        </div>
        <div className={styles.headerCellDiv1}>
          <b className={styles.headerTextB}>Value</b>
        </div>
      </div>
      <RowContent
        title={ROW_FIELD_HEADER_TITLE.LEGAL_ENTITY}
        content={row.legalEntity.text}
      />
      <RowContent
        title={ROW_FIELD_HEADER_TITLE.BUSINESS_UNIT}
        content={row.businessUnit.text}
      />
      <RowContent
        title={ROW_FIELD_HEADER_TITLE.FUNCTION}
        content={row.function.text}
      />
      <RowContent
        title={ROW_FIELD_HEADER_TITLE.DEPARTMENT}
        content={row.department.text}
      />
      <RowContent title={ROW_FIELD_HEADER_TITLE.TEAM} content={row.team.text} />
      {type === RequestType.Inactivation ? (
        <RowContent
          title={ROW_FIELD_HEADER_TITLE.INACTIVE_DATE}
          content={row.inactiveDate ? formatLogDate(row.inactiveDate) : "-"}
        />
      ) : (
        <>
          <RowContent
            title={ROW_FIELD_HEADER_TITLE.MAPPING_TEAM}
            content={row.mappingTeam}
          />
          <RowContent
            title={ROW_FIELD_HEADER_TITLE.MAPPING_OFFICE}
            content={row.mappingOffice}
          />
        </>
      )}
    </div>
  );
};

interface ContentProps {
  title: string;
  content: string;
}

const RowContent: React.FC<ContentProps> = ({ title, content }) => {
  return (
    <header className={styles.rowHeader} id="Header">
      <div className={styles.contentHeaderCellDiv}>
        <b className={styles.headerTextB}>{title}</b>
      </div>
      <div className={styles.contentCellDiv}>
        <div className={styles.textDiv}>{content}</div>
      </div>
    </header>
  );
};
