import React, { useContext, useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import { useSnackbar } from "notistack";

import { getStoredSession } from "../../../../api/account";
import { RequestService } from "../../../../api/OracleEBS/request";
import {
  RequestEntity,
  RequestStatus,
  RequestType,
} from "../../../../models/OracleEBS/request";
import { Config } from "../../../../utils/config";
import { inferError } from "../../../../utils/inferError";
import { getCurrentUserID } from "../../../../utils/session";
import { uiDateTime } from "../../../../utils/time";
import { AddIcon, AuditIcon, CancelIcon, EditIcon } from "../../assets";
import styles from "./css/Request.module.css";
import { ROLE_CODES } from "../../../../utils/OracleEBS/constants";
import { EditRequest } from "./EditRequest";
import { DisplayRequest } from "./DisplayRequest";
import Audit from "./components/Audit";
import { Drawer } from "@mui/material";
import { RequestAuditEntry } from "../../../../models/OracleEBS/audit";
import { OEBSGlobalContext } from "../../context/OEBSGlobal";
import { LoadingProgressContext } from "../../context/LoadingProgress";

export const Request: React.FC = () => {
  const { id } = useParams();
  const [request, setRequest] = useState<RequestEntity>();
  const [editRequest, setEditRequest] = useState<boolean>(false);
  const { app, role } = useContext(OEBSGlobalContext);
  const { enqueueSnackbar } = useSnackbar();
  const requestService = useMemo(
    () => new RequestService(`${Config.apiUrl}${app?.appRoutePath}`),
    [app?.appRoutePath]
  );
  const [showAudit, setShowAudit] = useState<boolean>(false);
  const [auditEntries, setAuditEntries] = useState<RequestAuditEntry[]>([]);
  const { startLoading, stopLoading } = useContext(LoadingProgressContext);

  useEffect(() => {
    (async () => {
      try {
        startLoading();
        const request = await requestService.getRequest(Number(id));
        stopLoading();
        setRequest(request);
        const session = getStoredSession();
        const userID = getCurrentUserID(session);
        if (
          request.status === RequestStatus.Rejected &&
          request.requesterID === userID
        ) {
          setEditRequest(true);
        }
      } catch (error: any) {
        const err = inferError(error);
        enqueueSnackbar(err.errorMessage, {
          variant: "error",
          preventDuplicate: true,
        });
      }
    })();
  }, [
    app.appRoutePath,
    enqueueSnackbar,
    setRequest,
    id,
    requestService,
    startLoading,
    stopLoading,
  ]);

  const audit = async () => {
    try {
      startLoading();
      const entries = await requestService.getRequestAuditLogs(Number(id));
      stopLoading();
      setAuditEntries(entries);
      setShowAudit(true);
    } catch (error: any) {
      const err = inferError(error);
      enqueueSnackbar(err.errorMessage, { variant: "error" });
      setShowAudit(false);
      return;
    }
  };

  let title = <></>;
  switch (request?.type) {
    case RequestType.Creation:
      title = (
        <div className={styles.newRequestDiv1}>
          <div className={styles.createTeamIconDiv}>
            <AddIcon />
          </div>
          <div className={styles.createTeamTitle}>Create team</div>
        </div>
      );
      break;
    case RequestType.Edition:
      title = (
        <div className={styles.newRequestDiv1}>
          <div className={styles.editTeamIconDiv}>
            <EditIcon />
          </div>
          <div className={styles.editTeamTitle}>Edit team mapping</div>
        </div>
      );
      break;
    case RequestType.Inactivation:
      title = (
        <div className={styles.newRequestDiv1}>
          <div className={styles.inactivateTeamIconDiv}>
            <CancelIcon />
          </div>
          <div className={styles.inactivateTeamTitle}>Inactivate team</div>
        </div>
      );
      break;
  }

  return (
    <>
      <Drawer
        open={showAudit}
        anchor="right"
        onClose={() => setShowAudit(false)}
      >
        <Audit onClose={() => setShowAudit(false)} entries={auditEntries} />
      </Drawer>

      {request && (
        <div className={styles.containerDiv}>
          <div className={styles.newRequestDiv}>
            <div className={styles.contentDiv}>
              <div className={styles.upbarDiv}>
                <div className={styles.requestInformationDiv}>
                  {title}
                  <div className={styles.requestDiv}>
                    Request {request.requestID}
                  </div>
                  <div
                    className={
                      request.status === RequestStatus.Rejected
                        ? styles.statusRejected
                        : request.status === RequestStatus.Resolved
                        ? styles.statusResolved
                        : request.status === RequestStatus.Deleted
                        ? styles.statusDeleted
                        : request.status === RequestStatus.Submitted
                        ? styles.statusSubmitted
                        : styles.statusCompleted
                    }
                  >
                    {request.status}
                  </div>
                  {request.status === RequestStatus.Completed &&
                    request.snTicketID && (
                      <div className={styles.reviewedByDiv}>
                        <b className={styles.emailB}>
                          Ticket ID: {request.snTicketID}
                        </b>
                      </div>
                    )}
                  <div className={styles.reviewedByDiv}>
                    {(role.roleCode === ROLE_CODES.REVIEWER ||
                      role.roleCode === ROLE_CODES.ADMIN) && (
                      <b className={styles.emailB}>
                        Requested by: {request.requesterEmail}
                      </b>
                    )}
                    <b className={styles.emailB}>
                      {request.reviewerID
                        ? `Reviewed by: ${request.reviewerEmail}`
                        : `Waiting for a reviewer`}
                    </b>
                  </div>
                  <b className={styles.dateB}>
                    {request.lastUpdate
                      ? uiDateTime(request.lastUpdate)
                      : uiDateTime(request.date)}
                  </b>
                </div>

                <button className={styles.auditButtonSecondary} onClick={audit}>
                  <AuditIcon className={styles.iconHistory} />
                  <b className={styles.auditB}>Audit</b>
                </button>
              </div>
              {editRequest ? (
                <EditRequest request={request} />
              ) : (
                <DisplayRequest request={request} />
              )}
            </div>
          </div>
        </div>
      )}
    </>
  );
};
