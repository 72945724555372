import React, { useContext, useMemo, useState } from "react";

import { saveAs } from "file-saver";

import { RequestService } from "../../../../../api/OracleEBS/request";
import { Config } from "../../../../../utils/config";
import { GuideIcon, AdminIcon, QuestionIcon } from "../../../assets";
import { OEBSGlobalContext } from "../../../context/OEBSGlobal";
import styles from "./css/HelpButtons.module.css";
import {
  ROLE_CODES,
  SUPPORT_EMAIL,
} from "../../../../../utils/OracleEBS/constants";
import { AdminDialog } from "../../Dialog/AdminDialog/AdminDialog";

export const HelpButtons: React.FC = () => {
  const { app, role } = useContext(OEBSGlobalContext);
  const requestService = useMemo(
    () => new RequestService(`${Config.apiUrl}${app?.appRoutePath}`),
    [app?.appRoutePath]
  );
  const [openAdmin, setOpenAdmin] = useState(false);

  const getManual = async () => {
    const manual = await requestService.getUserManual();
    saveAs(manual, "EBSUserManual.pdf");
  };

  const sendEmail = () => {
    window.location.replace(
      `mailto:${SUPPORT_EMAIL.EMAIL}?subject=${SUPPORT_EMAIL.SUBJECT}`
    );
  };

  return (
    <div className={styles.containerDiv}>
      <button className={styles.guideButton} onClick={getManual}>
        <GuideIcon />
      </button>
      <button className={styles.questionButton} onClick={sendEmail}>
        <QuestionIcon />
      </button>
      {role.roleCode === ROLE_CODES.ADMIN && (
        <>
          <button
            className={styles.addButtonParent}
            onClick={() => setOpenAdmin(true)}
          >
            <div className={styles.addButton}>
              <div className={styles.personIconDiv}>
                <AdminIcon />
              </div>
            </div>
          </button>
          {openAdmin && (
            <AdminDialog
              open={openAdmin}
              onCloseClick={() => setOpenAdmin(false)}
            />
          )}
        </>
      )}
    </div>
  );
};
