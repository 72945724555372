import React, { useState } from "react";
import { SnackbarProvider } from "notistack";

import { Session } from "./components/Layouts/Session";
import { GlobalContext, LoginStatus } from "./context/Global";
import { SnackbarCloseButton } from "./components/common/SnackbarCloseButton";
import snackbarStyles from "./components/common/css/SnackbarCloseButton.module.css";

const App: React.FC = () => {
  const [accountInfo, setAccountInfo] = useState<LoginStatus>({});

  return (
    <GlobalContext.Provider
      value={{
        accountInfo,
        setAccountInfo,
      }}
    >
      <SnackbarProvider
        maxSnack={3}
        action={(snackbarId) => (
          <SnackbarCloseButton snackbarId={snackbarId}></SnackbarCloseButton>
        )}
        classes={{ variantError: snackbarStyles.variantError }}
      >
        <Session />
      </SnackbarProvider>
    </GlobalContext.Provider>
  );
};

export default App;
