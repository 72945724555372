import { utils, writeFile, read } from "xlsx";
import { RequestRow, RequestType } from "../../models/OracleEBS/request";
import { uiDate, uiDateTime } from "../time";
import { ROW_FIELD_HEADER_TITLE } from "./constants";

export interface XMLSRequestRows {
  requestID: number;
  type: RequestType;
  rows: XLSXRequestRow[];
}

interface XLSXRequestRow {
  legalEntity: string;
  businessUnit: string;
  function: string;
  department: string;
  team: string;
  inactiveDate: string;
  mappingTeam: string;
  mappingOffice: string;
  status?: "Fulfilled" | "Canceled";
}

const getHeaders = (type: RequestType, showState: boolean): string[] => {
  const headers = [
    ROW_FIELD_HEADER_TITLE.LEGAL_ENTITY,
    ROW_FIELD_HEADER_TITLE.BUSINESS_UNIT,
    ROW_FIELD_HEADER_TITLE.FUNCTION,
    ROW_FIELD_HEADER_TITLE.DEPARTMENT,
    ROW_FIELD_HEADER_TITLE.TEAM,
  ];
  if (type === RequestType.Inactivation) {
    headers.push(ROW_FIELD_HEADER_TITLE.INACTIVE_DATE);
  } else {
    headers.push(
      ROW_FIELD_HEADER_TITLE.MAPPING_TEAM,
      ROW_FIELD_HEADER_TITLE.MAPPING_OFFICE
    );
  }
  if (showState) {
    headers.push("Status (Fulfilled / Canceled)");
  }
  return headers;
};

const getFieldNames = (type: RequestType, showState: boolean): string[] => {
  const headers = [
    "legalEntity",
    "businessUnit",
    "function",
    "department",
    "team",
  ];
  if (type === RequestType.Inactivation) {
    headers.push("inactiveDate");
  } else {
    headers.push("mappingTeam", "mappingOffice");
  }
  if (showState) {
    headers.push("status");
  }
  return headers;
};

export interface ExportOptions {
  type: RequestType;
  rows?: RequestRow[];
  showState?: boolean;
  isAccepted?: boolean;
  requester?: string;
  reviewer?: string;
  date?: number;
}

export const exportToXMLS = (options: ExportOptions) => {
  // Generate worksheet and workbook
  const worksheet = utils.json_to_sheet([], {
    skipHeader: true,
  });
  const workbook = utils.book_new();
  utils.book_append_sheet(workbook, worksheet, "Ownership Team");

  if (options.rows) {
    // Format and add rows
    const rowsToXLSX: XLSXRequestRow[] = options.rows.map((row) => {
      const res = {
        legalEntity: row.legalEntity.text,
        businessUnit: row.businessUnit.text,
        function: row.function.text,
        department: row.department.text,
        team: row.team.text,
      } as XLSXRequestRow;
      if (options.type === RequestType.Inactivation) {
        res.inactiveDate = row.inactiveDate ? uiDate(row.inactiveDate) : "";
      } else {
        res.mappingTeam = row.mappingTeam;
        res.mappingOffice = row.mappingOffice;
      }
      if (options.showState) {
        // show Status column
        res.status = row.isDeleted ? "Canceled" : "Fulfilled";
      }
      return res;
    });

    utils.sheet_add_json(worksheet, rowsToXLSX, {
      origin: "A1",
    });
  }

  // Add headers
  const headers = getHeaders(options.type, options.showState);
  utils.sheet_add_aoa(worksheet, [headers], {
    origin: "A1",
  });

  // Set cell columns width to be readable since beginning
  worksheet["!cols"] = [
    { wpx: 110 },
    { wpx: 110 },
    { wpx: 110 },
    { wpx: 110 },
    { wpx: 110 },
    { wpx: 110 },
    { wpx: 140 },
    { wpx: 140 },
    { wpx: 140 },
    { wpx: 110 },
  ];

  // When the request is accepted we block the sheet and add extra info.
  if (options.isAccepted) {
    utils.sheet_add_aoa(
      worksheet,
      [
        ["Requested by:", options.requester],
        ["Reviewed by:", options.reviewer],
        ["Approval date:", uiDateTime(options.date, "DD MMM yyyy HH:mm")],
      ],
      { origin: `A${options.rows.length + 5}` }
    );

    const password =
      Math.random().toString(36).slice(2) + Math.random().toString(36).slice(2);
    worksheet["!protect"] = {
      password: password,
    };
  }

  // Create an XLSX file and try to save it.
  // This call will attempt to force a client-side download.
  writeFile(
    workbook,
    options.rows && options.rows[0].requestID
      ? `OTRequest-${options.rows[0].requestID}.xlsx`
      : `OwnershipTeam${
          options.type === RequestType.Edition ? "Mapping " : ""
        }${options.type}.xlsx`
  );
};

export const readFromXLSX = async (
  file: File,
  requestID: number = 0,
  type: RequestType
): Promise<XMLSRequestRows> => {
  const f = await file.arrayBuffer();
  const wb = read(f); // parse the array buffer
  const ws = wb.Sheets[wb.SheetNames[0]]; // get the first worksheet
  const header = getFieldNames(type, true); // Change headers to real field names

  utils.sheet_add_aoa(ws, [header], {
    origin: "A1",
  });
  const data = utils.sheet_to_json<XLSXRequestRow>(ws, { raw: false }); // generate objects
  return { requestID: requestID, type: type, rows: data };
};
