import React, { useContext, useState } from "react";

import Dialog from "@mui/material/Dialog";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";

import styles from "./css/FiltersSelector.module.css";
import { CloseIcon } from "../../../assets";
import { OEBSGlobalContext } from "../../../context/OEBSGlobal";
import {
  DateFilterValue,
  Filter,
} from "../../../../../models/OracleEBS/tablePageInformation";
import {
  Checkbox,
  CheckboxProps,
  FormControlLabel,
  FormGroup,
  Grid,
  styled,
} from "@mui/material";
import {
  RequestStatus,
  RequestType,
} from "../../../../../models/OracleEBS/request";
import {
  REQUEST_FIELD_NAMES,
  ROLE_CODES,
} from "../../../../../utils/OracleEBS/constants";
import moment, { Moment } from "moment";
import { getDateUnix } from "../../../../../utils/time";
import { getFilter } from "../../../../../utils/OracleEBS/filters";

interface Props {
  open: boolean;
  onCloseClick: () => void;
}

export const FiltersSelector: React.FC<Props> = ({ open, onCloseClick }) => {
  const { role } = useContext(OEBSGlobalContext);
  const { requestPageInformation, setRequestPageInformation } =
    useContext(OEBSGlobalContext);

  const typesFilter =
    getFilter(
      requestPageInformation.filters,
      "options",
      REQUEST_FIELD_NAMES.REQUEST_TYPE
    )?.value || [];
  const [typesCheckboxes, setTypesCheckboxes] = React.useState<{
    [key in RequestType]: boolean;
  }>({
    Creation: typesFilter.includes(RequestType.Creation),
    Edition: typesFilter.includes(RequestType.Edition),
    Inactivation: typesFilter.includes(RequestType.Inactivation),
  });
  const statusFilter =
    getFilter(
      requestPageInformation.filters,
      "options",
      REQUEST_FIELD_NAMES.STATUS
    )?.value || [];
  const [statusCheckboxes, setStatusCheckboxes] = React.useState<{
    [key in RequestStatus]: boolean;
  }>({
    Submitted: statusFilter.includes(RequestStatus.Submitted),
    Rejected: statusFilter.includes(RequestStatus.Rejected),
    Resolved: statusFilter.includes(RequestStatus.Resolved),
    Deleted: statusFilter.includes(RequestStatus.Deleted),
    Completed: statusFilter.includes(RequestStatus.Completed),
  });
  const [referenceNumber, setReferenceNumber] = useState<string>(
    String(
      getFilter(
        requestPageInformation.filters,
        "number",
        REQUEST_FIELD_NAMES.REQUEST_ID
      )?.value || ""
    )
  );
  const [email, setEmail] = useState<string>(
    getFilter(
      requestPageInformation.filters,
      "string",
      role.roleCode === ROLE_CODES.REQUESTER
        ? REQUEST_FIELD_NAMES.REVIEWER_EMAIL
        : REQUEST_FIELD_NAMES.REQUESTER_EMAIL
    )?.value || ""
  );
  const [lastUpdateRange, setLastUpdateRange] = useState<DateFilterValue>(
    getFilter(
      requestPageInformation.filters,
      "date",
      REQUEST_FIELD_NAMES.LAST_UPDATE
    )?.value || ({} as DateFilterValue)
  );
  const [creationRange, setCreationRange] = useState<DateFilterValue>(
    getFilter(requestPageInformation.filters, "date", REQUEST_FIELD_NAMES.DATE)
      ?.value || ({} as DateFilterValue)
  );

  const applyFilters = () => {
    const filters = [] as Filter[];

    const typesArray = [];
    for (let keyString in typesCheckboxes) {
      const key = keyString as RequestType;
      const check = typesCheckboxes[key];
      if (check) typesArray.push(key);
    }
    if (typesArray.length > 0)
      filters.push({
        field: REQUEST_FIELD_NAMES.REQUEST_TYPE,
        type: "options",
        value: typesArray,
      });

    const statusArray = [];
    for (let keyString in statusCheckboxes) {
      const key = keyString as RequestStatus;
      const check = statusCheckboxes[key];
      if (check) statusArray.push(key);
    }
    if (statusArray.length > 0)
      filters.push({
        field: REQUEST_FIELD_NAMES.STATUS,
        type: "options",
        value: statusArray,
      });

    if (referenceNumber)
      filters.push({
        field: REQUEST_FIELD_NAMES.REQUEST_ID,
        type: "number",
        value: Number(referenceNumber),
      });

    if (email)
      filters.push({
        field:
          role.roleCode === ROLE_CODES.REQUESTER
            ? REQUEST_FIELD_NAMES.REVIEWER_EMAIL
            : REQUEST_FIELD_NAMES.REQUESTER_EMAIL,
        type: "string",
        value: email,
      });

    if (lastUpdateRange.from || lastUpdateRange.to)
      filters.push({
        field: REQUEST_FIELD_NAMES.LAST_UPDATE,
        type: "date",
        value: lastUpdateRange,
      });

    if (creationRange.from || creationRange.to)
      filters.push({
        field: REQUEST_FIELD_NAMES.DATE,
        type: "date",
        value: creationRange,
      });

    setRequestPageInformation((prev) => {
      return { ...prev, filters: filters };
    });

    onCloseClick();
  };

  const clearFilters = () => {
    setTypesCheckboxes({
      Creation: false,
      Edition: false,
      Inactivation: false,
    });
    setStatusCheckboxes({
      Submitted: false,
      Rejected: false,
      Resolved: false,
      Deleted: false,
      Completed: false,
    });
    setEmail("");
    setReferenceNumber("");
    setLastUpdateRange({ from: null, to: null });
    setCreationRange({ from: null, to: null });
  };

  const onStatusChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const status = event.target.name;
    const checked = event.target.checked;
    setStatusCheckboxes((prev) => {
      return {
        ...prev,
        [status]: checked,
      };
    });
  };

  const onTypeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const type = event.target.name;
    const checked = event.target.checked;
    setTypesCheckboxes((prev) => {
      return {
        ...prev,
        [type]: checked,
      };
    });
  };

  const blockInvalidChar = (event: React.KeyboardEvent<HTMLInputElement>) =>
    ["e", "E", "+", "-", "."].includes(event.key) && event.preventDefault();

  return (
    <LocalizationProvider dateAdapter={AdapterMoment}>
      <Dialog open={open}>
        <div className={styles.filtersDiv}>
          <div className={styles.headerDiv}>
            <div className={styles.contentDiv}>
              <div className={styles.popuptittleDiv}>
                <b className={styles.tittleB}>Advanced Filters</b>
                <b
                  className={styles.tittleB}
                >{`(${requestPageInformation.filters.length})`}</b>
              </div>
              <button className={styles.xButton} onClick={onCloseClick}>
                <CloseIcon className={styles.vectorIcon} />
              </button>
            </div>
            <div className={styles.bigdivider} />
          </div>
          <div className={styles.containerDiv}>
            <div className={styles.frameDiv}>
              <div className={styles.frameDiv}>
                <div className={styles.statusDiv}>
                  <div className={styles.statusLabel}>
                    <b className={styles.statusB}>Type</b>
                  </div>
                  <div className={styles.containerDiv1}>
                    <FormGroup>
                      <Grid container rowSpacing={1}>
                        <Grid item xs={12}>
                          <FormControlLabel
                            onChange={onTypeChange}
                            sx={{ width: "100%" }}
                            control={
                              <BpCheckbox
                                checked={typesCheckboxes[RequestType.Creation]}
                                name={RequestType.Creation}
                              />
                            }
                            label={
                              <b className={styles.statustextB}>
                                Team creation
                              </b>
                            }
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <FormControlLabel
                            onChange={onTypeChange}
                            sx={{ width: "100%" }}
                            control={
                              <BpCheckbox
                                checked={typesCheckboxes[RequestType.Edition]}
                                name={RequestType.Edition}
                              />
                            }
                            label={
                              <b className={styles.statustextB}>
                                Team mapping edition
                              </b>
                            }
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <FormControlLabel
                            onChange={onTypeChange}
                            sx={{ width: "100%" }}
                            control={
                              <BpCheckbox
                                checked={
                                  typesCheckboxes[RequestType.Inactivation]
                                }
                                name={RequestType.Inactivation}
                              />
                            }
                            label={
                              <b className={styles.statustextB}>
                                Team inactivation
                              </b>
                            }
                          />
                        </Grid>
                      </Grid>
                    </FormGroup>
                  </div>
                </div>
                <div className={styles.divider} />
              </div>
              <div className={styles.frameDiv}>
                <div className={styles.statusDiv}>
                  <div className={styles.statusLabel}>
                    <b className={styles.statusB}>Status</b>
                  </div>
                  <div className={styles.containerDiv1}>
                    <FormGroup>
                      <Grid container rowSpacing={1}>
                        <Grid item xs={6}>
                          <FormControlLabel
                            onChange={onStatusChange}
                            sx={{ width: "100%" }}
                            control={
                              <BpCheckbox
                                checked={
                                  statusCheckboxes[RequestStatus.Submitted]
                                }
                                name={RequestStatus.Submitted}
                              />
                            }
                            label={
                              <b className={styles.statustextB}>Submitted</b>
                            }
                          />
                        </Grid>
                        <Grid item xs={6}>
                          <FormControlLabel
                            onChange={onStatusChange}
                            sx={{ width: "100%" }}
                            control={
                              <BpCheckbox
                                checked={
                                  statusCheckboxes[RequestStatus.Rejected]
                                }
                                name={RequestStatus.Rejected}
                              />
                            }
                            label={
                              <b className={styles.statustextB}>Rejected</b>
                            }
                          />
                        </Grid>
                        <Grid item xs={6}>
                          <FormControlLabel
                            onChange={onStatusChange}
                            sx={{ width: "100%" }}
                            control={
                              <BpCheckbox
                                checked={
                                  statusCheckboxes[RequestStatus.Resolved]
                                }
                                name={RequestStatus.Resolved}
                              />
                            }
                            label={
                              <b className={styles.statustextB}>Resolved</b>
                            }
                          />
                        </Grid>
                        <Grid item xs={6}>
                          <FormControlLabel
                            onChange={onStatusChange}
                            sx={{ width: "100%" }}
                            control={
                              <BpCheckbox
                                checked={
                                  statusCheckboxes[RequestStatus.Deleted]
                                }
                                name={RequestStatus.Deleted}
                              />
                            }
                            label={
                              <b className={styles.statustextB}>Deleted</b>
                            }
                          />
                        </Grid>
                        <Grid item xs={6}>
                          <FormControlLabel
                            onChange={onStatusChange}
                            sx={{ width: "100%" }}
                            control={
                              <BpCheckbox
                                checked={
                                  statusCheckboxes[RequestStatus.Completed]
                                }
                                name={RequestStatus.Completed}
                              />
                            }
                            label={
                              <b className={styles.statustextB}>Completed</b>
                            }
                          />
                        </Grid>
                      </Grid>
                    </FormGroup>
                  </div>
                </div>
                <div className={styles.divider} />
              </div>
              <div className={styles.frameDiv}>
                <div className={styles.inputDiv}>
                  <b className={styles.statusB}>Reference number</b>
                  <div className={styles.inputcontainerDiv}>
                    <div className={styles.inputDiv1}>
                      <input
                        className={styles.inputText}
                        type="number"
                        min={0}
                        value={referenceNumber}
                        onKeyDown={blockInvalidChar}
                        onChange={(event) =>
                          setReferenceNumber(event.currentTarget.value)
                        }
                      />
                      <button
                        className={styles.clearButton}
                        onClick={() => setReferenceNumber("")}
                      >
                        <CloseIcon className={styles.xIcon} />
                      </button>
                    </div>
                  </div>
                </div>
                <div className={styles.divider} />
              </div>
              <div className={styles.frameDiv}>
                <div className={styles.inputDiv}>
                  <b className={styles.statusB}>
                    {role.roleCode === ROLE_CODES.REQUESTER
                      ? "Reviewed by"
                      : "Requested by"}
                  </b>
                  <div className={styles.inputcontainerDiv}>
                    <div className={styles.inputDiv1}>
                      <input
                        className={styles.inputText}
                        value={email}
                        onChange={(event) =>
                          setEmail(event.currentTarget.value)
                        }
                      />
                      <button
                        className={styles.clearButton}
                        onClick={() => setEmail("")}
                      >
                        <CloseIcon className={styles.xIcon} />
                      </button>
                    </div>
                  </div>
                </div>
                <div className={styles.divider} />
              </div>
              <div className={styles.frameDiv}>
                <div className={styles.inputDiv4}>
                  <b className={styles.statusB}>Last update</b>
                  <div className={styles.daterangeDiv}>
                    <div className={styles.textDiv}>From</div>
                    <div className={styles.component45Div}>
                      <div className={styles.inputDiv5}>
                        <div className={styles.datecontentDiv}>
                          <div className={styles.inputDiv6}>
                            <CustomDatePicker
                              value={
                                lastUpdateRange.from !== null
                                  ? moment.unix(lastUpdateRange.from)
                                  : null
                              }
                              onChange={(value: Moment | null) => {
                                const date = getDateUnix(value);
                                setLastUpdateRange((prev) => {
                                  return { ...prev, from: date };
                                });
                              }}
                              onClearClick={() => {
                                setLastUpdateRange((prev) => {
                                  return { ...prev, from: null };
                                });
                              }}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className={styles.textDiv}>To</div>
                    <div className={styles.component45Div}>
                      <div className={styles.inputDiv5}>
                        <div className={styles.datecontentDiv}>
                          <div className={styles.inputDiv6}>
                            <CustomDatePicker
                              value={
                                lastUpdateRange.to !== null
                                  ? moment.unix(lastUpdateRange.to)
                                  : null
                              }
                              onChange={(value: Moment | null) => {
                                const date = getDateUnix(value);
                                setLastUpdateRange((prev) => {
                                  return { ...prev, to: date };
                                });
                              }}
                              onClearClick={() => {
                                setLastUpdateRange((prev) => {
                                  return { ...prev, to: null };
                                });
                              }}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className={styles.divider} />
              </div>
              <div className={styles.frameDiv}>
                <div className={styles.inputDiv4}>
                  <b className={styles.statusB}>Creation date</b>
                  <div className={styles.daterangeDiv}>
                    <div className={styles.textDiv}>From</div>
                    <div className={styles.component45Div}>
                      <div className={styles.inputDiv5}>
                        <div className={styles.datecontentDiv}>
                          <div className={styles.inputDiv6}>
                            <CustomDatePicker
                              value={
                                creationRange.from !== null
                                  ? moment.unix(creationRange.from)
                                  : null
                              }
                              onChange={(value: Moment | null) => {
                                const date = getDateUnix(value);
                                setCreationRange((prev) => {
                                  return { ...prev, from: date };
                                });
                              }}
                              onClearClick={() => {
                                setCreationRange((prev) => {
                                  return { ...prev, from: null };
                                });
                              }}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className={styles.textDiv}>To</div>
                    <div className={styles.component45Div}>
                      <div className={styles.inputDiv5}>
                        <div className={styles.datecontentDiv}>
                          <div className={styles.inputDiv6}>
                            <CustomDatePicker
                              value={
                                creationRange.to !== null
                                  ? moment.unix(creationRange.to)
                                  : null
                              }
                              onChange={(value: Moment | null) => {
                                const date = getDateUnix(value);
                                setCreationRange((prev) => {
                                  return { ...prev, to: date };
                                });
                              }}
                              onClearClick={() => {
                                setCreationRange((prev) => {
                                  return { ...prev, to: null };
                                });
                              }}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className={styles.divider4} />
              </div>
            </div>
          </div>
          <div className={styles.buttonscontainerDiv}>
            <button className={styles.buttontext} onClick={clearFilters}>
              <div className={styles.labelbuttontextDiv}>CLEAR ALL</div>
            </button>
            <button className={styles.buttonfilled} onClick={applyFilters}>
              <div
                className={styles.labelbuttonfilledDiv}
              >{`Apply filters `}</div>
            </button>
          </div>
        </div>
      </Dialog>
    </LocalizationProvider>
  );
};

const BpIcon = styled("span")(({ theme }) => ({
  borderRadius: "2px",
  width: "18px",
  height: "18px",
  boxShadow:
    theme.palette.mode === "dark"
      ? "0 0 0 1px rgb(16 22 26 / 40%)"
      : "inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)",
  backgroundColor: "#f5f8fa",
  backgroundImage:
    "linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))",
  ".Mui-focusVisible &": {
    outline: "2px auto rgba(19,124,189,.6)",
    outlineOffset: 2,
  },
  "&:before": {
    display: "block",
    width: "18px",
    height: "18px",
    backgroundImage:
      "url(\"data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath" +
      " fill-rule='evenodd' clip-rule='evenodd' d='M12 5c-.28 0-.53.11-.71.29L7 9.59l-2.29-2.3a1.003 " +
      "1.003 0 00-1.42 1.42l3 3c.18.18.43.29.71.29s.53-.11.71-.29l5-5A1.003 1.003 0 0012 5z' fill='%23fff'/%3E%3C/svg%3E\")",
    content: '""',
  },
  "input:hover ~ &": {
    backgroundColor: "#cba0e9",
  },
}));

const BpCheckedIcon = styled(BpIcon)({
  backgroundColor: "#973fd4",
  "input:hover ~ &": {
    backgroundColor: "#cba0e9",
  },
});

// Inspired by blueprintjs
const BpCheckbox = (props: CheckboxProps) => {
  return (
    <Checkbox
      sx={{
        "&:hover": { bgcolor: "transparent" },
        padding: "0px 8px 0px 0px",
      }}
      disableRipple
      color="default"
      checkedIcon={<BpCheckedIcon />}
      icon={<BpIcon />}
      inputProps={{ "aria-label": "Checkbox demo" }}
      {...props}
    />
  );
};

interface CustomDatePickerProps {
  value: moment.Moment;
  onChange: (value: Moment | null) => void;
  onClearClick: () => void;
}

const CustomDatePicker: React.FC<CustomDatePickerProps> = ({
  value,
  onChange,
  onClearClick,
}) => {
  return (
    <DesktopDatePicker
      className={styles.bodyCellTextField}
      label="Date desktop"
      inputFormat="DD-MM-YYYY"
      value={value}
      onChange={onChange}
      renderInput={({ inputRef, inputProps, InputProps }) => (
        <>
          <input ref={inputRef} {...inputProps} className={styles.inputDate} />
          <div className={styles.calendarIconDiv}>
            {InputProps?.endAdornment}
          </div>
          <button className={styles.clearButton} onClick={onClearClick}>
            <CloseIcon className={styles.xIcon} />
          </button>
        </>
      )}
    />
  );
};
