import React, { createContext, useState } from "react";
import {
  ButtonRounded,
  ButtonVariants,
} from "../components/Buttons/Rounded/ButtonRounded";
import { ConfirmationDialog } from "../components/Dialog/ConfirmationDialog";

export interface DialogOptions {
  title: string;
  message?: string;
  additionalButton?: React.ReactNode;
  additionalComponent?: React.ReactNode;
  confirmButton?: ButtonOptions;
  dismissButton?: ButtonOptions;
  actionCallBack?: (value: boolean | PromiseLike<boolean>) => void;
}

interface ButtonOptions {
  variant: ButtonVariants;
  content: React.ReactNode;
}

interface ContextProps {
  openDialog: (opt: DialogOptions) => void;
}

export const ConfirmationDialogContext = createContext<ContextProps>(
  {} as ContextProps
);

interface ProviderProps {
  children: React.ReactNode;
}

interface State {
  isOpen: boolean;
  config: DialogOptions;
}

export const ConfirmationDialogProvider: React.FC<ProviderProps> = ({
  children,
}) => {
  const [state, setState] = useState<State>({
    isOpen: false,
    config: {} as DialogOptions,
  });

  const openDialog = (opts: DialogOptions) => {
    setState({ isOpen: true, config: opts });
  };

  const resetDialog = () => {
    setState({ isOpen: false, config: {} as DialogOptions });
  };

  const onConfirm = () => {
    state.config.actionCallBack(true);
    resetDialog();
  };

  const onDismiss = () => {
    state.config.actionCallBack(false);
    resetDialog();
  };

  return (
    <ConfirmationDialogContext.Provider value={{ openDialog }}>
      {state.isOpen && (
        <ConfirmationDialog
          onClickExit={onDismiss}
          open
          title={state.config?.title}
          message={state.config?.message}
          AdditionalButton={state.config.additionalButton}
          AdditionalComponent={state.config.additionalComponent}
          AcceptButton={
            state.config.confirmButton ? (
              <ButtonRounded
                variant={state.config.confirmButton.variant}
                type="button"
                onClick={onConfirm}
              >
                {state.config.confirmButton.content}
              </ButtonRounded>
            ) : (
              <ButtonRounded
                variant={"Green"}
                type="button"
                onClick={onConfirm}
              >
                Accept
              </ButtonRounded>
            )
          }
          CancelButton={
            state.config.dismissButton ? (
              <ButtonRounded
                variant={state.config.dismissButton.variant}
                type="button"
                onClick={onDismiss}
              >
                {state.config.dismissButton.content}
              </ButtonRounded>
            ) : (
              <ButtonRounded
                variant={"Transparent"}
                type="button"
                onClick={onDismiss}
              >
                Cancel
              </ButtonRounded>
            )
          }
        />
      )}
      {children}
    </ConfirmationDialogContext.Provider>
  );
};
