export const ENDPOINTS = {
  REQUEST: "request",
  REQUESTS: "requests",
  OPTIONS: "options",
  MAPPINGS: "mappings",
  CREATE_REQUEST: "request/create",
  UPDATE_STATUS: "/request/update-status",
  EDIT_REQUEST: "/request/edit",
  DELETE_REQUEST: "/request/delete",
  AUDIT_LOGS: "/request/audit",
  VALIDATE_ROWS: "/request/rows/validate",
  USER_MANUAL: "download/user-manual",
  REVIEWER_LIST: "/admin/reviewers/list",
  REQUESTERS_LIST: "/admin/requesters/list",
  MODIFY_ROLE: "/users/roles",
  REQUEST_LIST_XLSX: "/download/request-list",
  REQUEST_XLSX: "/download/request",
  COMPLETED_DFLT_RECIPIENTS: "/admin/recipients/completed",
  STATUS_CHANGE_DFLT_RECIPIENTS: "/admin/recipients/status-change",
  COMPLETED_DFLT_RECIPIENTS_REMOVE: "/admin/recipients/completed/remove",
  STATUS_CHANGE_DFLT_RECIPIENTS_REMOVE:
    "/admin/recipients/status-change/remove",
  EXPIRY_DATE: "/admin/expiry-date",
};
