import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";

import { TextField } from "@mui/material";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";

import styles from "../css/Row.module.css";
import { RequestRow, RowField } from "../../../../../models/OracleEBS/request";
import moment, { Moment } from "moment";
import {
  ROW_FIELD_NAMES,
  ROW_FIELD_PARAM,
} from "../../../../../utils/OracleEBS/constants";
import { RequestService } from "../../../../../api/OracleEBS/request";
import { Config } from "../../../../../utils/config";
import { inferError } from "../../../../../utils/inferError";
import { useSnackbar } from "notistack";
import { OEBSGlobalContext } from "../../../context/OEBSGlobal";
import { RowFieldAutocomplete } from "../common/RowFieldAutocomplete";

const theme = createTheme({
  components: {
    MuiOutlinedInput: {
      styleOverrides: {
        notchedOutline: {
          border: "none",
        },
      },
    },
    MuiAutocomplete: {
      styleOverrides: {
        root: { border: "none" },
        listbox: {
          overflow: "unset",
        },
        paper: {
          backgroundColor: "#eaebf2",
          maxHeight: "200px",
        },
      },
    },
  },
});

interface Props {
  row: RequestRow;
  handleChange?: (
    rowNumber: number,
    field: string,
    value: string | number | null
  ) => void;
  deleteSelection?: (rowNumber: number, fields: string[]) => void;
  legalEntityOptions?: RowField[];
  markWrongCells: boolean;
}

export const TeamInactivationRow: React.FC<Props> = ({
  row,
  handleChange,
  deleteSelection,
  legalEntityOptions,
  markWrongCells,
}) => {
  const { app } = useContext(OEBSGlobalContext);
  const { enqueueSnackbar } = useSnackbar();
  const [businessUnitOptions, setBusinessUnitOptions] = useState<RowField[]>(
    []
  );
  const [functionOptions, setFunctionOptions] = useState<RowField[]>([]);
  const [departmentOptions, setDepartmentOptions] = useState<RowField[]>([]);
  const [teamOptions, setTeamOptions] = useState<RowField[]>([]);
  const [validDate, setValidDate] = useState<boolean>(true);
  const requestService = useMemo(
    () => new RequestService(`${Config.apiUrl}${app?.appRoutePath}`),
    [app?.appRoutePath]
  );
  const [markCell, setMarkCell] = useState<{
    legalEntity: boolean;
    businessUnit: boolean;
    function: boolean;
    department: boolean;
    team: boolean;
  }>({
    legalEntity: markWrongCells && !row.legalEntity,
    businessUnit: markWrongCells && !row.businessUnit && !!row.legalEntity,
    function: markWrongCells && !row.function && !!row.businessUnit,
    department: markWrongCells && !row.department && !!row.function,
    team: markWrongCells && !row.team && !!row.department,
  });

  const getFieldOptions = useCallback(
    async (field: string, parent: number) => {
      try {
        const options = await requestService.getFieldOptionsByParent(
          field,
          parent
        );
        return options;
      } catch (error: any) {
        const err = inferError(error);
        enqueueSnackbar(err.errorMessage, { variant: "error" });
      }
    },
    [enqueueSnackbar, requestService]
  );

  useEffect(() => {
    (async () => {
      setMarkCell({
        legalEntity: markWrongCells && !row.legalEntity,
        businessUnit: markWrongCells && !row.businessUnit && !!row.legalEntity,
        function: markWrongCells && !row.function && !!row.businessUnit,
        department: markWrongCells && !row.department && !!row.function,
        team: markWrongCells && !row.team && !!row.department,
      });
      if (row.legalEntity) {
        const businessUnitOps = await getFieldOptions(
          ROW_FIELD_PARAM.BUSINESS_UNIT,
          row.legalEntity.code
        );
        setBusinessUnitOptions(businessUnitOps);
      }
      if (row.businessUnit) {
        const functionOps = await getFieldOptions(
          ROW_FIELD_PARAM.FUNCTION,
          row.businessUnit.code
        );
        setFunctionOptions(functionOps);
      }
      if (row.function) {
        const departmentOps = await getFieldOptions(
          ROW_FIELD_PARAM.DEPARTMENT,
          row.function.code
        );
        setDepartmentOptions(departmentOps);
      }
      if (row.department) {
        const teamOps = await getFieldOptions(
          ROW_FIELD_PARAM.TEAM,
          row.department.code
        );
        setTeamOptions(teamOps);
      }
    })();
  }, [
    getFieldOptions,
    markWrongCells,
    row.businessUnit,
    row.department,
    row.function,
    row.legalEntity,
    row.team,
  ]);

  const onChange =
    (field: string) =>
    async (
      event: React.ChangeEvent<HTMLInputElement>,
      value: string | string | null
    ) => {
      handleChange(row.rowNumber, field, value || event.target.value);
      let fieldsToClear: string[] = [];
      switch (field) {
        case ROW_FIELD_NAMES.LEGAL_ENTITY:
          fieldsToClear = [
            ROW_FIELD_NAMES.BUSINESS_UNIT,
            ROW_FIELD_NAMES.FUNCTION,
            ROW_FIELD_NAMES.DEPARTMENT,
            ROW_FIELD_NAMES.TEAM,
            ROW_FIELD_NAMES.MAPPING_TEAM,
            ROW_FIELD_NAMES.MAPPING_OFFICE,
          ];
          break;
        case ROW_FIELD_NAMES.BUSINESS_UNIT:
          fieldsToClear = [
            ROW_FIELD_NAMES.FUNCTION,
            ROW_FIELD_NAMES.DEPARTMENT,
            ROW_FIELD_NAMES.TEAM,
            ROW_FIELD_NAMES.MAPPING_TEAM,
            ROW_FIELD_NAMES.MAPPING_OFFICE,
          ];
          break;
        case ROW_FIELD_NAMES.FUNCTION:
          fieldsToClear = [
            ROW_FIELD_NAMES.DEPARTMENT,
            ROW_FIELD_NAMES.TEAM,
            ROW_FIELD_NAMES.MAPPING_TEAM,
            ROW_FIELD_NAMES.MAPPING_OFFICE,
          ];
          break;
        case ROW_FIELD_NAMES.DEPARTMENT:
          fieldsToClear = [ROW_FIELD_NAMES.TEAM];
          break;
      }
      if (fieldsToClear.length > 0)
        deleteSelection(row.rowNumber, fieldsToClear);
    };

  const handleDatePick = (value: Moment | null) => {
    if (!value) {
      setValidDate(true);
      handleChange(row.rowNumber, ROW_FIELD_NAMES.INACTIVE_DATE, null);
      return;
    }
    setValidDate(value.isValid());
    // set to zero the hour, minutes, seconds and milliseconds of the given input.
    value.utcOffset(0, true);
    value.set("hour", 0);
    value.set("minute", 0);
    value.set("second", 0);
    value.set("millisecond", 0);
    handleChange(row.rowNumber, ROW_FIELD_NAMES.INACTIVE_DATE, value.unix());
  };

  return (
    <LocalizationProvider dateAdapter={AdapterMoment}>
      <ThemeProvider theme={theme}>
        <div className={styles.rowDiv}>
          <RowFieldAutocomplete
            value={row.legalEntity}
            onChange={onChange(ROW_FIELD_NAMES.LEGAL_ENTITY)}
            options={legalEntityOptions}
            disabled={false}
            placeholder="Eclipse Legal Entity"
            invalidCell={markCell.legalEntity}
          />
          <RowFieldAutocomplete
            value={row.businessUnit}
            onChange={onChange(ROW_FIELD_NAMES.BUSINESS_UNIT)}
            options={businessUnitOptions}
            disabled={!row.legalEntity}
            placeholder="Eclipse Business Unit"
            invalidCell={markCell.businessUnit}
          />
          <RowFieldAutocomplete
            value={row.function}
            onChange={onChange(ROW_FIELD_NAMES.FUNCTION)}
            options={functionOptions}
            disabled={!row.businessUnit}
            placeholder="Eclipse Function"
            invalidCell={markCell.function}
          />
          <RowFieldAutocomplete
            value={row.department}
            onChange={onChange(ROW_FIELD_NAMES.DEPARTMENT)}
            options={departmentOptions}
            disabled={!row.function}
            placeholder="Eclipse Department"
            invalidCell={markCell.department}
          />
          <RowFieldAutocomplete
            value={row.team}
            onChange={onChange(ROW_FIELD_NAMES.TEAM)}
            options={teamOptions}
            disabled={!row.department}
            placeholder="Eclipse Team"
            invalidCell={markCell.team}
          />
          <div className={styles.cell}>
            <div
              className={`${
                (!validDate || (markWrongCells && !row.inactiveDate)) &&
                styles.invalidCell
              }`}
            >
              <DesktopDatePicker
                className={styles.bodyCellTextField}
                label="Date desktop"
                inputFormat="DD-MM-YYYY"
                value={
                  row.inactiveDate !== null
                    ? moment.unix(row.inactiveDate)
                    : null
                }
                onChange={handleDatePick}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    required
                    color="secondary"
                    label=""
                    placeholder="Inactive date"
                    margin="none"
                    variant="outlined"
                  />
                )}
              />
            </div>
          </div>
        </div>
      </ThemeProvider>
    </LocalizationProvider>
  );
};
