import React, { createContext, useCallback, useState } from "react";
import { FullScreen } from "../components/Progress/FullScreen";

interface ContextProps {
  startLoading: () => void;
  stopLoading: () => void;
}

export const LoadingProgressContext = createContext<ContextProps>(
  {} as ContextProps
);

interface ProviderProps {
  children: React.ReactNode;
}

export const LoadingProgressProvider: React.FC<ProviderProps> = ({
  children,
}) => {
  const [isLoading, setIsLoading] = useState(false);

  const startLoading = useCallback(() => {
    setIsLoading(true);
  }, [setIsLoading]);

  const stopLoading = useCallback(() => {
    setIsLoading(false);
  }, [setIsLoading]);

  return (
    <LoadingProgressContext.Provider
      value={{
        startLoading: startLoading,
        stopLoading: stopLoading,
      }}
    >
      <FullScreen open={isLoading} />
      {children}
    </LoadingProgressContext.Provider>
  );
};
