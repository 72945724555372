import React from "react";
import * as Icons from "../../assets/appIcons";

interface Props {
  iconName?: string;
  className?: string;
}

export const AppIcon: React.FC<Props> = ({ iconName, className }) => {
  switch (iconName) {
    case "appIcon1":
      return <Icons.AppIcon1 className={className} />;
    case "appIcon2":
      return <Icons.AppIcon2 className={className} />;
    case "appIcon3":
      return <Icons.AppIcon3 className={className} />;

    default:
      return <Icons.DefaultAppIcon className={className} />;
  }
};
