import { Grid, IconButton } from "@material-ui/core";
import clone from "clone";
import MUIDataTable, {
  MUIDataTableColumnDef,
  MUIDataTableColumnOptions,
  MUIDataTableMeta,
  MUIDataTableOptions,
  MUIDataTableState,
} from "mui-datatables";
import React from "react";
import { PageInformation } from "./models/PageInformation";
import { getStringFilterOptions } from "../StringFilter";
import { getNumberFilterOptions } from "../NumberFilter";
import { getDateRangeFilterOptions } from "../DateRangeFilter";
import { getOptionFilterOptions } from "../OptionFilter";
import { setDisplay, setSortThirdClickReset } from "./helpers/options";
import { fixTableStateError, getPageInfo } from "./helpers/paginationHelpers";
import { Item } from "../OptionFilter";

interface ColumnBase {
  /** ID of the column. */
  id: string;
  /** Label for the column. */
  label: string;
  /** Display mode for the column. */
  display?: "true" | "false" | "excluded";
  /** Function that returns a string or React component. Used to display data within all table cells of a given column. **/
  customBodyRender?: (
    value: any,
    tableMeta: MUIDataTableMeta,
    updateValue: (value: string) => void
  ) => React.ReactNode;
  filterList?: string[];
}

interface StringColumn extends ColumnBase {
  /** Type of the column. */
  type: "string";
  /** Icon to be displayed on the clear button. If not provided, there will be no clear button. */
  clearIcon?: JSX.Element;
}

interface NumberColumn extends ColumnBase {
  /** Type of the column. */
  type: "number";
}

interface DateRangeColumn extends ColumnBase {
  /** Type of the column. */
  type: "dateRange";
  /** Label for the column. */
  label: string;
  /** Icon to be displayed on the clear button. If not provided, there will be no clear button. */
  clearIcon?: JSX.Element;
  /** Icon to be displayed on the null button. If not provided, there will be no null button. */
  nullIcon?: JSX.Element;
  /** Word for the since date. If not provided, it will be 'SINCE' */
  sinceWord?: string;
  /** Word for the to date. If not provided, it will be 'TO' */
  untilWord?: string;
}

interface OptionsColumn extends ColumnBase {
  /** Type of the column. */
  type: "options";
  /** Items to be displayed on the select. */
  items: Item[];
  /** Text for the default option. If not provided, it will be 'No filter'. */
  noFilterStr?: string;
  /** Style class for the select. */
  className?: string;
}

interface NoFilterColumn extends ColumnBase {
  /** Type of the column. */
  type: "noFilter";
}

interface NoOptionsColumn extends ColumnBase {
  /** Type of the column. */
  type: "noOptions";
}

export type Column =
  | StringColumn
  | DateRangeColumn
  | NumberColumn
  | OptionsColumn
  | NoFilterColumn
  | NoOptionsColumn;

export const parseColumn = (c: Column): MUIDataTableColumnDef => {
  let filterOpt: MUIDataTableColumnOptions;
  switch (c.type) {
    case "string":
      filterOpt = getStringFilterOptions(
        c.label,
        c.clearIcon,
        c.filterList,
        c.customBodyRender
      );
      break;
    case "number":
      filterOpt = getNumberFilterOptions(
        c.label,
        c.customBodyRender,
        c.filterList
      );
      break;
    case "dateRange":
      filterOpt = getDateRangeFilterOptions(
        c.label,
        c.clearIcon,
        c.nullIcon,
        c.sinceWord,
        c.untilWord,
        c.filterList,
        c.customBodyRender
      );
      break;
    case "options":
      filterOpt = getOptionFilterOptions(
        c.label,
        c.items,
        c.noFilterStr,
        c.className,
        c.filterList,
        c.customBodyRender
      );
      break;
    case "noFilter":
      filterOpt = {
        customBodyRender: c.customBodyRender,
        display: c.display,
        filter: false,
        viewColumns: false,
      };
      break;
    case "noOptions":
      filterOpt = {};
  }

  if (c.display) {
    filterOpt = setDisplay(filterOpt, c.display);
  }
  filterOpt = setSortThirdClickReset(filterOpt);

  return {
    name: c.id,
    label: c.label,
    options: filterOpt,
  };
};

export const tableColumns: MUIDataTableColumnDef[] = [
  {
    name: "item",
    options: {
      // This will load the item but it will not be shown in the UI.
      display: undefined,
      viewColumns: false,
      searchable: false,
      filter: false,
    },
  },
];

export interface ServerSideInformation {
  /** The page information the table needs to work together with a server. */
  pageInformation: PageInformation;
  /** Function to call after the page information changes. This is needed if working with a server */
  onPageChange: (PageInformation: PageInformation) => void;
  /** The number of elements the collection contains in total, not the number of elements on the current page.
   * If not provided, it will be the length of the given 'list'. */
  numRows?: number;
}

export interface Props<T> {
  /** List of elements the table will display. */
  list: T[];
  /** Columns with the information that will be displayed. */
  columns: Column[];
  /** Function to transform the fields the item contains into representative information. */
  itemToData: (item: T) => object | string[] | number[];
  /** Function to call when an item from the list is selected. */
  onSelect: (item: T) => void;
  /** Number of rows per page to be displayed initially. */
  rowsPerPage?: number;
  /** Options for the number of rows the table will display. If not provided, they will be '[5, 10, 15, 20, 50, 100]' */
  rowsPerPageOptions?: number[];

  /** Add button icon. If not provided, there will be no add button */
  addIcon?: JSX.Element;
  /** Function to call when the add button is pressed. */
  onNewItemSelect?: () => void;

  /** Information needed to work with server side pagination. If not provided, the table will work locally. */
  serverSideInfo?: ServerSideInformation;

  /** JSX element to display on the header of the table. */
  headerContent?: JSX.Element;
}

/** A table with various functionality that allows back-end side integration and autodetects the language in order to translate the content keywords.
 * Supported languages: English (default) and Spanish.
 */
export function Table<T extends object>({
  list,
  columns,
  itemToData,
  onSelect,
  rowsPerPage,
  rowsPerPageOptions,
  addIcon,
  onNewItemSelect,
  serverSideInfo,
  headerContent,
}: Props<T>): React.ReactElement {
  const options: MUIDataTableOptions = {
    filterType: "textField",
    download: false,
    print: false,
    selectableRowsHeader: false,
    selectToolbarPlacement: "none",
    selectableRows: "none",
    rowsPerPage: rowsPerPage || serverSideInfo?.pageInformation.rowsPerPage,
    rowsPerPageOptions: rowsPerPageOptions || [5, 10, 15, 20, 50, 100],
    page: serverSideInfo?.pageInformation.page,
    elevation: 5,
    onRowClick: (
      rowData: string[],
      _rowState: { dataIndex: number; rowIndex: number }
    ) => {
      onSelect(list[_rowState.dataIndex]); // rowData[0] contains the whole item.
    },
    serverSide: !!serverSideInfo,
    onTableChange:
      serverSideInfo &&
      ((action: string, tableState: MUIDataTableState) => {
        // We fix the array of values error.
        fixTableStateError(tableState);
        const newPageInfo = getPageInfo(clone(tableState));
        // Prevent the state is set again with a new instance of page info entity
        // if they are equal. It fix the infinite loop that performs the 'getPage'
        // callback.
        if (
          JSON.stringify(newPageInfo) !==
          JSON.stringify(serverSideInfo.pageInformation)
        ) {
          serverSideInfo.onPageChange(clone(newPageInfo));
        }
      }),
    count: serverSideInfo?.numRows,
  };

  const data = list.map((item) => {
    const result: (object | string[] | number[])[] = [item]; // result[0] contains the whole item.
    return result.concat(itemToData(item));
  });

  return (
    <MUIDataTable
      title={
        (headerContent || addIcon) && (
          <Grid container spacing={1} xs={12} item>
            {headerContent && (
              <Grid lg={4} xs={10} item>
                {headerContent}
              </Grid>
            )}
            <Grid lg={4} xs={1} item>
              {addIcon && (
                <IconButton
                  data-qa="add-button"
                  onClick={() => {
                    onNewItemSelect();
                  }}
                  aria-label="add"
                >
                  {addIcon}
                </IconButton>
              )}
            </Grid>
          </Grid>
        )
      }
      options={options}
      columns={tableColumns.concat(columns.map((c: Column) => parseColumn(c)))}
      data={data}
    />
  );
}
