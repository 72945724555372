import React, { useEffect, useState } from "react";
import { Props as StringInputProps, StringInput } from "../StringInput";

export const DEFAULT_DELAY = 500; // milliseconds.

export interface Props {
  /** The amount of milliseconds that are garanteed to be waited before calling 'onChange'. */
  delay?: number;
  /** Input onChange function. */
  onChange: (value?: string | null) => void;
}

type StringInputPropsWithoutOnChange = Omit<StringInputProps, "onChange">;
export type StringInputDelayProps = Props & StringInputPropsWithoutOnChange;

export const StringInputDelay: React.FC<StringInputDelayProps> = (props) => {
  const { delay, onChange, value } = props;
  const [actualValue, setActualValue] = useState<string>(value);
  useEffect(() => {
    const timer = setTimeout(
      () => onChange(actualValue),
      delay || DEFAULT_DELAY
    );
    return () => clearTimeout(timer);
  }, [actualValue, delay, onChange]);

  const inputProps: StringInputProps = {
    ...props,
    value: actualValue,
    onChange: setActualValue,
  };
  return <StringInput data-qa="string-input-delay" {...inputProps} />;
};
