import React from "react";
import { RequestType } from "../../../../../models/OracleEBS/request";
import { ROW_FIELD_HEADER_TITLE } from "../../../../../utils/OracleEBS/constants";

import styles from "../css/Header.module.css";

interface Props {
  type: RequestType;
}

export const Header: React.FC<Props> = ({ type }) => {
  return (
    <header className={styles.header} id="Header">
      <div className={styles.headerCellDiv}>
        <div className={styles.textDiv}>
          {ROW_FIELD_HEADER_TITLE.LEGAL_ENTITY}
        </div>
      </div>
      <div className={styles.headerCellDiv}>
        <div className={styles.textDiv}>
          {ROW_FIELD_HEADER_TITLE.BUSINESS_UNIT}
        </div>
      </div>
      <div className={styles.headerCellDiv}>
        <div className={styles.textDiv}>{ROW_FIELD_HEADER_TITLE.FUNCTION}</div>
      </div>
      <div className={styles.headerCellDiv}>
        <div className={styles.textDiv}>
          {ROW_FIELD_HEADER_TITLE.DEPARTMENT}
        </div>
      </div>
      <div className={styles.headerCellDiv}>
        <div className={styles.textDiv}>{ROW_FIELD_HEADER_TITLE.TEAM}</div>
      </div>
      {type === RequestType.Inactivation ? (
        <div className={styles.headerCellDiv}>
          <div className={styles.textDiv}>
            {ROW_FIELD_HEADER_TITLE.INACTIVE_DATE}
          </div>
        </div>
      ) : (
        <>
          <div className={styles.headerCellDiv}>
            <div className={styles.textDiv}>
              {ROW_FIELD_HEADER_TITLE.MAPPING_TEAM}
            </div>
          </div>
          <div className={styles.headerCellDiv}>
            <div className={styles.textDiv}>
              {ROW_FIELD_HEADER_TITLE.MAPPING_OFFICE}
            </div>
          </div>
        </>
      )}
    </header>
  );
};
