import { Grid, IconButton, Input, InputLabel } from "@material-ui/core";
import React from "react";

export interface Props {
  /** Label for the input. */
  label?: string;
  /** The value of the input. */
  value?: string | null;
  /** Input onChange function. */
  onChange?: (value?: string | null) => void;
  /** Whether the input is required or not. */
  required?: boolean;

  /** The clear icon the input will use. When the clear button is pressed, the onChange function is called with 'null' value. */
  clearIcon?: JSX.Element;
  /** Whether the clear icon is always enabled or not. */
  buttonAlwaysEnabled?: boolean;

  /** Whether the input will be full width or not. */
  fullWidth?: boolean;
  /** Style class for the input. */
  className?: string;
}

/** A simple input for strings with an option to be cleared. */
export const StringInput: React.FC<Props> = ({
  label,
  value,
  onChange,
  required,
  clearIcon,
  buttonAlwaysEnabled,
  fullWidth,
  className,
}) => {
  return (
    <Grid data-qa="string-input">
      {label && <InputLabel data-qa="label">{label}</InputLabel>}
      <Input
        data-qa="input"
        type="text"
        value={value || ""}
        fullWidth={fullWidth}
        required={required}
        className={className}
        onChange={(e) => {
          if (onChange) {
            onChange(e.target.value);
          }
        }}
      />
      {clearIcon && (
        <IconButton
          data-qa="clear-button"
          color="secondary"
          disabled={buttonAlwaysEnabled ? false : !value}
          onClick={() => {
            if (onChange) {
              onChange(null);
            }
          }}
        >
          {clearIcon}
        </IconButton>
      )}
    </Grid>
  );
};
