export const APP_NAME = "Ownership team";

export const ROLE_CODES = {
  REQUESTER: "OEBS_REQUESTER",
  REVIEWER: "OEBS_REVIEWER",
  ADMIN: "OEBS_ADMIN",
};

export const REQUEST_FIELD_NAMES = {
  REQUEST_ID: "requestID",
  REQUEST_TYPE: "requestType",
  REQUESTER_ID: "requesterID",
  REVIEWER_ID: "reviewerID",
  DATE: "requestDate",
  LAST_UPDATE: "requestLastUpdate",
  STATUS: "requestStatus",
  REVIEWER_EMAIL: "reviewerEmail",
  REQUESTER_EMAIL: "requesterEmail",
};

export const RequestListHeaders: { [key: string]: string } = {
  requestID: "Ref.",
  requestType: "Request type",
  reviewerEmail: "Reviewed by",
  requesterEmail: "Requested by",
  requestLastUpdate: "Last Update",
  requestDate: "Creation Date",
  requestStatus: "Status",
};

export const ROW_FIELD_NAMES = {
  LEGAL_ENTITY: "legalEntity",
  BUSINESS_UNIT: "businessUnit",
  FUNCTION: "function",
  DEPARTMENT: "department",
  TEAM: "team",
  INACTIVE_DATE: "inactiveDate",
  MAPPING_TEAM: "mappingTeam",
  MAPPING_OFFICE: "mappingOffice",
};

export const ROW_FIELD_PARAM = {
  LEGAL_ENTITY: "legal-entity",
  BUSINESS_UNIT: "business-unit",
  FUNCTION: "function",
  DEPARTMENT: "department",
  TEAM: "team",
  MAPPING_TEAM: "mapping-team",
  MAPPING_OFFICE: "mapping-office",
};

export const ROW_FIELD_HEADER_TITLE = {
  LEGAL_ENTITY: "Eclipse Legal Entity",
  BUSINESS_UNIT: "Eclipse Business Unit",
  FUNCTION: "Eclipse Function",
  DEPARTMENT: "Eclipse Department",
  TEAM: "Eclipse Team",
  INACTIVE_DATE: "Inactive Date",
  MAPPING_TEAM: "Oracle / EBS mapping Team",
  MAPPING_OFFICE: "Oracle / EBS mapping Office",
  STATUS: "Status",
};

export const SUPPORT_EMAIL = {
  EMAIL: "support@example.com",
  SUBJECT: "Support Ownership Team Mapping Application",
};
