import React from "react";
import { Route, Routes } from "react-router-dom";

import { Header } from "../components/Header/Header";
import { OracleEBS } from "../components/OracleEBS/OracleEBS";
import { CreateRequest } from "../components/Request/CreateRequest";
import styles from "./css/Router.module.css";
import { Request } from "../components/Request/Request";
import { ConfirmationDialogProvider } from "../context/ConfirmationDialog";
import { OEBSGlobalProvider } from "../context/OEBSGlobal";
import { AppEntity } from "../../../models/app";
import { Role } from "../../../models/role";
import { RequestType } from "../../../models/OracleEBS/request";
import { HelpButtons } from "../components/Buttons/Help/HelpButtons";
import { LoadingProgressProvider } from "../context/LoadingProgress";

interface Props {
  app: AppEntity;
  role: Role;
}

export const Router: React.FC<Props> = ({ app, role }) => {
  return (
    <OEBSGlobalProvider app={app} role={role}>
      <ConfirmationDialogProvider>
        <LoadingProgressProvider>
          <div className={styles.root}>
            <div className={styles.frameDiv}>
              <Header />
              <HelpButtons />
              <Routes>
                <Route path="/" element={<OracleEBS />} />
                <Route path="/:id" element={<Request />} />
                <Route
                  path="/create-team"
                  element={<CreateRequest type={RequestType.Creation} />}
                />
                <Route
                  path="/edit-team"
                  element={<CreateRequest type={RequestType.Edition} />}
                />
                <Route
                  path="/inactivate-team"
                  element={<CreateRequest type={RequestType.Inactivation} />}
                />
              </Routes>
            </div>
          </div>
        </LoadingProgressProvider>
      </ConfirmationDialogProvider>
    </OEBSGlobalProvider>
  );
};
