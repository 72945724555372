import React from "react";

import styles from "../css/Footer.module.css";
import { AddRowIcon } from "../../../assets";

interface Props {
  addRow?: () => void;
}

export const Footer: React.FC<Props> = ({ addRow }) => {
  return (
    <footer className={styles.footer}>
      {addRow && (
        <button
          className={styles.bodyCellButton}
          onClick={addRow}
          type="button"
        >
          <div className={styles.iconsDiv}>
            <AddRowIcon className={styles.addIcon} />
            <p className={styles.purpleText}>Add row</p>
          </div>
        </button>
      )}
    </footer>
  );
};
