import moment, { Moment } from "moment";

export const now = () => {
  return moment().unix();
};

export const today = () => {
  return moment.utc().startOf("day").unix();
};

/**
 * todayDate returns today's timestamp with 0 on hour, minute, second and millisecond counters.
 */
export const todayDate = (): number => {
  const date = moment();
  date.utcOffset(0, true);
  date.set("hour", 0);
  date.set("minute", 0);
  date.set("second", 0);
  date.set("millisecond", 0);
  return date.unix();
};

export const uiDateTime = (
  dateTime: number,
  format: string = "DD MMM yyyy HH:mm:ss"
) => {
  return moment.unix(dateTime).format(format);
};

export const parseUIDateTime = (
  dateTime: string,
  format: string = "YYYY-MM-DDTHH:mm"
) => {
  return moment(dateTime, format).unix();
};

export const uiDate = (date: number, format: string = "DD-MM-YYYY") => {
  return moment.unix(date).format(format);
};

export const parseUIDate = (date: string, format: string = "YYYY-MM-DD") => {
  return moment.utc(date, format).unix();
};

export const dateDaysDiff = (d1: number, d2: number) => {
  return moment.duration(moment.unix(d1).diff(moment.unix(d2))).asDays();
};

export const formatLogDateTime = (
  date: any,
  format: string = "DD MMM yyyy HH:mm:ss"
) => {
  return moment(date).format(format);
};

export const formatLogDate = (date: any, format: string = "DD-MM-YYYY") => {
  return moment(date).format(format);
};

/**
 * Returns the given `timestamp` to the same date but with time 23:59:59
 * @param timestamp
 * @returns `timestamp` with time 23:59:59
 */
export const toLastSecond = (timestamp: number) => {
  const date = new Date(timestamp * 1000);
  date.setHours(23, 59, 59);
  return date.getTime() / 1000;
};

export const getDateUnix = (value: Moment | null): number => {
  if (!value) {
    return null;
  }
  // set to zero the hour, minutes, seconds and milliseconds of the given input.
  value.utcOffset(0, true);
  value.set("hour", 0);
  value.set("minute", 0);
  value.set("second", 0);
  value.set("millisecond", 0);
  return value.unix();
};
